import { Component, Injectable, OnInit } from '@angular/core';

import { AuthenticationService } from '../../service/authentication.service';
import { NgbDatepickerI18n, NgbDateStruct, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../service/token.service';
import { UserService } from '../../service/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

const I18N_VALUES = {
  	'fr': {
    	weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    	months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  	}
  	// other languages you would support
};

@Injectable()
export class I18n {
  	language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  	constructor(private _i18n: I18n) {
    	super();
  	}

  	getWeekdayShortName(weekday: number): string {
    	return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  	}
  	getMonthShortName(month: number): string {
		return I18N_VALUES[this._i18n.language].months[month - 1];
  	}
  	getMonthFullName(month: number): string {
    	return this.getMonthShortName(month);
  	}

  	getDayAriaLabel(date: NgbDateStruct): string {
    	return `${date.day}-${date.month}-${date.year}`;
  	}
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
	
	readonly DELIMITER = '/';

  	parse(value: string): NgbDateStruct {
    	let result: NgbDateStruct = null;
		if (value) {
  			let date = value.split(this.DELIMITER);
  			result = {
    			day : parseInt(date[0], 10),
    			month : parseInt(date[1], 10),
    			year : parseInt(date[2], 10)
  			};
		}
    	return result;
  	}

  	format(date: NgbDateStruct): string {
    	let result: string = null;
    	if (date) {
      		result = date.day + this.DELIMITER + date.month + this.DELIMITER + date.year;
    	}
    	return result;
  	}
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}, {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class RegisterComponent implements OnInit {

	public registerForm: FormGroup;

	filedata:any;
	model: NgbDateStruct;
	date: {year: number, month: number};
	base64textString:any;
	currentYear:any;
	currentMonth:any;
	currentDay:any;
	fullDate:any;
	hasEmail:any;

	public form = {
		title: null,
		fname: null,
		lname: null,
		country: null,
		pincode: null,
		numebr: null,
		email: null,
		password: null,
		dob: null,
		image: null,
		address: 'p_address',
		siren: null,
		raison_sociale: null,
		tva: null,
		b_address: null,
		p_address: null,
		addressType: null,
		city: null,
		postalcode: null,
		// transport: null,
		// profile: null,
		// type: null,
	}
	
	constructor(
        private router: Router,
        public auth: AuthenticationService,
        private token: TokenService,
        private userservice: UserService,
        private toastr: ToastrService,
        private calendar: NgbCalendar
	) {
		var url = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);
		$(document).ready(function() {
			$('body').removeClass();
			$('body').addClass(url);
		});

		if (this.auth.isLoggedIn()) {
			this.router.navigate(['/accueil']);
		}

		this.userservice.loadScript();

		this.fullDate = new Date();
		this.currentYear = this.fullDate.getFullYear();
		this.currentMonth = this.fullDate.getMonth();
		this.currentDay = this.fullDate.getDay();
		console.log('this.currentYear' +this.currentYear);
		console.log('this.currentMonth' +this.currentMonth);
		console.log('this.currentDay' +this.currentDay);
		console.log('current Date' + this.fullDate);

		this.base64textString = null;
	}

	ngOnInit() {

		// $('.personal').addClass('show_personal');
		// $('.personal').removeClass('hide_personal');

		// // $('.personal_field').attr('required', true);
		// // $('.business_field').removeAttr('required', false);

		// $('.business').addClass('hide_business');
		// $('.business').removeClass('show_business');

	}

	changeAddressType( addressType ){
		this.form.address = addressType;
	}

	onSubmit(){
		
		//console.log(this.form); return;
		this.form.image = this.base64textString;
		//console.log(this.form);
  		this.auth.register(this.form).subscribe(
  			data => {

  				if(data['status'] == 'true'){
  					this.token.set('token', data['token']);
  					this.token.set('currentUser', JSON.stringify(data['user']));
  					this.auth.loginEvent.emit(data['user'])
  					var userId =  this.token.getUser();

  					if(this.token.get('event_type')){
  						this.toastr.success("Utilisateur enregistré avec succès.");
  						this.router.navigate(['/récapitulatif-de-la-commande']);
  					}else{
  						this.router.navigate(['/accueil']);
  					}
  				}else{
  					var error = '';
  					if( typeof data['email'] !== 'undefined' ){
                		this.toastr.error('Le couriel a déja été pris en compte.');
  					} else {
                		this.toastr.error('Quelque chose ne va pas, veuillez réessayer.');
                	}
  				}
  			},
            error => {
                this.toastr.error(error.error.error);
            }
  		);
  	}

  	fileEvent(event){
		this.filedata = event.target.files[0];
		$('#file-name').html(event.target.files[0].name);
		// document.getElementById("file-name").innerHTML = target.files[0].name;

		var reader = new FileReader();

        reader.onload = this._handleReaderLoaded.bind(this);

        reader.readAsBinaryString(this.filedata);
	}

	_handleReaderLoaded(readerEvt) {
		var binaryString = readerEvt.target.result;
		this.base64textString= btoa(binaryString);
	}

	/*peronalAccount(){
		$('.personal').addClass('show_personal');
		$('.personal').removeClass('hide_personal');

		$('.personal_field').attr('required', true);
		$('.business_field').removeAttr('required', false);

		$('.business').addClass('hide_business');
		$('.business').removeClass('show_business');
	}

	businessAccount(){
		$('.business').addClass('show_business');
		$('.business').removeClass('hide_business');

		$('.business_field').attr('required', true);
		$('.personal_field').removeAttr('required', false);

		$('.personal').addClass('hide_personal');
		$('.personal').removeClass('show_personal');
	}*/

	changeCountry(){
		this.changePincode(this.form.country);
	}

	accountType( accountType ){
		console.log(accountType);
		if(accountType == 'peronal'){
			$('.personal').addClass('show_personal');
			$('.personal').removeClass('hide_personal');

			// $('.personal_field').attr('required', true);
			// $('.business_field').removeAttr('required', false);

			$('.business').addClass('hide_business');
			$('.business').removeClass('show_business');

			$('.personnel_tel_label').show();
			$('.entreprise_tel_label').hide();
		}

		if(accountType == 'business'){
			$('.business').addClass('show_business');
			$('.business').removeClass('hide_business');

			// $('.business_field').attr('required', true);
			// $('.personal_field').removeAttr('required', false);

			$('.personal').addClass('hide_personal');
			$('.personal').removeClass('show_personal');

			$('.personnel_tel_label').hide();
			$('.entreprise_tel_label').show();
		}

	}

	changePincode(containernew){
		var pincodeArr = {
			FRA: "+33",
			AFG: "+93",
			ALB: "+355",
			ALG: "+213",
			AND: "+376",
			ANG: "+244",
			ANT: "+1-268",
			ARG: "+54",
			ARM: "+374",
			ARU: "+297",
			ASA: "+1-684",
			AUS: "+61",
			AUT: "+43",
			AZE: "+994",
			BAH: "+1-242",
			BAN: "+880",
			BAR: "+1-246",
			BDI: "+257",
			BEL: "+32",
			BEN: "+	229",
			BER: "+1-441",
			BHU: "+975",
			BIH: "+387",
			BIZ: "+501",
			BLR: "+375",
			BOL: "+591",
			BOT: "+267",
			BRA: "+55",
			BRN: "+973",
			BRU: "+673",
			BUL: "+359",
			BUR: "+226",
			CAF: "236",
			CAM: "+855",
			CAN: "+1",
			CAY: "+1-345",
			CGO: "+242",
			CHA: "+235",
			CHI: "+56",
			CHN: "+86",
			CIV: "Cote d'Ivoire",
			CMR: "+237",
			COD: "+243",
			COK: "+682",
			COL: "+57",
			COM: "+269",
			CPV: "+238",
			CRC: "+506",
			CRO: "+385",
			CUB: "+53",
			CYP: "+357",
			CZE: "+420",
			DEN: "+45",
			DJI: "+253",
			DMA: "+1 767",
			DOM: "+1 809",
			ECU: "+593",
			EGY: "+20",
			ERI: "+291",
			ESA: "+503",
			ESP: "+34",
			EST: "+372",
			ETH: "+251",
			FIJ: "+679",
			FIN: "+358",
			FSM: "+691",
			GAB: "+241",
			GAM: "+220",
			GBR: "+44",
			GBS: "+245",
			GEO: "+995",
			GEQ: "+240",
			GER: "+49",
			GHA: "+233",
			GRE: "+30",
			GRN: "+1 473",
			GUA: "+502",
			GUI: "+224",
			GUM: "+1 671",
			GUY: "+592",
			HAI: "+509",
			HKG: "+852",
			HON: "+504",
			HUN: "+36",
			INA: "+62",
			IND: "+91",
			IRI: "+98",
			IRL: "+353",
			IRQ: "+964",
			ISL: "+354",
			ISR: "+972",
			ISV: "+00 1",
			ITA: "+39",
			IVB: "+1 284",
			JAM: "+1 876",
			JOR: "+962",
			JPN: "+81",
			KAZ: "+7 6",
			KEN: "+254",
			KGZ: "+996",
			KIR: "+686",
			KOR: "+82",
			KOS: "+383",
			KSA: "+966",
			KUW: "+965",
			LAO: "+856",
			LAT: "+371",
			LBA: "+218",
			LBR: "+231",
			LCA: "+1 758",
			LES: "+266",
			LIB: "+961",
			LIE: "+423",
			LTU: "+370",
			LUX: "+352",
			MAD: "+261",
			MAR: "+212",
			MAS: "+60",
			MAW: "+265",
			MDA: "+373",
			MDV: "+960",
			MEX: "+52",
			MGL: "+976",
			MHL: "+692",
			MKD: "+389",
			MLI: "+223",
			MLT: "+356",
			MNE: "+382",
			MON: "+377",
			MOZ: "+258",
			MRI: "+230",
			MTN: "+222",
			MYA: "+95",
			NAM: "+264",
			NCA: "+505",
			NED: "+31",
			NEP: "+977",
			NGR: "+234",
			NIG: "+227",
			NOR: "+47",
			NRU: "+674",
			NZL: "+64",
			OMA: "+968",
			PAK: "+92",
			PAN: "+507",
			PAR: "+595",
			PER: "+51",
			PHI: "+63",
			PLE: "+970",
			PLW: "+680",
			PNG: "+675",
			POL: "+48",
			POR: "+351",
			PRK: "+850",
			PUR: "+1 787",
			QAT: "+974",
			ROU: "+40",
			RSA: "+27",
			RUS: "+7",
			RWA: "+250",
			SAM: "+685",
			SEN: "+221",
			SEY: "+248",
			SIN: "+65",
			SKN: "+1 869",
			SLE: "+232",
			SLO: "+386",
			SMR: "+378",
			SOL: "+677",
			SOM: "+252",
			SRB: "+381",
			SRI: "+94",
			SSD: "+211",
			STP: "+239",
			SUD: "+249",
			SUI: "+41",
			SUR: "+597",
			SVK: "+421",
			SWE: "+46",
			SWZ: "+268",
			SYR: "+963",
			TAN: "+255",
			TGA: "+676",
			THA: "+66",
			TJK: "+992",
			TKM: "+993",
			TLS: "+670",
			TOG: "+228",
			TPE: "+886",
			TTO: "+1 868",
			TUN: "+216",
			TUR: "+90",
			TUV: "+688",
			UAE: "+971",
			UGA: "+256",
			UKR: "+380",
			URU: "+598",
			USA: "+1",
			UZB: "+998",
			VAN: "+678",
			VEN: "+58",
			VIE: "+84",
			VIN: "+1 784",
			YEM: "+967",
			ZAM: "+260",
			ZAN: "+255 24",
			ZIM: "+263"
		}
		var self = this;
		
		$.each(pincodeArr, function(key, value) {
			if(key == containernew){
				self.form.pincode = value;
			}
		});
	}
}

