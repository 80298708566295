import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { TokenService } from '../../service/token.service';
import { UserService } from '../../service/user.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { PaymentService } from '../../service/payment.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { DecimalPipe } from '@angular/common';
import * as $ from 'jquery';
declare var jQuery: any;

@Component({
	selector: 'app-recurring-order',
	templateUrl: './recurring-order.component.html',
	styleUrls: ['./recurring-order.component.css']
})
export class RecurringOrderComponent implements OnInit {

	step:any;
	service:any;
	paymentURL: string = "";
	//paymentURL: string = "https://payment-webinit-mercanet.test.sips-atos.com/paymentInit";
	order_id: any = '';
	nomade_service:any = {};
	IMG_URL: any;
	allData:any = {};
	paymentForm: FormGroup;
	totalPrice: number = 0;
	ServicePrice: number = 0;
	VATPercent: any;
	orderDetail: any;
	currentUser: any;
	VATadd: any;

	constructor(
		private router: Router,
		private token : TokenService,
		private userservice: UserService,
		private toastr: ToastrService,
		private cookie: CookieService,
		private payment: PaymentService,
		private fb: FormBuilder,
		public datepipe: DatePipe,
	) { 
		this.IMG_URL = environment.STORAGE_IMG_URL;
		var order_id = this.getUrlParameter('order');
		if( typeof order_id != 'undefined' && order_id != '' ){
			this.order_id = order_id;
		}
		console.log( this.order_id );
	}

	ngOnInit() {
		this.paymentForm = this.fb.group({
			email: ['', Validators.required],
			cardNumber: ['', Validators.required],
			expiredDate: ['', Validators.required],
			cvv: ['', Validators.required]
		})
		this.getOrderdetails();
	}

	getOrderdetails(){
		var orderData = {};
		orderData['orderId'] =  this.order_id;
		this.userservice.fetchRecurringOrderValue( orderData ).subscribe(
  			data => {
  				console.log( data );
	            if( data['status'] == 'true' ){
                    this.nomade_service = data['orderService'];
                    if( this.nomade_service.length <= 0 ){
                    	this.order_id = '';
                    }
                    console.log( this.nomade_service );
                    this.calculateTotal();
	            } else {
	            	this.order_id = '';
	            }
  			},
            error => {
                console.log('cannot send recurring request.');
            }
  		);
	}

	calculateTotal(){
		this.currentUser =  JSON.parse(this.token.get('currentUser'));

		if( this.nomade_service.length > 0 ){
			var self = this;
			jQuery( this.nomade_service ).each( function( i, service ){
				let nomadeService = self.nomade_service[i];
				console.log( nomadeService );
				let servicePrice = 0;
				if( nomadeService['type_of_invoice'] == '2' ){
					servicePrice = Number( nomadeService['recurring_price'] );
					let todayDate = new Date();
					todayDate.setMonth( todayDate.getMonth() + Number( nomadeService['recurring_months'] ) );
					self.nomade_service[i]['service_until'] = self.datepipe.transform( todayDate, 'yyyy-MM-dd' );
				} else {
					servicePrice = nomadeService['one_time_price'];
				}
				console.log( 'service price => '+servicePrice )
				self.ServicePrice = Number( self.ServicePrice ) + Number( servicePrice );
				console.log( 'after servicePrice => '+self.ServicePrice );
			});

			this.totalPrice = Number( this.totalPrice ) + Number( this.ServicePrice );
			this.token.set('totalRecuPrice', this.totalPrice);
			this.token.set('totalRecuSubmitPrice', this.totalPrice);
		}

		if( this.currentUser !== 'undefined' && this.currentUser != null ){
			if( this.currentUser.type == '1' ){
				//We apply flat 20% vat
				this.VATPercent = 20;
				this.VATadd = this.totalPrice*this.VATPercent/100;
				this.totalPrice = this.totalPrice + this.totalPrice*this.VATPercent/100;
				this.token.set('totalRecuPrice', this.totalPrice);
				this.token.set('totalRecuSubmitPrice', this.totalPrice);
				this.token.set('taxRecuAmount', this.VATadd);
			} else {
				//Check vat number
				if( this.currentUser.tva != null && this.currentUser.tva.toLowerCase().substr(0, 2) == 'fr' ){
					//console.log('in');
					this.VATPercent = 20;
					this.VATadd = this.totalPrice*this.VATPercent/100;
					this.totalPrice = this.totalPrice + this.totalPrice*this.VATPercent/100;
					this.token.set('totalRecuPrice', this.totalPrice);
					this.token.set('totalRecuSubmitPrice', this.totalPrice);
					this.token.set('taxRecuAmount', this.VATadd);
				}
			}
		}

		console.log( '3 =>' + this.totalPrice );
		console.log( this.totalPrice );
	}

	getUrlParameter(sParam) {
		var sPageURL = window.location.search.substring(1),
		sURLVariables = sPageURL.split('&'),
		sParameterName,
		i;

		for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=');

			if (sParameterName[0] === sParam) {
				return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
			}
		}
	}

	next(){
		var self = this;
		this.allData['allData'] = this.cookie.getAll();

		this.allData['orderId'] = this.order_id;
		this.allData['total'] = this.token.get( 'totalRecuSubmitPrice' );
		this.allData['taxAmount'] = this.token.get('taxRecuAmount');
		this.allData['totalPrice'] = this.token.get('totalRecuPrice');
		this.allData['nomade_service'] = JSON.stringify( this.nomade_service );
		
		var currentUser = JSON.parse(this.token.get('currentUser'));

		this.allData['userDetail'] = this.token.userDetail('currentUser');
		this.allData['payment'] = this.paymentForm.value;
		this.allData['userCanOrderWithoutPay'] = this.token.get('userCanOrderWithoutPay');
		this.payment.RecurringPayment(this.allData).subscribe(
  			data => {
  				if(data['status'] == 'false'){
  					jQuery('#mailNotSend').modal();
  				}

	            if(data['status'] == 'true'){
	            	this.orderDetail = data['orderDetail']

	            	if( currentUser['user_can_pay_offline'] == '0'){
	            		this.paymentGateWay();
	            	}else{

	            		this.token.remove('totalRecuSubmitPrice');
						this.token.remove('taxRecuAmount');
						this.token.remove('totalRecuPrice');

	            		this.router.navigate(['/merci'],{queryParams:{staus:'true'}});
	            	}

                	this.toastr.success("Commande réussie..");
	            }
  			},
            error => {
                // this.toastr.error(error.error.error);
            }
  		);
	}

	postData: any = {};

	paymentGateWay(){
		this.allData['total'] = Number( this.token.get('totalRecuSubmitPrice') ) || 0;
		this.allData['orderDetail'] = this.orderDetail;
		this.allData['nomade_service'] = JSON.stringify( this.nomade_service );
		this.payment.recurringPaymentGateway(this.allData).subscribe(
			data => {
				if(data['status'] == 'true'){
					
					//this.postData['Data'] = data['data'];
					//this.postData['InterfaceVersion'] = "HP_2.20";
					//this.postData['Seal'] = data['seal'];
					//console.log( this.postData );
					//console.log(this.postData['Data'])
					//console.log(this.postData['InterfaceVersion'])
					//console.log(this.postData['Seal'])
					//$("#submitPaymentForm input[name='Data']").val(this.postData['Data']);
					//$("#submitPaymentForm input[name='InterfaceVersion']").val(this.postData['InterfaceVersion']);
					//$("#submitPaymentForm input[name='Seal']").val(this.postData['Seal']);
					// $('#submitPaymentForm').submit();
					this.paymentURL = data['data'];

					setTimeout(()=>{ $('#paymentClick').click() }, 1000)
					//$('#paymentClick').click();

				}
				/*if(this.token.get('eventData') && this.token.get('service') == '1'){
					if(data['status'] == 'false'){
						this.toastr.error("Quelque chose ne va pas.");
						this.router.navigate(['/récapitulatif-de-la-commande']);
					}
				}

				if(this.token.get('menu') && this.token.get('service') == '2'){
					if(data['status'] == 'false'){
						this.toastr.error("Quelque chose ne va pas.");
						this.router.navigate(['/récapitulatif-de-la-commande']);
					}	
				}*/
			},
			error => {

			}
		);
	}

}
