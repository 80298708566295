import { Component, OnInit } from '@angular/core';

import { TokenService } from '../../service/token.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../../service/user.service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
declare var jQuery: any;

@Component({
    selector: 'app-my-document',
    templateUrl: './my-document.component.html',
    styleUrls: ['./my-document.component.css']
})
export class MyAccountDocuments implements OnInit {

	userDetail:any;
    baseURL: any;
    documentsData:any;
    i: any = 1;
    document_type: any = [];
    userDocuments: any = [];
    docURL: any;
    b64Doc: any;
    downloadUrl: any;
    docChecked:any = [];
    fileName: any;
    type: any;

    constructor(
        private token: TokenService,
        private userservice: UserService,
        private toastr: ToastrService,
        ) { 
        this.baseURL = environment.IMG_URL;
        this.userDetail = this.token.userDetail('currentUser');
        this.getDocumentType();
        this.getUserDocuments();
    }

    ngOnInit() {

        var self = this;

        $(document).ready(function() {
            $(document).on('change', '.fileInput', function(e){
                var droppedFiles = e.target.files || e.dataTransfer.files;
                console.log($(this));
                if (droppedFiles.length > 1) {
                    $(this).next().find('.fileLabelText').html(droppedFiles.length + " fichiers sélectionnés");
                } else {
                    $(this).next().find('.fileLabelText').html(droppedFiles[0].name);
                }
            });        
        });

        $(document).on('change','.document_type', function(){
            console.log($(this).val());
            $(this).next('.other').hide();
            $(this).next('.other').next('.error').remove();
            if( $(this).val() == 'other' ){
                $(this).next().show();
            }
        });

        $(document).on('change','.repalce-document', function(){
            var formData = new FormData();
            formData.append('file', $(this)[0].files[0]);
            formData.append('document_id', $(this).data('id'));
            self.repalceDocument(formData);
        });

    }

    // fileChangeEvent(fileInput: any){
    //     var self = this;
    //       if (fileInput.target.files && fileInput.target.files[0]) {
    //           console.log(fileInput.target.files[0]);
    //           self.repalceDocument(fileInput.target.files[0]);
    //         //var reader = new FileReader();

    //         // reader.onload = function (e : any) {
    //         //     $('#preview').attr('src', e.target.result);
    //         // }

    //         //reader.readAsDataURL(fileInput.target.files[0]);
    //     }
    // }

    uploadFiles() {
    
        //changeStatus("Uploading...");

        var error = false;

        $('.fileInput').each(function(){
            $(this).next().next('.error').remove();
            if( $(this).val() == '' ){
                $('<p class="error">Veuillez sélectionner un document.</p>').insertAfter($(this).next());
                error = true;
            }
        });

        $('.document_type').each(function(){
            $(this).next('.other').next('.error').remove();
            if( $(this).val() == 'other' ){
                if( $(this).next().val() == '' ){
                    $('<p class="error">Veuillez saisir la valeur d\'autres documents.</p>').insertAfter($(this).next('.other'));
                    error = true;
                }
            }
        });

        if( error ){
            return false;
        }

        var form = $('#user-documents')[0];
        var formData = new FormData(form);
            formData.append('user_id', this.token.get('userId') || this.userDetail.id);
        this.userservice.saveUserDocumnet(formData).subscribe(
            data => {
                if( data['success'] ){
                    this.toastr.success(data['message']);
                }else{
                    this.toastr.error(data['message']);    
                }
            },
            error => {
                this.toastr.error(error.error.error);
            }
        );

    }

    addMore(){
        console.log(this.document_type);
        var html = '<div class="col-sm-6 col-xs-12 documents-col">';
            html += '<span class="remove-whole-documents" onclick="return this.parentNode.remove();"></span>';
                html += '<div class="border-col section">';
                    html += '<h5>Déposer votre document</h5>';
                    html += '<div id="dropFileForm" class="section dropFileForm">';
                        html += '<input type="file" name="files[]" class="fileInput" id="fileInput'+this.i+'" multiple>';
                        html += '<label for="fileInput'+this.i+'" class="form-control fileLabel" id="fileLabel" ondragover="overrideDefault(event);fileHover();" ondragenter="overrideDefault(event);fileHover();" ondragleave="overrideDefault(event);fileHoverEnd();" ondrop="overrideDefault(event);fileHoverEnd(); addFiles(event);">';
                            html += '<span id="fileLabelText" class="fileLabelText">Glissez & déposez</span>';
                        html += '</label>';
                    html += '</div>';
                    html += '<select class="form-control document_type" name="documents['+this.i+'][type]">';
                        html += '<option value="document" disabled>Type de document</option>';

                        for( var i = 0; i < this.document_type.length; i++){
                            var selected = '';
                            if( i == 0 ){
                                selected = 'selected';
                            }
                            html +='<option value="'+this.document_type[i]['id']+'" '+selected+'>'+this.document_type[i]['name']+'</option>';
                        }

                        html += '<option value="other">Other (customer can set the custom type)</option>';
                    html += '</select>';
                    html += '<input type="text" name="documents['+this.i+'][other]" class="form-control other" style="display: none;">';
                    html += '<textarea placeholder="Description" name="documents['+this.i+'][description]" class="form-control"></textarea>';
                html += '</div>';
            html += '</div>';

        $('#documents-uploads').append(html);
        this.i++;
    }

    getDocumentType(){
        this.userservice.getDocumentType().subscribe(
            data => {
                //console.log(data);
                this.document_type = data['document_type'];
            },
            error => {
            }
        );
    }

    getUserDocuments(){
        this.userservice.getUserDocuments().subscribe(
            data => {
                this.userDocuments = data['data'];
                console.log(this.userDocuments);
            },
            error => {
            }
        );
    }

    updateCheckedOptions( document_id, event ){
        if(event.target.checked) {
           this.docChecked.push(document_id);
        } else {
             for(var i=0 ; i < this.docChecked.length; i++) {
                   if(this.docChecked[i] == document_id) {
                     this.docChecked.splice(i,1);
                  }
            }
        }
        console.log(this.docChecked);
    }

    exportDocuments(){
        
        if(this.docChecked.length > 0){
            this.documentsData = {};
            this.documentsData['docId'] = this.docChecked;;
            this.documentsData['action'] = 'zip';
            this.userservice.genrateDocument(this.documentsData).subscribe(
                data => {
                    if(data['flag']){
                        this.b64Doc = data['b64Doc'];
                        this.docURL = data['data_url'];
                        var parts = this.docURL.split("/");
                        this.fileName = parts[parts.length-1];
                        this.saveDocument(this.b64Doc,this.fileName);
                    }
                    if(!data['flag']){
                        this.toastr.error("Oups! Quelque chose s'est mal passé.");
                    }
                },
                error => {
                    this.toastr.error("Oups! Quelque chose s'est mal passé.");
                }
            );

          }else{
              jQuery('#noSelectedOrder').modal();
          }
    }

    downloadDocument(document_id){
        
        this.documentsData = {};
        this.documentsData['docId'] = document_id;
        this.documentsData['action'] = 'download';
        this.userservice.genrateDocument(this.documentsData).subscribe(
            data => {
                if(data['flag']){
                    this.b64Doc = data['b64Doc'];
                    this.docURL = data['data_url'];
                    var parts = this.docURL.split("/");
                    this.fileName = parts[parts.length-1];
                    this.saveDocument(this.b64Doc,this.fileName);
                }
                if(!data['flag']){
                    this.toastr.error("Oups! Quelque chose s'est mal passé.");
                }
            },
            error => {
                this.toastr.error("Oups! Quelque chose s'est mal passé.");
            }
        );
    }

    viewReplace( document_id ){
        $( '.user-documents-replace' ).hide();
        $( '.replace_'+document_id ).show();
    }

    viewDocument(document_id){
        
        this.documentsData = {};
        this.documentsData['docId'] = document_id;
        this.documentsData['action'] = 'view';
        this.userservice.genrateDocument(this.documentsData).subscribe(
            data => {
                if(data['flag']){
                    this.docURL = data['data_url'];
                    window.open(this.docURL, '_blank');
                }
                if(!data['flag']){
                    this.toastr.error("Oups! Quelque chose s'est mal passé.");
                }
            },
            error => {
                this.toastr.error("Oups! Quelque chose s'est mal passé.");
            }
        );
    }

    repalceDocument(formData){
        formData.append('user_id', this.token.get('userId'));   
        this.userservice.repalceDocument(formData).subscribe(
            data => {
                if( data['success'] ){
                    this.toastr.success(data['message']);
                }else{
                    this.toastr.error(data['message']);    
                }
            },
            error => {
                this.toastr.error("Oups! Quelque chose s'est mal passé.");
            }
        );
    }

    saveDocument(base64Data,filename) {

        var arrBuffer = this.base64ToArrayBuffer(base64Data);

        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([arrBuffer]);

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        var data = window.URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        document.body.appendChild(link); //required in FF, optional for Chrome
        link.href = data;
        link.download = filename;
        link.click();

        if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
            //console.log('safari');
            var windowReference = window.open();
            windowReference.location.href  = link.href;
            // window event not working here
        }else{
            //console.log('non safari');
            window.URL.revokeObjectURL(data);
        }

        link.remove();
    }

    base64ToArrayBuffer(data) {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    };

}
