import { Component, OnInit } from '@angular/core';

import { PaymentService } from '../../service/payment.service';
import { UserService } from '../../service/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenService } from '../../service/token.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var jQuery: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

	allData:any = {};
	paymentForm: FormGroup;
	walletDiscount:any;
	totalPrice:any;
	price:any;
	coupponDiscount:any;
	MenuPrice:any;
	paymentURL: string = "https://payment-webinit-mercanet.test.sips-atos.com/paymentInit";

	constructor(
		private router: Router,
        private payment: PaymentService,
        private token: TokenService,
        private toastr: ToastrService,
        private cookie: CookieService,
        private fb: FormBuilder,
        public userserivce: UserService,
	) { 
		if(this.token.get('service') == '1'){
			this.totalPrice = this.token.get('totalPrice') || this.token.get('service_cost') || null;
			this.price = this.token.get('price') || this.token.get('service_cost') || null;
		}
		if(this.token.get('service') == '2'){
			if(this.token.get('event_type') == 'hour'){
				this.totalPrice = Number(this.token.get('hour_price')); 
				if(this.token.get('menuPrice')){
					this.totalPrice = Number(this.token.get('hour_price')) + Number(this.token.get('menuPrice'));
				}
				this.price = this.token.get('hour_price');
			}

			if (this.token.get('event_type') == 'half') {
				this.totalPrice = Number(this.token.get('half_price'));
				if(this.token.get('menuPrice')){
					this.totalPrice = Number(this.token.get('half_price')) + Number(this.token.get('menuPrice'));
				}
				this.price = this.token.get('half_price');
			}

			if(this.token.get('event_type') == 'full'){
				this.totalPrice = Number(this.token.get('full_price'));
				if(this.token.get('menuPrice')){
					this.totalPrice = Number(this.token.get('full_price')) + Number(this.token.get('menuPrice'));
				}
				this.price = this.token.get('full_price');
			}
		}

		this.walletDiscount = this.token.get('walletDiscount') || null;
		this.coupponDiscount = this.token.get('couponDiscount') || null;
		this.MenuPrice = this.token.get('menuPrice') || null;
		this.paymentGateWay();

	}

	ngOnInit() {
		this.paymentForm = this.fb.group({
			email: ['', Validators.required],
			cardNumber: ['', Validators.required],
			expiredDate: ['', Validators.required],
			cvv: ['', Validators.required]
		})
	}

	Payment(){
		this.allData['allData'] = this.cookie.getAll();
		this.allData['menu'] = this.token.get('menu');
		this.allData['total'] = this.token.get('totalPrice') || this.token.get('service_cost');
		this.allData['eventData'] = this.token.get('eventData');
		this.allData['service'] = this.token.get('service');
		this.allData['event_type'] = this.token.get('event_type');
		this.allData['room_size'] = this.token.get('room_size');
		this.allData['payment'] = this.paymentForm.value;
		this.allData['userDetail'] = this.token.userDetail('currentUser');
		// console.log(this.allData);
		this.payment.Payment(this.allData).subscribe(
  			data => {
  				if(data['status'] == 'false'){
  					jQuery('#mailNotSend').modal();
  				}

	            if(data['status'] == 'true'){
	            	this.token.remove('menu');
	            	this.token.remove('eventData');
	            	this.token.remove('service');
	            	this.token.remove('event_type');
	            	this.token.remove('room_size');
	            	this.token.remove('full_price');
	            	this.token.remove('hour_price');
	            	this.token.remove('half_price');
	            	this.token.remove('menuPrice');

	            	this.cookie.delete('service');
	            	this.cookie.delete('space');
	            	this.cookie.delete('eventData');
	            	this.cookie.delete('totalPrice');

                	this.toastr.success("Payment Successfully.");
	                this.router.navigate(['/merci']);
	            }
  			},
            error => {
                // this.toastr.error(error.error.error);
            }
  		);
	}

	postData: any = {};

	paymentGateWay(){
		this.allData['total'] = this.token.get('totalPrice') || this.token.get('service_cost');
		if(this.allData['total'] != 0){
			this.payment.paymentGateway(this.allData).subscribe(
				data => {
					if(data['status'] == 'true'){
						this.postData['Data'] = data['data'];
						this.postData['InterfaceVersion'] = "HP_2.20";
						this.postData['Seal'] = data['seal'];
					}

					if(data['status'] == 'false'){
						this.router.navigate(['/paiement']);
						this.toastr.error("Something Went Wrong.");
					}
				},
				error => {

				}
			);
		}
	}
}
