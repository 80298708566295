import { Component, OnInit } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { FormBuilder } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.css']
})
export class PaymentDetailComponent implements OnInit {

	spaceData: any;
	totalPrice: any;
	balance: any;
	walletUpdatePrice: any;
	private eventData: any = Array();
	space: any = Array();
	

	applyCouponForm = this.fb.group({
        couponValue: null,
    })

	constructor(
		private cookie: CookieService,
		public fb: FormBuilder,
	) { }

	ngOnInit() {
	}

	// walletBalance(){
	// 	this.balance = 10;
	// 	var TotalPrice = $('.totalPrice').text().split(' ')[0];
	// 	if(TotalPrice < this.balance){
	// 		this.totalPrice = TotalPrice - TotalPrice; 
	// 		this.walletUpdatePrice = this.balance - TotalPrice; 
	// 		$('.totalPrice').text(this.totalPrice+' €');
	// 		$('.order-box .walletDiscount').removeClass('hideWdiscount');
	// 		$('.order-box .walletDiscount span').text(TotalPrice+' €');
	// 		this.cookie.set('totalPrice', TotalPrice);
	// 		$('.walletBalance').text(this.walletUpdatePrice+' €');
	// 	}else{
	// 		this.totalPrice = TotalPrice - this.balance;
	// 		$('.totalPrice').text(this.totalPrice+' €');
	// 		this.cookie.set('totalPrice', this.totalPrice);
	// 		$('.order-box .walletDiscount').removeClass('hideWdiscount');
	// 		$('.walletBalance').text('0 €');
	// 	}
	// }

	// applyCoupon(){
	// 	if(this.applyCouponForm.value.couponValue == '123456789'){
	// 		var TotalPrice = $('.totalPrice').text().split(' ')[0];
	// 		this.totalPrice = TotalPrice - 100;
	// 		$('.order-box .applyCoupon span').text('100 €');
	// 		$('.totalPrice').text(this.totalPrice+' €');
	// 		this.cookie.set('totalPrice', this.totalPrice);
	// 		$('.order-box .applyCoupon').removeClass('hideCdiscount');
	// 	}
	// }
}
