import { Component, OnInit } from '@angular/core';

import { TokenService } from '../../service/token.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {

	userDetail:any;
    baseURL: any;
    storageURL: any;

    constructor(
        private token: TokenService,
        ) { 
        this.baseURL = environment.IMG_URL;
        this.storageURL = environment.STORAGE_IMG_URL;
        this.userDetail = this.token.userDetail('currentUser');
    }

    ngOnInit() {
    }

}
