import { Component, Injectable, OnInit } from '@angular/core';

import { ServiceFourService } from '../../service/service-four.service';
import { AuthenticationService } from '../../service/authentication.service';
import { NgbDatepickerI18n, NgbDateStruct, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../service/user.service';
import { TokenService } from '../../service/token.service';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
//import * as $ from 'jquery';
import * as $AB from 'jquery';

const I18N_VALUES = {
  	'fr': {
    	weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    	months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  	}
  	// other languages you would support
};

@Injectable()
export class I18n {
  	language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  	constructor(private _i18n: I18n) {
    	super();
  	}

  	getWeekdayShortName(weekday: number): string {
    	return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  	}
  	getMonthShortName(month: number): string {
		return I18N_VALUES[this._i18n.language].months[month - 1];
  	}
  	getMonthFullName(month: number): string {
    	return this.getMonthShortName(month);
  	}

  	getDayAriaLabel(date: NgbDateStruct): string {
    	return `${date.day}-${date.month}-${date.year}`;
  	}
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
	
	readonly DELIMITER = '/';

  	parse(value: string): NgbDateStruct {
    	let result: NgbDateStruct = null;
		if (value) {
  			let date = value.split(this.DELIMITER);
  			result = {
    			day : parseInt(date[0], 10),
    			month : parseInt(date[1], 10),
    			year : parseInt(date[2], 10)
  			};
		}
    	return result;
  	}

  	format(date: NgbDateStruct): string {
    	let result: string = null;
    	if (date) {
      		result = date.day + this.DELIMITER + date.month + this.DELIMITER + date.year;
    	}
    	return result;
  	}
}

@Component({
  selector: 'app-four-service',
  templateUrl: './four-service.component.html',
  styleUrls: ['./four-service.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}, {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class FourServiceComponent implements OnInit {
	
	countryInfo: any[] = [];
	Equipements: any[] = ["1"];
	Eventtype: any[] = ["1"];
	Eventtime: any[] = ["1"];
	allCountry: any;
	imageURL: any;
	updateValueForm:any;
	userDetail: any;
	eventData: any;
	isLoggedin: boolean = false;

	public fourServiceForm: FormGroup;

	public form = {
		fname: null, 
		lname:null,
		country_code: null,
		pincode: null,
		number: null,
		social_reason: null,
		address: null,
		city: null,
		postal_code: null,
		country: null,
		equipements: Array(),
		eventtype: Array(),
		number_of_pepole: null,
		start_date: null,
		eventTime: Array(),
		other: null,
		start:null
	}

	constructor(
		private router: Router,
		public auth: AuthenticationService,
		private userservice: UserService,
		private token: TokenService,
		private toastr: ToastrService,
		private service_four: ServiceFourService,
	) { 
		this.userservice.loadScript();
		this.getEventData();
		this.userDetail = this.token.userDetail('currentUser') || [];
		this.imageURL = environment.STORAGE_IMG_URL;
		console.log( this.userDetail );
		setTimeout( ()=>{
			this.form.fname = this.userDetail.fname || '';
			this.form.lname = this.userDetail.lname || '';
			this.form.country_code = this.userDetail.country_code || 'FRA';
			this.form.pincode = this.userDetail.phone_code || '+33';
			this.form.number = this.userDetail.number || '';
			this.form.address = ( this.userDetail.type == '1' ) ? this.userDetail.personal_address : this.userDetail.business_address || '';
			this.form.city = this.userDetail.city || '';
			this.form.postal_code = this.userDetail.postalcode || '';
			this.form.social_reason = this.userDetail.raison_sociale || '';
		}, 1000 );
	}

	ngOnInit() {
		this.getCountries();
	}

	submitForm(){
		$AB('#submit_button').click();
	}

	getEventData(){
		this.service_four.getEventOfficeData().subscribe(
			data => {
				this.eventData = data;
				console.log( this.eventData );
				//this.allCountry = data;
				//this.form.country = this.getSelectedCountry( this.userDetail.country_code );
				//console.log( this.getSelectedCountry( this.userDetail.country_code ) );
			},
			err => console.log(err)
		)
	}

	onChange(event){
		if(!this.Equipements.includes(event.value)){
			this.Equipements.push(event.value)
		}else{
			this.Equipements = $AB.grep(this.Equipements, function(value) {
				return value != event.value;
			});
		}
	}

	onETypeChange( event ){
		if(!this.Eventtype.includes(event.value)){
			this.Eventtype.push(event.value)
		}else{
			this.Eventtype = $AB.grep(this.Eventtype, function(value) {
				return value != event.value;
			});
		}
	}

	onTimeChange( event ){
		if(!this.Eventtime.includes(event.value)){
			this.Eventtime.push(event.value)
		}else{
			this.Eventtime = $AB.grep(this.Eventtime, function(value) {
				return value != event.value;
			});
		}
	}

	getSelectedCountry( country_code ){
		//73 is used for france
		if( typeof country_code === 'undefined' || country_code == '' ){
			return '73';
		}

		if( this.allCountry.length > 0 ){
			for ( var i = 0; i < this.allCountry.length; i++ ) {
				if( this.allCountry[i].iso3 == country_code ){
					return this.allCountry[i].id;
				}
			}
		} else {
			return '73';
		}
	}

	onSubmit() {
		//this.router.navigate(['/working-progress']);
		this.form.equipements = this.Equipements;
		this.form.eventtype = this.Eventtype;
		this.form.eventTime = this.Eventtime;
		this.service_four.addServiceFour(this.form).subscribe(
  			data => {
  				console.log(data);
  				if(data['status'] == 'false'){
  					this.toastr.error("E-mail non envoyé");
  				}
	            if(data['status'] == 'true'){
	            	var selectedService = this.token.get( 'service' );
	            	this.token.set( 'selectedService', selectedService );
	            	this.token.remove('service');
                	this.toastr.success("Ajouter un événement avec succès.");
	                this.router.navigate(['/merci']);
	            }
  			},
            error => {
                this.toastr.error(error.error.error);
            }
  		);
	}

	getCountries(){
		this.userservice.allCountries().subscribe(
			data => {
				this.allCountry = data;
				this.form.country = this.getSelectedCountry( this.userDetail.country_code );
			},
			err => console.log(err)
		)
	}

	changeCountry(){
		this.changePincode(this.form.country_code);
	}

	changePincode(containernew){
		var pincodeArr = {
			FRA: "+33",
			AFG: "+93",
			ALB: "+355",
			ALG: "+213",
			AND: "+376",
			ANG: "+244",
			ANT: "+1-268",
			ARG: "+54",
			ARM: "+374",
			ARU: "+297",
			ASA: "+1-684",
			AUS: "+61",
			AUT: "+43",
			AZE: "+994",
			BAH: "+1-242",
			BAN: "+880",
			BAR: "+1-246",
			BDI: "+257",
			BEL: "+32",
			BEN: "+	229",
			BER: "+1-441",
			BHU: "+975",
			BIH: "+387",
			BIZ: "+501",
			BLR: "+375",
			BOL: "+591",
			BOT: "+267",
			BRA: "+55",
			BRN: "+973",
			BRU: "+673",
			BUL: "+359",
			BUR: "+226",
			CAF: "236",
			CAM: "+855",
			CAN: "+1",
			CAY: "+1-345",
			CGO: "+242",
			CHA: "+235",
			CHI: "+56",
			CHN: "+86",
			CIV: "Cote d'Ivoire",
			CMR: "+237",
			COD: "+243",
			COK: "+682",
			COL: "+57",
			COM: "+269",
			CPV: "+238",
			CRC: "+506",
			CRO: "+385",
			CUB: "+53",
			CYP: "+357",
			CZE: "+420",
			DEN: "+45",
			DJI: "+253",
			DMA: "+1 767",
			DOM: "+1 809",
			ECU: "+593",
			EGY: "+20",
			ERI: "+291",
			ESA: "+503",
			ESP: "+34",
			EST: "+372",
			ETH: "+251",
			FIJ: "+679",
			FIN: "+358",
			FSM: "+691",
			GAB: "+241",
			GAM: "+220",
			GBR: "+44",
			GBS: "+245",
			GEO: "+995",
			GEQ: "+240",
			GER: "+49",
			GHA: "+233",
			GRE: "+30",
			GRN: "+1 473",
			GUA: "+502",
			GUI: "+224",
			GUM: "+1 671",
			GUY: "+592",
			HAI: "+509",
			HKG: "+852",
			HON: "+504",
			HUN: "+36",
			INA: "+62",
			IND: "+91",
			IRI: "+98",
			IRL: "+353",
			IRQ: "+964",
			ISL: "+354",
			ISR: "+972",
			ISV: "+00 1",
			ITA: "+39",
			IVB: "+1 284",
			JAM: "+1 876",
			JOR: "+962",
			JPN: "+81",
			KAZ: "+7 6",
			KEN: "+254",
			KGZ: "+996",
			KIR: "+686",
			KOR: "+82",
			KOS: "+383",
			KSA: "+966",
			KUW: "+965",
			LAO: "+856",
			LAT: "+371",
			LBA: "+218",
			LBR: "+231",
			LCA: "+1 758",
			LES: "+266",
			LIB: "+961",
			LIE: "+423",
			LTU: "+370",
			LUX: "+352",
			MAD: "+261",
			MAR: "+212",
			MAS: "+60",
			MAW: "+265",
			MDA: "+373",
			MDV: "+960",
			MEX: "+52",
			MGL: "+976",
			MHL: "+692",
			MKD: "+389",
			MLI: "+223",
			MLT: "+356",
			MNE: "+382",
			MON: "+377",
			MOZ: "+258",
			MRI: "+230",
			MTN: "+222",
			MYA: "+95",
			NAM: "+264",
			NCA: "+505",
			NED: "+31",
			NEP: "+977",
			NGR: "+234",
			NIG: "+227",
			NOR: "+47",
			NRU: "+674",
			NZL: "+64",
			OMA: "+968",
			PAK: "+92",
			PAN: "+507",
			PAR: "+595",
			PER: "+51",
			PHI: "+63",
			PLE: "+970",
			PLW: "+680",
			PNG: "+675",
			POL: "+48",
			POR: "+351",
			PRK: "+850",
			PUR: "+1 787",
			QAT: "+974",
			ROU: "+40",
			RSA: "+27",
			RUS: "+7",
			RWA: "+250",
			SAM: "+685",
			SEN: "+221",
			SEY: "+248",
			SIN: "+65",
			SKN: "+1 869",
			SLE: "+232",
			SLO: "+386",
			SMR: "+378",
			SOL: "+677",
			SOM: "+252",
			SRB: "+381",
			SRI: "+94",
			SSD: "+211",
			STP: "+239",
			SUD: "+249",
			SUI: "+41",
			SUR: "+597",
			SVK: "+421",
			SWE: "+46",
			SWZ: "+268",
			SYR: "+963",
			TAN: "+255",
			TGA: "+676",
			THA: "+66",
			TJK: "+992",
			TKM: "+993",
			TLS: "+670",
			TOG: "+228",
			TPE: "+886",
			TTO: "+1 868",
			TUN: "+216",
			TUR: "+90",
			TUV: "+688",
			UAE: "+971",
			UGA: "+256",
			UKR: "+380",
			URU: "+598",
			USA: "+1",
			UZB: "+998",
			VAN: "+678",
			VEN: "+58",
			VIE: "+84",
			VIN: "+1 784",
			YEM: "+967",
			ZAM: "+260",
			ZAN: "+255 24",
			ZIM: "+263"
		}
		var self = this;

		$AB.each(pincodeArr, function(key, value) {
		    	if(key == containernew){
					self.form.pincode = value;
		    	}
		});
	}

}
