import { Component, OnInit, Output, EventEmitter  } from '@angular/core';

import { AuthenticationService } from '../../service/authentication.service';
import { TokenService } from '../../service/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
// import { UserService } from '../../service/user.service';
import * as $ from 'jquery';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {  	

    //public resetPasswordForm: FormGroup;
    step:any;
    submitted = false;
    resetPasswordToken: any;
    // public spaceData: any;
    public form={
        newPassword: null,
        confirmPassword: null,
    }

    checkPasswords(group: FormGroup) { // here we have the 'passwords' group
        let pass = group.get('newPassword').value;
        let confirmPass = group.get('confirmPassword').value;

        return pass === confirmPass ? null : { notSame: true }     
    }

    resetPasswordForm = this.fb.group({
        newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]],
        confirmPassword: ['']
    },{ validator:this.checkPasswords })

    constructor(
        private router: Router,
        private auth: AuthenticationService,
        private token: TokenService,
        private toastr: ToastrService,
        private cookie: CookieService,
        public fb: FormBuilder,
        private location: Location,
        // private userService: UserService
    ) { 

        // this.userService.loadScript();

        var url = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);
        $(document).ready(function() {
            $('body').removeClass();
            $('body').addClass(url);
        });

        if (this.auth.isLoggedIn()) {
            this.router.navigate(['/accueil']);
            //this.back();
        }

        this.resetPasswordToken = this.getUrlParameter('token');
        this.token.set( 'resetPasswordToken', this.resetPasswordToken );
        
    }

    ngOnInit() {
    }

    get f() { return this.resetPasswordForm.controls; }

    submitForm(){
        $('#submitButton').click();
    }

    onSubmit(){

        this.submitted = true;
        if (this.resetPasswordForm.invalid) {;
            return;
        }

        this.auth.resetPassword(this.resetPasswordForm.value,this.token.get( 'resetPasswordToken')).subscribe(
            data => {
                if(data['status'] == 'true'){
                    this.toastr.success("Changement de mot de passe réussi.");
                    this.token.remove( 'resetPasswordToken' );

                    setTimeout( ()=>{
                        this.router.navigate(['/connexion']);
                    }, 1000 );
                    
                }else{
                    this.toastr.error('Quelque chose ne va pas, service réessayer.');
                }
            },
            error => {
                this.toastr.error(error.error.error);
            }
        );
    }

    back(){
        this.step = this.token.get('step');
        this.token.set('step', this.step - 1);
        this.location.back();
    }

    getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };

}
