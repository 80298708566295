import { Injectable, EventEmitter } from '@angular/core';

import { environment } from '../../environments/environment';
import { TokenService } from '../service/token.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../model/user';
import { map } from 'rxjs/operators';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public loginEvent: EventEmitter<any>;
    baseUrl: any;
    isLoggedin: boolean = false;

    constructor(private http: HttpClient, private token: TokenService) {
        this.baseUrl = environment.APP_URL;
        this.loginEvent = new EventEmitter();
    }

    login(data){
      return this.http.post(`${this.baseUrl}/auth/login`, data);
    }

    forgotPassword(data){
      return this.http.post(`${this.baseUrl}/auth/forgotpassword`, data);
    }

    resetPassword(data,resetPasswordToken){
      data['resetPasswordToken'] = resetPasswordToken;
      //console.log(data);
      //return;
      return this.http.post(`${this.baseUrl}/auth/resetpassword`, data);
    }

    register(data){
      return this.http.post(`${this.baseUrl}/auth/register`, data);
    }

    logout(){
      localStorage.clear();
    }

    isLoggedIn(){
      if (localStorage.getItem('currentUser') == null || localStorage.getItem('token') == null){
        this.isLoggedin = false;
        return this.isLoggedin;
      }else{
        return true;
      }
    }

    eyeHideShow(){

      $('#password').attr('type', function(index, attr){
        return attr == 'password' ? 'text' : 'password';
      });

      $('.pass-eye i').toggleClass('fa fa-eye');
      $('.pass-eye i').toggleClass('fa fa-eye-slash');
    }
}
