import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from '../../service/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenService } from '../../service/token.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import * as $ from 'jquery';

@Component({
	selector: 'app-connection-and-security',
	templateUrl: './connection-and-security.component.html',
	styleUrls: ['./connection-and-security.component.css']
})
export class ConnectionAndSecurityComponent implements OnInit {

	userDetail: any;
	userId: any;
	submitted = false;
	base64textString:any;

	checkPasswords(group: FormGroup) { // here we have the 'passwords' group
		let pass = group.get('newPassword').value;
		let confirmPass = group.get('confirmPassword').value;

		return pass === confirmPass ? null : { notSame: true }     
	}

	changePasswordForm = this.fb.group({
		oldPassword: ['', Validators.required],
		newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]],
		confirmPassword: [''],
		user_id: ['']
	},{ validator:this.checkPasswords })

	constructor(
		private router: Router,
		private token: TokenService,
		private auth: AuthenticationService,
		private toastr: ToastrService,
		public fb: FormBuilder,
		private userservice: UserService,
	) { 
		this.userservice.loadScript();
		this.userDetail = this.token.userDetail('currentUser');
		this.userId =  this.token.getUser();
	}

	ngOnInit() {
	}

	get f() { return this.changePasswordForm.controls; }

	submitForm(){
		$('#submitButton').click();
	}

	onSubmit(){
		this.changePasswordForm.value.user_id = this.userId;

		this.submitted = true;
		if (this.changePasswordForm.invalid) {;
			return;
		}

  		this.userservice.changePassword(this.changePasswordForm.value).subscribe(
  			data => {
	            if(data['status'] == 'false'){
                	this.toastr.error("Ancien mot de passe incorrect, veuillez réessayer.");
	            }
	            if(data['status'] == 'true'){
		  			this.token.set('currentUser', JSON.stringify(data['user']));
		  			this.token.set('token', data['token']);
		            var userId =  this.token.getUser();
                	this.toastr.success("Votre mot de passe a été changé.");
	                this.router.navigate(['/mon-compte']);
	            }
  			},
            error => {
                // this.toastr.error(error.error.error);
            }
  		);
  	}

}
