import { Component, Injectable, OnInit } from '@angular/core';

import { UserService } from '../../service/user.service';
import { NgbDatepickerI18n, NgbDateStruct, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TokenService } from '../../service/token.service';
import { environment } from '../../../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AbstractControl, FormBuilder, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { AuthenticationService } from '../../service/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';

const I18N_VALUES = {
  	'fr': {
    	weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    	months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  	}
  	// other languages you would support
};

@Injectable()
export class I18n {
  	language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  	constructor(private _i18n: I18n) {
    	super();
  	}

  	getWeekdayShortName(weekday: number): string {
    	return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  	}
  	getMonthShortName(month: number): string {
		return I18N_VALUES[this._i18n.language].months[month - 1];
  	}
  	getMonthFullName(month: number): string {
    	return this.getMonthShortName(month);
  	}

  	getDayAriaLabel(date: NgbDateStruct): string {
    	return `${date.day}-${date.month}-${date.year}`;
  	}
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
	
	readonly DELIMITER = '/';

  	parse(value: string): NgbDateStruct {
    	let result: NgbDateStruct = null;
		if (value) {
  			let date = value.split(this.DELIMITER);
  			result = {
    			day : parseInt(date[0], 10),
    			month : parseInt(date[1], 10),
    			year : parseInt(date[2], 10)
  			};
		}
    	return result;
  	}

  	format(date: NgbDateStruct): string {
    	let result: string = null;
    	if (date) {
      		result = date.day + this.DELIMITER + date.month + this.DELIMITER + date.year;
    	}
    	return result;
  	}
}

function conditionalValidator(condition: (() => boolean), validator: ValidatorFn): ValidatorFn {
  	return ( control: AbstractControl ): {[key: string]: any} => {
    	if ( ! condition() ) {
      		return null;
    	}
    	return validator( control );
  	}
}


@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}, {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
})
export class PersonalInfoComponent implements OnInit {

	userDetail: any;

	base64textString:any;
	filedata:any;
	userId: any;
	submitted = false;
	updateValueForm:any;
	currentYear:any;
	currentMonth:any;
	currentDay:any;
	DOB: any;
	baseURL: any;
	storageURL: any;
	userUpdateForm: any;

	constructor(
		private userservice: UserService,
		private router: Router,
		private token: TokenService,
		private auth: AuthenticationService,
		private toastr: ToastrService,
		public fb: FormBuilder,
		public datepipe: DatePipe,
	) { 

		this.userservice.loadScript();
		this.userDetail = this.token.userDetail('currentUser');
		console.log( this.userDetail );
		this.userUpdateForm = this.fb.group({
			fname: ['', Validators.required],
			lname: ['', Validators.required],
			genre: ['', Validators.required],
			country: [''],
			pincode: ['', Validators.required],
			number: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			p_address: [ '', conditionalValidator( () => ( this.userDetail.type == '1' ), Validators.required ) ],
			siren: [ '', conditionalValidator( () => ( this.userDetail.type == '2' ), Validators.required ) ],
			raison_sociale: [ '', conditionalValidator( () => ( this.userDetail.type == '2' ), Validators.required ) ],
			tva: [ '', conditionalValidator( () => ( this.userDetail.type == '2' ), Validators.required ) ],
			business_address: [ '', conditionalValidator( () => ( this.userDetail.type == '2' ), Validators.required ) ],
			dob: [''],
			//transport: [''],
			//profile: [''],
			image: [''],
			user_id: ['', Validators.required],
			city: [''],
			postalcode: ['']
		})

		this.userId =  this.token.getUser();
		this.baseURL = environment.IMG_URL;
		this.storageURL = environment.STORAGE_IMG_URL;
		
		if( typeof this.userDetail.dob !== 'undefined' && this.userDetail.dob != '' && this.userDetail.dob != '0000-00-00' ){
			console.log(this.userDetail.dob);
			var datepiedate = this.datepipe.transform(this.userDetail.dob, 'yyyy/dd/MM').split('/')


			// var dob = new Date(this.userDetail.dob);
			// this.DOB =  { year: dob.getFullYear(), 
			// 			  month: dob.getMonth(), 
			// 			  day: dob.getDate()
			// 			};

			this.DOB =  { year: Number(datepiedate[0]), // year
						  month: Number(datepiedate[2]), // month
						  day: Number(datepiedate[1]) // day
						};
		} else {
			//this.DOB = '';
		}

		setTimeout(()=>{
			this.updateValueForm = {
				fname: this.userDetail.fname,
				lname: this.userDetail.lname,
				genre: this.userDetail.title,
				country: this.userDetail.country_code,
				pincode: this.userDetail.phone_code,
				number: this.userDetail.number,
				email: this.userDetail.email,
				p_address: this.userDetail.personal_address,
				siren: this.userDetail.siren,
				raison_sociale: this.userDetail.raison_sociale,
				tva: this.userDetail.tva,
				business_address: this.userDetail.business_address,
				//transport: this.userDetail.transport,
				//profile: this.userDetail.profile,
				//dob: this.DOB || '',
				image: null,
				user_id: this.userId,
				city: this.userDetail.city || '',
				postalcode: this.userDetail.postalcode || ''
			}
			if( this.DOB != '' ){
				this.updateValueForm.dob = this.DOB;
			}
			this.userUpdateForm.patchValue(this.updateValueForm);
		}, 1000);
	}

	ngOnInit() {
		this.currentYear = new Date().getFullYear();
		this.currentMonth = new Date().getMonth();
		this.currentDay = new Date().getDay();
	}

	get f() { return this.userUpdateForm.controls; }

	submitForm(){
		console.log('call');
		$('#submitButton').click();
	}

	onSubmit(){
		console.log('call sumit');
		this.userUpdateForm.value.image = this.base64textString;
		this.userUpdateForm.value.user_id = this.userId;
		this.submitted = true;
		if (this.userUpdateForm.invalid) {
			console.log('call invalid');
			//return;
		}
		console.log('not invalid');
		//return;

  		this.userservice.userDetailUpdate(this.userUpdateForm.value).subscribe(
  			data => {
  				console.log('call success');
	  			this.token.set('currentUser', JSON.stringify(data['user']));
	            var userId =  this.token.getUser();
	            this.auth.loginEvent.emit(data['user'])
	            if(userId){
                	this.toastr.success("Utilisateur enregistré avec succès.");
	                this.router.navigate(['/mon-compte']);
	            }
  			},
            error => {
                this.toastr.error(error.error.error);
            }
  		);
  	}

  	fileEvent(event){
		this.filedata = event.target.files[0];

		var reader = new FileReader();

        reader.onload =this._handleReaderLoaded.bind(this);

        reader.readAsBinaryString(this.filedata);
	}

	_handleReaderLoaded(readerEvt) {
		var binaryString = readerEvt.target.result;
		this.base64textString= btoa(binaryString);
	}

	uploadFile(target) {
		document.getElementById("file-name").innerHTML = target.files[0].name;
	}

	changeCountry(){
		this.changePincode(this.userUpdateForm.value.country);
	}

	changePincode(containernew){
		var pincodeArr = {
			FRA: "+33",
			AFG: "+93",
			ALB: "+355",
			ALG: "+213",
			AND: "+376",
			ANG: "+244",
			ANT: "+1-268",
			ARG: "+54",
			ARM: "+374",
			ARU: "+297",
			ASA: "+1-684",
			AUS: "+61",
			AUT: "+43",
			AZE: "+994",
			BAH: "+1-242",
			BAN: "+880",
			BAR: "+1-246",
			BDI: "+257",
			BEL: "+32",
			BEN: "+	229",
			BER: "+1-441",
			BHU: "+975",
			BIH: "+387",
			BIZ: "+501",
			BLR: "+375",
			BOL: "+591",
			BOT: "+267",
			BRA: "+55",
			BRN: "+973",
			BRU: "+673",
			BUL: "+359",
			BUR: "+226",
			CAF: "236",
			CAM: "+855",
			CAN: "+1",
			CAY: "+1-345",
			CGO: "+242",
			CHA: "+235",
			CHI: "+56",
			CHN: "+86",
			CIV: "Cote d'Ivoire",
			CMR: "+237",
			COD: "+243",
			COK: "+682",
			COL: "+57",
			COM: "+269",
			CPV: "+238",
			CRC: "+506",
			CRO: "+385",
			CUB: "+53",
			CYP: "+357",
			CZE: "+420",
			DEN: "+45",
			DJI: "+253",
			DMA: "+1 767",
			DOM: "+1 809",
			ECU: "+593",
			EGY: "+20",
			ERI: "+291",
			ESA: "+503",
			ESP: "+34",
			EST: "+372",
			ETH: "+251",
			FIJ: "+679",
			FIN: "+358",
			FSM: "+691",
			GAB: "+241",
			GAM: "+220",
			GBR: "+44",
			GBS: "+245",
			GEO: "+995",
			GEQ: "+240",
			GER: "+49",
			GHA: "+233",
			GRE: "+30",
			GRN: "+1 473",
			GUA: "+502",
			GUI: "+224",
			GUM: "+1 671",
			GUY: "+592",
			HAI: "+509",
			HKG: "+852",
			HON: "+504",
			HUN: "+36",
			INA: "+62",
			IND: "+91",
			IRI: "+98",
			IRL: "+353",
			IRQ: "+964",
			ISL: "+354",
			ISR: "+972",
			ISV: "+00 1",
			ITA: "+39",
			IVB: "+1 284",
			JAM: "+1 876",
			JOR: "+962",
			JPN: "+81",
			KAZ: "+7 6",
			KEN: "+254",
			KGZ: "+996",
			KIR: "+686",
			KOR: "+82",
			KOS: "+383",
			KSA: "+966",
			KUW: "+965",
			LAO: "+856",
			LAT: "+371",
			LBA: "+218",
			LBR: "+231",
			LCA: "+1 758",
			LES: "+266",
			LIB: "+961",
			LIE: "+423",
			LTU: "+370",
			LUX: "+352",
			MAD: "+261",
			MAR: "+212",
			MAS: "+60",
			MAW: "+265",
			MDA: "+373",
			MDV: "+960",
			MEX: "+52",
			MGL: "+976",
			MHL: "+692",
			MKD: "+389",
			MLI: "+223",
			MLT: "+356",
			MNE: "+382",
			MON: "+377",
			MOZ: "+258",
			MRI: "+230",
			MTN: "+222",
			MYA: "+95",
			NAM: "+264",
			NCA: "+505",
			NED: "+31",
			NEP: "+977",
			NGR: "+234",
			NIG: "+227",
			NOR: "+47",
			NRU: "+674",
			NZL: "+64",
			OMA: "+968",
			PAK: "+92",
			PAN: "+507",
			PAR: "+595",
			PER: "+51",
			PHI: "+63",
			PLE: "+970",
			PLW: "+680",
			PNG: "+675",
			POL: "+48",
			POR: "+351",
			PRK: "+850",
			PUR: "+1 787",
			QAT: "+974",
			ROU: "+40",
			RSA: "+27",
			RUS: "+7",
			RWA: "+250",
			SAM: "+685",
			SEN: "+221",
			SEY: "+248",
			SIN: "+65",
			SKN: "+1 869",
			SLE: "+232",
			SLO: "+386",
			SMR: "+378",
			SOL: "+677",
			SOM: "+252",
			SRB: "+381",
			SRI: "+94",
			SSD: "+211",
			STP: "+239",
			SUD: "+249",
			SUI: "+41",
			SUR: "+597",
			SVK: "+421",
			SWE: "+46",
			SWZ: "+268",
			SYR: "+963",
			TAN: "+255",
			TGA: "+676",
			THA: "+66",
			TJK: "+992",
			TKM: "+993",
			TLS: "+670",
			TOG: "+228",
			TPE: "+886",
			TTO: "+1 868",
			TUN: "+216",
			TUR: "+90",
			TUV: "+688",
			UAE: "+971",
			UGA: "+256",
			UKR: "+380",
			URU: "+598",
			USA: "+1",
			UZB: "+998",
			VAN: "+678",
			VEN: "+58",
			VIE: "+84",
			VIN: "+1 784",
			YEM: "+967",
			ZAM: "+260",
			ZAN: "+255 24",
			ZIM: "+263"
		}
		var self = this;
		
		$.each(pincodeArr, function(key, value) {
			if(key == containernew){
				self.userUpdateForm.value.pincode = value;
				$('#pincode option.'+key).attr('selected', true);
			}
		});
	}
}
