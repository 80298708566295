import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceFourService {

	baseUrl: any;

  	constructor(private http: HttpClient) { 
  		this.baseUrl = environment.APP_URL;
  	}

  	addServiceFour(data){
  		return this.http.post(`${this.baseUrl}/addFourService`, data);
  	}

  	getEventOfficeData(){
  		return this.http.get(`${this.baseUrl}/getFourServiceOffice`);
  	}
}
