import { Component, OnInit } from '@angular/core';

import { TokenService } from '../../service/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from '../../service/payment.service';
import { UserService } from '../../service/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var jQuery: any;

@Component({
	selector: 'app-thanks',
	templateUrl: './thanks.component.html',
	styleUrls: ['./thanks.component.css']
})
export class ThanksComponent implements OnInit {

	allData:any = {};
	paymentForm: FormGroup;
	selectedService: any;
	officeData: any;
	heading: any;
	sub_heading: any;
	btn_url: any;
	button_label: any;
	orderData:any = {};

	constructor(
		private router: Router,
		private token: TokenService,
		private cookie: CookieService,
		private userserivce: UserService,
		private toastr: ToastrService,
		private payment: PaymentService,
		private fb: FormBuilder,
	) {
		this.selectedService = this.token.get( 'service' ) || this.token.get( 'selectedService' );
		this.token.set( 'selectedService', this.selectedService );
		this.orderData['orderType'] = this.selectedService;
		var self = this;
		this.userserivce.getThankyoumsg( this.orderData ).subscribe(
			data => {
				self.heading = data['heading'];
				self.sub_heading = data['sub_heading'];
				self.btn_url = data['button_url'].substr(data['button_url'].lastIndexOf("/") + 1);
				self.button_label = data['button_label'];
			},
			err => console.log(err)
		)
	}

	ngOnInit() {

		var status = this.getUrlParameter('status');
		if(status == "true"){

			//let updateWalletTotal = this.token.get( 'walletUpdatePrice' );
			if( this.token.get( 'walletUpdatePrice' ) ){
				let walletData = {};
				walletData['user_id'] = this.token.getUser();
				walletData['updateWalletTotal'] = this.token.get('walletUpdatePrice');
				this.userserivce.updateWalletBalance(walletData).subscribe(
		  			data => {
			            if( data['status'] == 'true' ){
			            	
			            }
		  			},
		            error => {
		                this.toastr.error(error.error.error);
		            }
		  		);
			}

			this.token.remove('menu');
			this.token.remove('eventData');
			this.token.remove('service');
			this.token.remove('event_type');
			this.token.remove('nomade_service');
			this.token.remove('room_size');
			this.token.remove('full_price');
			this.token.remove('hour_price');
			this.token.remove('half_price');
			this.token.remove('menuPrice');
			this.token.remove('totalPrice');
			this.token.remove('totalSubmitPrice');
			this.token.remove('walletTotalDesc');
			this.token.remove('service_cost');
			this.token.remove('service_type');
			this.token.remove('fetchEvent');
			this.token.remove('walletDiscount');
			this.token.remove('price');
			this.token.remove('walletUpdatePrice');
			this.token.remove('contract');
			this.token.remove('amountType');
			this.token.remove('percentage');
			this.token.remove('couponDiscount');
			this.token.remove('couponName');
			this.token.remove('taxAmount');
			this.token.remove('editOrder');
			this.token.remove('editOrderId');
			this.token.remove('edit_order_created_at');

			this.token.remove('selected_events');
			this.token.remove('room_type');
			this.token.remove('item_key');
			this.token.remove('event_key');
			this.token.remove('CurrentItemKey');
			this.token.remove('selected_events_temp');

			this.cookie.delete('service');
			this.cookie.delete('space');
			this.cookie.delete('eventData');
			this.cookie.delete('totalPrice');
		}
		if(status == "false"){
			this.toastr.error("Le paiement a échoué, veuillez réessayer..");
			this.router.navigate(['/récapitulatif-de-la-commande']);
		}
	}

	getUrlParameter(sParam) {
		var sPageURL = window.location.search.substring(1),
		sURLVariables = sPageURL.split('&'),
		sParameterName,
		i;

		for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=');

			if (sParameterName[0] === sParam) {
				return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
			}
		}
	};

}
