import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../service/user.service';
import { StoreService } from '../../service/store.service';
import { TokenService } from '../../service/token.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import * as moment from 'moment';
declare var jQuery: any;

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

	appointments: any = [];
    userId: any = {};
    refundOrderData: any = {};
	myOrders: any;
	myOrderMenu : any;
	orderId: any = {};
	pdfURL: any;
	orderCount: any;
	imageURL: any;
	NomadeOrderImageURL: any;
	MeetingRoomOrderImageURL: any;
	NomadeOrderImageDetail: any;
	MeetingRoomOrderImageDetail: any;
	eventData: any;
	eventArray: any = [];
	nextPrevArray: any = [];
    eventDataThirtyDay = [];
    eventDataTenDay = [];
    eventDataOneDay = [];
    eventDataOneMonth = [];
    eventDataHour = [];
    eventDataHalfDay = [];
    isSelectedEvent = [];
	selectedOrderId: any;
	selectedOrderBookedDay : any;
	selectedOrderServiceDay : any;
	fetchAllEvent: any;
    full_day_label: any = 'J'; //Journée entière';
    morning_label: any = 'M'; //Matin';
    afternoon_label: any = 'A'; //Après-midi';
    unavailable_label: any  = 'F'; //Fermé
    unavailable_class: any = 'unavailable';
    meetingRoomIds: any = [];
    currentOrderId: any;
    currentOrderDate: any;
    currentOrderPaymentStatus: any;

	constructor(
		private userservice: UserService,
		private store: StoreService,
		private token: TokenService,
		private toastr: ToastrService,
		public datepipe: DatePipe,
        private router: Router,
	) { 
		this.userservice.loadScript();
		this.imageURL = environment.STORAGE_IMG_URL;
		this.userId['user_id'] = this.token.getUser();
		this.myOrder();
		this.getNomadeOrderImage();
		this.getMeetingRoomOrderImage();
	}

	ngOnInit() {
		var self = this;
        var icon = "X";
        jQuery('#calendar').fullCalendar({
            themeSystem: 'bootstrap4',
            businessHours: false,
            defaultView: 'month',
            editable: true,
            header: {
                left: 'title',
                center: '',
                right: 'prev,next'
            },
            week: {
                dow: 1,
                doy: 4
            },
            buttonText: {
                today: "Aujourd'hui",
                year: "Année",
                month: "Mois",
                week: "Semaine",
                day: "Jour",
                list: "Mon planning"
            },
            firstDay: '1',
            weekLabel: "Sem.",
            monthNames: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
            monthNamesShort: ['Jan','Fév','Mar','Avr','Mai','Jui','Jui','Aoû','Sep','Oct','Nov','Déc'],
            dayNames: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
            dayNamesShort:["Dim.","Lun.","Mar.","Mer.","Jeu.","Ven.","Sam."],
            allDayHtml: "Toute la<br/>journée",
            eventLimitText: "en plus",
            noEventsMessage: "Aucun événement à afficher",
            eventAfterAllRender: function(view){

                jQuery(this).html(' ');
                jQuery('.event_selection').remove();

                var today = new Date();
                var dd    = String(today.getDate()).padStart(2, '0');
                var mm    = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy  = today.getFullYear();
                    today = new Date(yyyy+'-'+mm+'-'+dd); // today date

                jQuery('#calendar .fc-day.fc-widget-content').each(function(i) {

                    var html = '';

                    // Fullcalendar includes a date data element on each day.
                    var date          = jQuery(this).data('date');
                    var selected_date = new Date(date);

                    // prevent to past dates.
                    if(selected_date.getTime() < today.getTime()){
                        return;
                    }
                    
                    if( !self.checkSelectDayIsAvailable(date) ){
                        html = '<div class="event_selection foo">';
                            html += self.unavailableDayHtml(date);
                        html += '</div>';
                        //console.log(self.unavailable_class);
                        jQuery(this).append(html).addClass(self.unavailable_class);
                        if( self.getUnavailableMsg(date) ){
                            var unavailableFullMsg = self.getUnavailableFullMsg( date );
                            //console.log( jQuery(this) );
                            jQuery(this).attr( "data-tooltip", unavailableFullMsg );
                        }
                    }else{
                        jQuery(this).append(html);
                    }


                });
            },
            eventRender: function(event, element) {
                let current_date = self.datepipe.transform(event.start._i, 'yyyy-MM-dd');
                $( '[data-date="'+current_date+'"].fc-day' ).addClass('full_day');
                $( '[data-date="'+current_date+'"].fc-day-number' ).addClass('date-highlight');
                if( event.className[0] != 'fetchEvent' ){
                    if( self.checkSelectDayIsAvailable( current_date ) ){
                        element.find('.fc-content').append("<span class='closeon' style='font-weight:900; color:white; font-size:10px; float:right;'>X</span>");
                    }
                    //element.parent('td').addClass('full_day');
                }

                element.find('.closeon').click(function() {
                    var event_date = JSON.parse(self.token.get('eventData'));
                    var currentEvent = self.token.get('service_type');
                    if( currentEvent == 'Mois' ){
                        let tempMonthData = [];
                        for( let i=0; i < event_date.length; i++ ){
                            let event_id = '_fc' + ( i + 1 );
                            tempMonthData.push({
                                start: event_date[i]['start'],
                                end : event_date[i]['end'],
                                id: event_id,
                                event_type : self.token.get('length_of_booking')
                            });
                        }
                        jQuery('#calendar').fullCalendar( 'removeEventSource', tempMonthData );
                        self.token.remove('eventData');
                        self.eventDataOneMonth = [];
                        self.eventNextPrev();
                    } else {
                        jQuery('#calendar').fullCalendar('removeEvents', event._id);
                        for( let i=0; i < event_date.length; i++ ){

                            var startDate = self.datepipe.transform(event_date[i].start, 'yyyy-MM-dd');
                            var startDateEvent = self.datepipe.transform(event.start._i, 'yyyy-MM-dd');
                            var endDate = self.datepipe.transform(event_date[i].end, 'yyyy-MM-dd');
                            var endDateEvent = self.datepipe.transform(event.end._i, 'yyyy-MM-dd');

                            if( startDate === startDateEvent && endDate === endDateEvent ){
                                var index = event_date.indexOf(startDate);
                                event_date.splice(i, 1);
                                self.eventDataThirtyDay = event_date;
                                self.eventDataTenDay = event_date;
                                self.eventDataOneDay = event_date;
                                self.eventDataOneMonth = event_date;
                                self.eventDataHour = event_date;
                                self.eventDataHalfDay = event_date;
                                self.token.set('eventData', JSON.stringify(event_date));
                                $( '[data-date="'+event.start._i+'"].fc-day' ).removeClass('full_day');
                                $( '[data-date="'+event.start._i+'"].fc-day-number' ).removeClass('date-highlight');
                            }
                        }
                    }
                });
            },
            dayClick: function(date, jsEvent, view, element) {
                /*console.log( date.format('YYYY-MM-DD') );
                console.log( moment( date.format('YYYY-MM-DD') ).add(2, 'M').format('YYYY-MM-DD') );*/
                // jQuery('#calendar').fullCalendar('gotoDate', moment(date.format('YYYY-MM-DD')).add(2, 'M').format('YYYY-MM-DD'));
                let eventExist = 'false';
                if (moment().format('YYYY-MM-DD') === date.format('YYYY-MM-DD') || date.isAfter(moment())) {
                    // This allows today and future date

                    var selectedDate = date.format('YYYY-MM-DD');
                    var remain_selection_days = Number( self.token.get( 'remain_booking' ) );
                    if( !self.checkSelectDayIsAvailable(selectedDate) ){
                        return;
                    }

                    jQuery('#calendar').fullCalendar('clientEvents', function(event) {
                        if(event.start <= date && event.end >= date) {
                            eventExist = 'true';
                        }
                    });
                    if (eventExist == 'true') {
                        jQuery("#eventExist").modal();
                    }

                    if(eventExist == 'false'){
                        jQuery('#event_date').val(date);

                        if( self.token.get('length_of_booking') == "1" ){
                            var event_date = self.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd');
                            if( self.dateIsSelectable( event_date, '1' ) ){
                            	if( self.eventDataThirtyDay.length < remain_selection_days ){
	                                self.eventDataThirtyDay.push({
	                                    start: event_date,
	                                    end : event_date,
	                                    event_type : self.token.get('length_of_booking')
	                                })
	                                self.token.set('eventData', JSON.stringify(self.eventDataThirtyDay));
	                            }else{
	                                jQuery('#thirtyDayEventError').modal();
	                            }
	                            self.addThirtyDayEvent();
                            } else {
                            	jQuery('#OutofSelectionError').modal();
                            }
                        }

                        if( self.token.get('length_of_booking') == "4" ){
                            var event_date = self.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd');
                            if( self.dateIsSelectable( event_date, '4' ) ){
	                            if( self.eventDataTenDay.length < remain_selection_days ){
	                                self.eventDataTenDay.push({
	                                    start: event_date,
	                                    end : event_date,
	                                    event_type : self.token.get('length_of_booking')
	                                })
	                                self.token.set('eventData', JSON.stringify(self.eventDataTenDay));
	                            }else{
	                                jQuery('#tenDayEventError').modal();
	                            }

	                            self.addTenDayEvent();
	                        } else {
	                        	jQuery('#OutofSelectionError').modal();
	                        }
                        }
                    }

                } else {
                    // Else part is for past dates
                    jQuery('#pastDayClick').modal();
                }
            },
            eventDestroy: function( event, element ){
                let current_date = self.datepipe.transform(event.start._i, 'yyyy-MM-dd');
                if( event.className[0] != 'fetchEvent' ){
                    $( '[data-date="'+current_date+'"].fc-day' ).removeClass('full_day');
                    $( '[data-date="'+current_date+'"].fc-day-number' ).removeClass('date-highlight');
                }
            },
            eventClick: function(event, jsEvent, view) {
                /*console.log('eventClick');
                var similarEvents = $("#calendar").fullCalendar('clientEvents', function(e) { return e.test === event.test });

                for (var i = 0; similarEvents.length > i ; i++){
                    similarEvents[i].className = 'full_day';
                    $('#calendar').fullCalendar('updateEvent', similarEvents[i]);
                }*/
            },
            /*loading: function( isLoading, view ) {
                if(isLoading) {// isLoading gives boolean value  
                    console.log('start loading');
                } else {
                    console.log('stop loading');
                }
            }*/
        })

        jQuery('.fc-prev-button span').click(function(){
            self.eventNextPrev();
        });

        jQuery('.fc-next-button span').click(function(){
            self.eventNextPrev();
        });

        var goToDate = JSON.parse(this.token.get('eventData'))

        if(this.token.get('eventData')){
            if( typeof goToDate !== 'undefined' && goToDate.length > 0 ){
                jQuery('#calendar').fullCalendar('gotoDate', goToDate[0].start);
            }
        }

        jQuery('#calendar-modal-view-event').on('shown.bs.modal', function () {
            //console.log('calendar modal show');
            jQuery("#calendar").fullCalendar('render');
            // self.fetchAllEvent = JSON.parse(self.token.get('fetchEvent'));
            // console.log(self.fetchAllEvent);
            //jQuery('#calendar').fullCalendar('addEventSource', self.fetchAllEvent);
            jQuery('#calendar').fullCalendar('refetchEvents');
            if(self.token.get('length_of_booking') == "1"){
                self.addThirtyDayEvent();
            }

            if(self.token.get('length_of_booking') == "4"){
                self.addTenDayEvent();
            }
        });

        jQuery('#calendar-modal-view-event').on('hide.bs.modal', function () {
        	self.token.remove( 'eventData' );
        	self.eventDataThirtyDay = [];
    		self.eventDataTenDay = [];
        });
	}

	myOrder(){
		this.userservice.getOrder(this.userId).subscribe(
			data => {
				if(data['status'] == 'true'){
					this.myOrders = data['order'];
					this.orderCount = this.myOrders.length;
					for (let i = 0; i < data['order'].length; i++) {
                        
                        var title = '';
                        if(data['order'][i].order_type == 1 && data['order'][i].length_of_booking == 1){
                            title = '30 Jours'
                        }else if(data['order'][i].order_type == 2 && data['order'][i].length_of_booking == 2){
                            title = 'Half Day';
                        }else if(data['order'][i].order_type == 1 && data['order'][i].length_of_booking == 2 || data['order'][i].order_type == 2 && data['order'][i].length_of_booking == 3){
                            title = 'La journée';
                        }else if(data['order'][i].order_type == 1 && data['order'][i].length_of_booking == 3){
                            title = 'Le mois';
                        }else if(data['order'][i].order_type == 1 && data['order'][i].length_of_booking == 4){
                            title = '10 Jours';
                        }else if(data['order'][i].order_type == 2 && data['order'][i].length_of_booking == 1){
                            title = 'Hourly';
                        }
                        if( data['order'][i].start_date != null && data['order'][i].end_date != null && this.token.get('userId') != null && this.token.get('userId') == data['order'][i].user_id && data['order'][i].appointment.length > 0 ){
                            this.eventArray.push({
                                title: this.full_day_label,
                                start: data['order'][i].start_date,
                                end: data['order'][i].end_date,
                                allDay: true,
                                editable:false,
                                color: 'yellow',
                                className: 'fetchEvent'
                            });
                        }

                        if(data['order'][i].start_date == null && data['order'][i].end_date == null && this.token.get('userId') != null && this.token.get('userId') == data['order'][i].user_id  && data['order'][i].appointment.length > 0 ){
                            for (let j = 0; j < data['order'][i].appointment.length; j++) {
                                this.eventArray.push({
                                    title: this.full_day_label,
                                    start: data['order'][i].appointment[j].start_date,
                                    end: data['order'][i].appointment[j].end_date,
                                    allDay: true,
                                    editable:false,
                                    color: 'yellow',
                                    className: 'fetchEvent'
                                });
                            }
                        }
                    }
					// console.log(this.myOrders.length);
				}
				jQuery('#calendar').fullCalendar('addEventSource', this.eventArray);
                this.token.set('fetchEvent', JSON.stringify(this.eventArray));

                var event_date = JSON.parse(this.token.get('eventData'));
                var title = this.token.get('length_of_booking');

                 var id = this.getUrlParameter('id');

                    if( id != '' ){
                        setTimeout(function(){
                            // let elem = document.getElementById( 'order-'+id );
                            // if( elem != null ){
                                var offSet = 20;
                                var obj = $('#order-' + id);
                                if(obj.length){
                                  var offs = obj.offset();
                                  var targetOffset = offs.top - offSet;
                                  $('html,body').animate({ scrollTop: targetOffset }, 500);
                                }
                            //}
                        }, 500 );
                    }

			},
			error =>{

			}
		);
	}

    getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };

    moreItems( order, item, event ){
        let event_view = $(event).data('action');
        let class_name = order+'_'+item+'_'+'second';
        if( event_view == 'plus' ){
            $( '.'+class_name ).show();
            $( event ).data( 'action', 'minus' );
            $( event ).html( 'Afficher Moins<label><</label>' );
            
            $( event ).parent().removeClass('arrow-down');
            $( event ).parent().addClass('arrow-up');
        } else {
            $( '.'+class_name ).hide();
            $( event ).data( 'action', 'plus' );
            $( event ).html( 'Afficher Plus<label>></label>' );

            $( event ).parent().removeClass('arrow-up');
            $( event ).parent().addClass('arrow-down');
        }
    }

    // orderCanEdit( orderId, orderDate, order_payment_type ){
        
    //     let order_date = moment( orderDate );
    //     let current_date = moment();

    //     let duration = moment.duration( current_date.diff( order_date ) );
    //     let hours = Math.round( duration.asHours() * 100 ) / 100;
    //     if( hours < 48 && order_payment_type == "offline" ){
    //         return true;
    //     }

    //     return false;
    // }

    editOrder( orderId ){
        var self = this;

        this.token.remove('fetchEvent');
        this.token.remove('couponDiscount');
        this.token.remove('totalPrice');
        this.token.remove('eventData');
        this.token.remove('selected_events');
        this.token.remove('totalSubmitPrice');
        this.token.remove('nomade_service');
        this.token.remove('menu');
        this.token.remove('menuPrice');
        this.token.remove('percentage');
        this.token.remove('couponName');
        this.token.remove('couponId');
        this.token.remove('taxAmount');
        this.token.remove('price');
        this.token.remove('walletUpdatePrice');

        
        this.token.set('editOrder', 1);
        this.token.set('editOrderId', orderId);
        this.orderId['order_id'] = orderId;
        this.userservice.getEditOrder(this.orderId).subscribe(
            data => {
                if(data['status'] == 'true'){
                    //console.log(data['events']);
                    let item_price = 0;
                    for (let i = 0; i < data['events'].length; i++) {
                        
                        var title = '';
                        
                        if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 1){
                            title = '30 Jours'
                        }else if(data['events'][i].order_type == 2 && data['events'][i].length_of_booking == 2){
                            title = 'Half Day';
                        }else if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 2 || data['events'][i].order_type == 2 && data['events'][i].length_of_booking == 3){
                            title = 'La journée';
                        }else if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 3){
                            title = 'Le mois';
                        }else if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 4){
                            title = '10 Jours';
                        }else if(data['events'][i].order_type == 2 && data['events'][i].length_of_booking == 1){
                            title = 'Hourly';
                        }

                        //if( data['events'][i].start_date != null && data['events'][i].end_date != null ){
                        if( typeof data['events'][i].appointment !== 'undefined' && data['events'][i].appointment.length > 0 ){

                            var title = '';
                            var service_cost = 0;
                            if( data['events'][i].length_of_booking == 2 ){
                                title = 'journée';
                                service_cost = 20;
                                service_cost = service_cost * data['events'][i].appointment.length;
                                item_price = item_price + service_cost;
                            }
                            
                            if( data['events'][i].length_of_booking == 3 ){
                                title = 'Mois';
                                service_cost = 140;
                                item_price = item_price + service_cost;
                            }

                            if( data['events'][i].length_of_booking == 4 ){
                                title = 'Dix jours';
                                service_cost = 90;
                                item_price = item_price + service_cost;
                            }

                            if( data['events'][i].length_of_booking == 1 ){
                                title = '30 Jours';
                                service_cost = 550;
                                item_price = item_price + service_cost;
                            }

                            self.token.set('event_type', title);

                            
                            this.token.set('service_cost', service_cost);
                            //console.log(data['events'][i].appointment.length);
                            if( data['events'][i].appointment.length > 0 ){
                                for (let j = 0; j < data['events'][i].appointment.length; j++) {
                                    
                                    self.appointments.push({
                                        start: data['events'][i].appointment[j].start_date,
                                        end : data['events'][i].appointment[j].end_date,
                                        event_type : title,
                                        id : data['events'][i].appointment[j].id
                                    })
                                    self.token.set('eventData', JSON.stringify(self.appointments));

                                }
                                //console.log(data['events'][i]);
                                if( data['events'][i].order_service.length > 0 ){
                                    //console.log(data['events'][i].order_service.length);
                                    self.token.set( 'nomade_service', JSON.stringify( data['events'][i].order_service ) );
                                }
                            }

                            /*if( data['events'][i].order_service.length > 0 ){

                                var getSerivceLocal = [];

                                for (let j = 0; j < data['events'][i].order_service.length; j++) {
                                    
                                    getSerivceLocal.push({
                                        id: data['events'][i].order_service[j].id,
                                        image : '',
                                        name : '',
                                        label : '',
                                        description : '',
                                        one_time_price : '',
                                        type_of_invoice : '',
                                        recurring_price : '',
                                        recurring_months : '',
                                        status : '',
                                        created_at : data['events'][i].order_service[j].created_at,
                                        updated_at : data['events'][i].order_service[j].updated_at,
                                    })
                                    self.token.set('nomade_service', JSON.stringify( getSerivceLocal ));

                                }

                            }*/
                        }

                        if(data['events'][i].event_start_date != null && data['events'][i].event_end_date != null){
                            var Fetchedevent = new Object();
                            Fetchedevent = {
                                title: title,
                                start: data['events'][i].event_start_date,
                                end: data['events'][i].event_end_date,
                                allDay: false,
                                editable:true,
                                color: 'yellow',
                                className: 'fetchEvent',
                                order_type: data['events'][i].order_type,
                                length_of_booking: data['events'][i].length_of_booking,
                                time_of_day: data['events'][i].time_of_day,
                                id : data['events'][i].id
                            };
                            item_price = item_price + data['events'][i].price;
                            this.eventArray.push( Fetchedevent );
                        }
                    }

                    if( data['order'].length > 0 ){
                        for (let i = 0; i < data['order'].length; i++) {
                            if( typeof data['order'][i].coupon !== 'undefined' ){
                                let editOrderCoupon = data['order'][i].coupon;
                                //console.log( editOrderCoupon );
                                //we found coupon applied in order so we set in edit order
                                this.token.set( 'amountType', editOrderCoupon['amount_type'] );
                                this.token.set( 'couponName', editOrderCoupon['name'] );
                                this.token.set( 'couponId', editOrderCoupon['id'] );
                                this.token.set( 'percentage', editOrderCoupon['percentage'] );
                                this.token.set( 'couponDiscount', data['order'][i].coupon_amount );
                                this.token.set( 'items_can_edit', data['order'][i].items_can_edit );
                                this.token.set( 'services_can_edit', data['order'][i].services_can_edit );
                            }

                            if( typeof data['order'][i].wallet_amount !== 'undefined' && data['order'][i].wallet_amount != '' ){
                                this.token.set( 'price', item_price );
                                this.token.set( 'walletDiscount', data['order'][i].wallet_amount );
                            }
                        }
                    }

                }
                
                self.store.RemoveEventSelection();
                var allEvents = JSON.parse(self.store.GetEventSelection()) || [];
                
                var allEvents = allEvents.filter(function (el) {
                  return el != null;
                });

                if (data['events'] !== undefined && Object.keys(data['events']).length > 0) {
                    
                    for (let i = 0; i < data['events'].length; i++) {
                        if( allEvents != null && !self.is_item_key_exist( data['events'][i].item_key ,allEvents )){
                            
                            // if( data['events'][i].order_fooding_menus == undefined ){
                            //     console.log('if');
                            // }else{
                            //     console.log('else');
                            //     console.log(data['events'][i].order_fooding_menus[0].id);

                            // }

                            if( data['events'][i].order_fooding_menus != undefined && Object.keys( data['events'][i].order_fooding_menus).length > 0 ){

                                var order_fooding_menus = [];
                                var MenuPrice = 0;
                                //var totalPrice = 0;
                                // var price = 0;

                                for (let j = 0; j < Object.keys( data['events'][i].order_fooding_menus).length; j++) {
                                    
                                    order_fooding_menus.push({
                                        id: data['events'][i].order_fooding_menus[j].id,
                                        contractor_id: data['events'][i].order_fooding_menus[j].contractor_id,
                                        menu_name: data['events'][i].order_fooding_menus[j].menu_name ,
                                        image: data['events'][i].order_fooding_menus[j].image ,
                                        description: data['events'][i].order_fooding_menus[j].description,
                                        price: data['events'][i].order_fooding_menus[j].price,
                                        created_at: data['events'][i].order_fooding_menus[j].created_at,
                                        updated_at: data['events'][i].order_fooding_menus[j].updated_at,
                                        qty: data['events'][i].order_fooding_menus[j].qty,
                                        category_id: data['events'][i].order_fooding_menus[j].category_id,
                                        category_name: data['events'][i].order_fooding_menus[j].category_name,
                                        menu_item_id: data['events'][i].order_fooding_menus[j].menu_item_id,
                                        order_fooding_category_id: data['events'][i].order_fooding_menus[j].order_fooding_category_id
                                    });
                                    
                                    MenuPrice = Number( MenuPrice ) + Number( data['events'][i].order_fooding_menus[j].price ) * Number( data['events'][i].order_fooding_menus[j].qty );
                                    //totalPrice = Number( totalPrice ) + Number( data['events'][i].order_fooding_menus[j].price ) * Number( data['events'][i].order_fooding_menus[j].qty );
                                }

                                self.store.setMenu( order_fooding_menus );
                                self.token.set('menuPrice', MenuPrice);
                                

                                data['events'][i].menu = order_fooding_menus;
                                //console.log(data['events'][i]);
                            }

                            allEvents.push(data['events'][i]);
                        }
                    }

                    var totalLength = data['events'].length + allEvents.length;
                    var k = 0;
                    
                    for (let i = data['events'].length; i < totalLength; i++) {
                        if( allEvents != null && !self.is_item_key_exist( allEvents[k].item_key ,allEvents )){
                            allEvents.push(allEvents[k]);
                            k++;
                        }
                    }
                    
                    this.store.SaveAllEventSelection( allEvents );

                }
                 
                //console.log(data['order'][0].total); 
                self.token.set('totalPrice', data['order'][0].total);
                self.token.set('totalSubmitPrice', data['order'][0].total);
                self.token.set('taxAmount', data['order'][0].taxAmount);
                self.token.set( 'service', 1 );
                self.token.set( 'edit_order_created_at', data['order'][0].created_at );
                //console.log(allEvents);

                this.token.set('fetchEvent', JSON.stringify(this.eventArray));

                var event_date = JSON.parse(this.token.get('eventData'));
                var title = this.token.get('event_type');
                if(event_date){
                    for( let z=0; z < event_date.length; z++ ){
                        this.isSelectedEvent.push({
                            title: title,
                            start: event_date[z].start,
                            end: event_date[z].end,
                            editable: false,
                            allDay: true,
                            color: '#90EE90'
                        });
                    }
                }

                this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['chariot']);
                }); 
            },
            error => {
            }
        );

    }

	viewConfirmModal(orderId,orderDate,order_payment_type){
        // console.log( payment_status ); 
        // if(payment_status == 4){
        //     console.log('if');
        // }

        // if(payment_status == '4'){
        //     console.log('if');
        // }

        // return;
        // var diff_ms = moment(orderDate).diff(moment());
        // var dur_obj = moment.duration(diff_ms);

        // var a = moment(orderDate);//now
        // var b = moment();

        // console.log(a.diff(b, 'minutes')) // 44700
        // console.log(a.diff(b, 'hours')) // 745
        // console.log(a.diff(b, 'days')) // 31
        // console.log(a.diff(b, 'weeks')) // 4

        // console.log(hours);
        // console.log(dur_obj.asHours());

        // if (order_date < current_date) {
        //     console.log('date is past');
        // } else {
        //     console.log('date is future');
        // }

        this.currentOrderId = orderId;
        this.currentOrderDate = orderDate;
        this.currentOrderPaymentStatus = order_payment_type;


		this.orderId['order_id'] = orderId;
		jQuery('#confirmRefundOrder').modal();
	}

    refundOrderConfirm(){

        jQuery('#confirmRefundOrder').modal('hide');

        var order_date = moment(this.currentOrderDate);
        var current_date = moment();

        var duration = moment.duration(current_date.diff(order_date));
        var hours = Math.round(duration.asHours() * 100) / 100;

        if( hours < 48 && this.currentOrderPaymentStatus == "offline" ){
            jQuery('#paymentOfflineBefore48').modal();
        }else if( hours > 48 && this.currentOrderPaymentStatus == "offline" ){
            jQuery('#paymentOfflineAfter8').modal();
        }else if( hours < 48 && this.currentOrderPaymentStatus == "online" ){
            jQuery('#paymentOnlineBefore48').modal();
        }else{
            jQuery('#paymentOnlineAfter48').modal();
        }
    }

	refundOrder(){

        this.refundOrderData['order_id'] = this.currentOrderId;

        var order_date = moment(this.currentOrderDate);
        var current_date = moment();

        var duration = moment.duration(current_date.diff(order_date));
        var hours = Math.round(duration.asHours() * 100) / 100;

        if( hours < 48 && this.currentOrderPaymentStatus == "offline" ){
            this.refundOrderData['payment_status'] = 2;
            this.refundOrderData['order_payment_type'] = "offline";
        }else if( hours > 48 && this.currentOrderPaymentStatus == "offline" ){
            this.refundOrderData['payment_status'] = 5;
            this.refundOrderData['order_payment_type'] = "offline";
        }else if( hours < 48 && this.currentOrderPaymentStatus == "online" ){
            this.refundOrderData['payment_status'] = 2;
            this.refundOrderData['order_payment_type'] = "online";
        }else{
            this.refundOrderData['payment_status'] = 5;
            this.refundOrderData['order_payment_type'] = "online";
        }


		// if(yes == 'yes'){

        //return;
			this.userservice.refundOrder(this.refundOrderData).subscribe(
				data => {
					if(data['status'] == 'true'){
						jQuery('.refund-popup').modal('hide');
						this.myOrder();
						this.toastr.success('Remboursement de votre commande.');
					}

					if(data['status'] == 'false'){
						this.toastr.error(data['message']);
					}
				},
				error =>{
                    this.toastr.error(error.error.error);
				}
			);
		// }
	}

	selectRemainDays( order_id ){
		//this.eventData = this.myOrders;
		//console.log( this.myOrders );
		if( this.myOrders.length > 0 ){
			for (var i = 0; i < this.myOrders.length; i++ ) {
				if( this.myOrders[i].id == order_id ){
					this.selectedOrderId = order_id;
					this.selectedOrderBookedDay = this.myOrders[i].appointment.length;
					this.eventData = this.myOrders[i].appointment;
					this.token.set( 'length_of_booking', this.myOrders[i].length_of_booking );
					var remain_booking = 0;
					if( this.myOrders[i].length_of_booking == '1' ){
						this.selectedOrderServiceDay = 30;
						remain_booking = Number( 30 - this.eventData.length );
					}

					if( this.myOrders[i].length_of_booking == '4' ){
						this.selectedOrderServiceDay = 10;
						remain_booking = Number( 10 - this.eventData.length );
					}
					this.token.set( 'remain_booking', remain_booking );
				}
			}
		}
		//console.log( this.eventData );
		jQuery('#calendar-modal-view-event').modal();
	}

	dateIsSelectable( date, service ){
		if( this.eventData.length > 0 ){
			let selectedDates = this.eventData;
			selectedDates = selectedDates.sort( this.sortEventData );
			let eventStartDate = new Date( selectedDates[0]['start_date'] );
        	let eventOfferEndDate = new Date( selectedDates[0]['start_date'] );
        	let offerExpirePeriod = ( service == '4' ) ? 2 : 6 ;
        	eventOfferEndDate.setMonth( eventOfferEndDate.getMonth() + offerExpirePeriod );
        	let currentDate = new Date( date );
        	if( currentDate.getTime() <= eventOfferEndDate.getTime() && currentDate.getTime() >= eventStartDate.getTime() ) {
        		return true
        	}
		}

		return false;
	}

	sortEventData( a, b ){
        var dateA = new Date(a.start_date).getTime();
        var dateB = new Date(b.start_date).getTime();
        return dateA > dateB ? 1 : -1;
    }

	checkSelectDayIsAvailable( date ){
        var self = this;
        if(self.token.get('getClosingDates')){
            var getClosingDates = JSON.parse(self.token.get('getClosingDates'));
            if( typeof getClosingDates !== 'undefined' ){
                if( typeof getClosingDates.holidays !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 && self.checkDateInHolidays(date) ){
                    if( !self.checkMorningAvailable(date) || !self.checkAfternoonAvailable(date) ){
                        this.unavailable_class = 'holiday';
                        return false;
                    }
                }else{
                    if( !self.checkDateInWeekdays(date) ){
                        this.unavailable_class = 'unavailable';
                        return false;
                    }
                }
            }
        }
        this.unavailable_class = 'unavailable';
        return true;
    }

    checkDayIsAvailableInBooked( date ){
        var fetchEvent = JSON.parse(this.token.get('fetchEvent'));            
        if( typeof fetchEvent !== 'undefined' && fetchEvent.length > 0 ){
            for (var i = fetchEvent.length - 1; i >= 0; --i) {
                var startDate = fetchEvent[i].start.split(" ");
                if ( startDate[0] == date ) {
                    //console.log(startDate[0] +'=='+ date);
                    return false;
                }
            }
        }
        return true;
    }

    unavailableDayHtml( date ){
        var unavailableMsg = this.unavailable_label;
        if(this.getUnavailableMsg(date)){
            unavailableMsg = this.getUnavailableMsg(date);
            //return '<div class="unavailable" data-tooltip="'+unavailableFullMsg+'"><span class="complete">'+unavailableMsg+'</span></div>';
        }
        return '<div class="unavailable"><span class="complete">'+unavailableMsg+'</span></div>';
    }

    getUnavailableFullMsg( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));   
        if( typeof getClosingDates !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 ){
            for (var i = getClosingDates.holidays.length - 1; i >= 0; --i) {
                if ( getClosingDates.holidays[i].date == date) {
                    if( typeof getClosingDates.holidays[i].purpose !== 'undefined' && getClosingDates.holidays[i].purpose != '' ){
                        let holiday_purpose = getClosingDates.holidays[i].purpose.split(" ");
                        let short_purpose_text = '';
                        if( holiday_purpose.length > 1 ){
                            for ( var k = 0; k <= 1; k++ ) {
                                short_purpose_text += holiday_purpose[k].charAt(0);
                            }
                        } else{
                            short_purpose_text = holiday_purpose.charAt(0);
                        }
                        return getClosingDates.holidays[i].purpose;
                    }
                }
            }
        }
        return false;
    }

    getUnavailableMsg( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));   
        if( typeof getClosingDates !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 ){
            for (var i = getClosingDates.holidays.length - 1; i >= 0; --i) {
                if ( getClosingDates.holidays[i].date == date) {
                    if( typeof getClosingDates.holidays[i].purpose !== 'undefined' && getClosingDates.holidays[i].purpose != '' ){
                        let holiday_purpose = getClosingDates.holidays[i].purpose.split(" ");
                        let short_purpose_text = '';
                        if( holiday_purpose.length > 1 ){
                            for ( var k = 0; k <= 1; k++ ) {
                                short_purpose_text += holiday_purpose[k].charAt(0);
                            }
                        } else{
                            short_purpose_text = holiday_purpose.charAt(0);
                        }
                        //console.log( short_purpose_text );
                        //return getClosingDates.holidays[i].purpose;
                        return short_purpose_text;
                    }
                }
            }
        }
        return false;
    }

    checkDateInHolidays( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));
        if( typeof getClosingDates !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 && this.in_array_r( date, getClosingDates.holidays )){
            return true;
        }
        return false;
    }

    checkMorningAvailable( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));            
        if( typeof getClosingDates !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 ){
            for (var i = getClosingDates.holidays.length - 1; i >= 0; --i) {
                if ( getClosingDates.holidays[i].date == date && getClosingDates.holidays[i].morning == '1' ) {
                    return true;
                }
            }
        }
        return false;
    }

    checkAfternoonAvailable( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));
        if( typeof getClosingDates !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 ){
            for (var i = getClosingDates.holidays.length - 1; i >= 0; --i) {
                if ( getClosingDates.holidays[i].date == date && getClosingDates.holidays[i].afternoon == '1' ) {
                    return true;
                }
            }
        }
        return false;
    }

    checkDateInWeekdays( date ){
        
        var self = this;

        if(self.token.get('getClosingDates')){

            var getClosingDates = JSON.parse(self.token.get('getClosingDates'));
            
            if( typeof getClosingDates !== 'undefined' ){

                if( typeof getClosingDates.week_days !== 'undefined' && getClosingDates.week_days.length > 0 ){

                    var currentDate = moment(date);

                    if( currentDate.isoWeekday() == 1 ){
                        
                        if( getClosingDates.week_days[0].monday_morning == '0' || getClosingDates.week_days[0].monday_afternoon == '0' ){
                            return false;
                        }
                        
                    }else if( currentDate.isoWeekday() == 2 ){

                        if( getClosingDates.week_days[0].tuesday_morning == '0' || getClosingDates.week_days[0].tuesday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 3 ){
                        
                        if( getClosingDates.week_days[0].wednesday_morning == '0' || getClosingDates.week_days[0].wednesday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 4 ){
                        
                        if( getClosingDates.week_days[0].thursday_morning == '0' || getClosingDates.week_days[0].thursday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 5 ){
                        
                        if( getClosingDates.week_days[0].friday_morning == '0' || getClosingDates.week_days[0].friday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 6 ){

                        if( getClosingDates.week_days[0].saturday_morning == '0' || getClosingDates.week_days[0].saturday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 7 ){
                        
                        if( getClosingDates.week_days[0].sunday_morning == '0' || getClosingDates.week_days[0].sunday_afternoon == '0' ){
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    saveRemainDays(){
    	var saving_data = {};
    	var selected_dates = this.token.get( 'eventData' ) || [];
    	if( this.selectedOrderId != '' && selected_dates.length > 0 ){
    		saving_data['order_id'] = this.selectedOrderId;
    		saving_data['dates'] = selected_dates;
    		console.log( 'ready to save.' );
    		this.userservice.saveRemainDays( saving_data ).subscribe(
	    		data => {
		            if(data['status'] == 'false'){
	                	this.toastr.error(data['message']);
		            }
		            if( data['status'] == 'true' ){
		            	jQuery('#calendar-modal-view-event').modal('hide');
						this.toastr.success( data['message'] );
						this.myOrder();	
						this.getNomadeOrderImage();
						this.getMeetingRoomOrderImage();
		            }
	  			},
	            error => {
	                this.toastr.error(error.error.error);
	            }
	  		);
    	} else {
    		jQuery('#noSelectedEvent').modal();
            return;
    	}
    }


    openFoodMenu(order_number,order_id,meeting_room_id){
        var myid="mymodal"+order_number+order_id+meeting_room_id;
        jQuery('#'+myid).modal();
    }

    in_array_r(needle, haystack) {
        var length = haystack.length;
        for(var key in haystack) {
            if(haystack[key] == needle){
                return true;
            }
            if(typeof haystack[key]=='object'){
                if(this.in_array_r(needle, haystack[key])){
                    return true;
                }
            } 
        }
        return false;
    }

    is_item_key_exist(needle, haystack) {
        //console.log(haystack.length);
        var length = haystack.length;
        if( length > 0 ){
            for(var key in haystack) {
                if(haystack[key]['item_key'] == needle){
                    return true;
                }
                if(typeof haystack[key]=='object'){
                    if(this.is_item_key_exist(needle, haystack[key])){
                        return true;
                    }
                } 
            }
        }
        return false;
    }

    addThirtyDayEvent(){

        var event_date = JSON.parse(this.token.get('eventData')) || [];
        var title = this.token.get('length_of_booking');

        if(event_date.length <= 30){
            jQuery('#calendar').fullCalendar('removeEvents')
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: this.full_day_label,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90',
                    className: 'full_day_section'
                });
            }
        }else{
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: this.full_day_label,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90',
                    className: 'full_day_section'
                });
            }
        }

        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    addTenDayEvent(){

        jQuery('#calendar').fullCalendar('removeEvents')
        var event_date = JSON.parse(this.token.get('eventData')) || [];
        var title = this.token.get('length_of_booking');

        if(event_date.length <= 10){
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: this.full_day_label,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90',
                    className: 'full_day_section'
                });
            }
        }else{
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: this.full_day_label,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90',
                    className: 'full_day_section'
                });
            }
        }

        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

	createPDF(order_id){
		this.orderId['orderId'] = order_id;
		this.userservice.genratePDF(this.orderId).subscribe(
  			data => {
	            if(data['status'] == 'false'){
                	this.toastr.error(data['message']);
	            }
	            if(data['status'] == 'true'){
					this.pdfURL = data['url'];
					window.open(this.pdfURL, '_blank');
	            }
  			},
            error => {
                this.toastr.error(error.error.error);
            }
  		);
	}

	getNomadeOrderImage(){
		this.userservice.getNomadeOrderImage().subscribe(
			data => {
				if(data['status'] == 'true'){
					this.NomadeOrderImageURL = data['imageURL'];
					this.NomadeOrderImageDetail = data['imageDetail'];
				}

				if(data['status'] == 'false'){
					this.toastr.success('Oups! Quelque chose a mal tourné.');
				}
			},
			error =>{

			}
		);
	}

	getMeetingRoomOrderImage(){
		this.userservice.getMeetingRoomOrderImage().subscribe(
			data => {
				if(data['status'] == 'true'){
					this.MeetingRoomOrderImageURL = data['imageURL'];
					this.MeetingRoomOrderImageDetail = data['imageDetail'];
				}

				if(data['status'] == 'false'){
					this.toastr.success('Oups! Quelque chose a mal tourné.');
				}
			},
			error =>{

			}
		);
	}

	eventNextPrev(){
        this.nextPrevArray = [];
        jQuery('#calendar').fullCalendar('removeEvents')
        var event_date = JSON.parse(this.token.get('eventData')) || [];
        var title = this.token.get('length_of_booking');
        if(event_date){
            for( let k=0; k < event_date.length; k++ ){
                this.nextPrevArray.push({
                    title: this.full_day_label,
                    start: event_date[k].start,
                    end: event_date[k].end,
                    editable:false,
                    allDay: true,
                    color: '#90EE90',
                    className: 'full_day_section'
                });
            }
            jQuery('#calendar').fullCalendar('addEventSource', this.nextPrevArray);
        }

        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    checkIteamLalready(meeting_room_id){
        //console.log(meeting_room_id);
        
        if(!this.in_array_r( meeting_room_id, this.meetingRoomIds )){
            this.meetingRoomIds.push(meeting_room_id);
            console.log(this.meetingRoomIds);
            return true;
        }
        return false;

    }
}
