import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { RegisterComponent } from './auth/register/register.component';
import { HomeComponent } from './page/home/home.component';
import { ThirdServiceComponent } from './page/third-service/third-service.component';
import { FourServiceComponent } from './page/four-service/four-service.component';
import { InvoicesAndCreditComponent } from './page/invoices-and-credit/invoices-and-credit.component';
import { MyAccountComponent } from './page/my-account/my-account.component';
import { MyAccountDocuments } from './page/my-document/my-document.component';
import { PersonalInfoComponent } from './page/personal-info/personal-info.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CalendarComponent } from './page/calendar/calendar.component';
import { ConnectionAndSecurityComponent } from './page/connection-and-security/connection-and-security.component';
import { ThanksComponent } from './page/thanks/thanks.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { SpaceComponent } from './page/space/space.component';
import { BackNextComponent } from './page/back-next/back-next.component';
import { CartComponent } from './page/cart/cart.component';
import { OrderSummaryComponent } from './page/order-summary/order-summary.component';
import { OrderComponent } from './page/order/order.component';
import { RecurringOrderComponent } from './page/recurring-order/recurring-order.component';
import { PaymentComponent } from './page/payment/payment.component';
import { MeetingRoomComponent } from './page/meeting-room/meeting-room.component';
import { CategoryComponent } from './page/category/category.component';
import { ServiceComponent } from './page/nomade-service/nomade-service.component';
import { CookieService } from 'ngx-cookie-service';
import { PaymentDetailComponent } from './page/payment-detail/payment-detail.component';
import { StepComponent } from './page/step/step.component';
import { WorkinProgress } from './page/workin-progress/workin-progress.component';
import { ContacterComponent } from './page/contact/contact.component';
import { DomiciliationComponent } from './page/domiciliation/domiciliation.component';
import { ThankYouComponent } from './page/thank-you/thank-you.component';

registerLocaleData(localeFr, 'fr-FR');

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // {disableTimeOut: true}
    NgbModule,
    FullCalendarModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    RegisterComponent,
    HomeComponent,
    ThirdServiceComponent,
    FourServiceComponent,
    InvoicesAndCreditComponent,
    MyAccountComponent,
    MyAccountDocuments,
    PersonalInfoComponent,
    SidebarComponent,
    CalendarComponent,
    ConnectionAndSecurityComponent,
    ThanksComponent,
    SpaceComponent,
    BackNextComponent,
    CartComponent,
    OrderSummaryComponent,
    OrderComponent,
    RecurringOrderComponent,
    PaymentComponent,
    MeetingRoomComponent,
    CategoryComponent,
    ServiceComponent,
    PaymentDetailComponent,
    StepComponent,
    WorkinProgress,
    ContacterComponent,
    DomiciliationComponent,
    ThankYouComponent
  ],
  providers: [DatePipe, CookieService, { provide: LOCALE_ID, useValue: "fr-FR" }],
  bootstrap: [AppComponent]
})
export class AppModule { }
