import { Component, OnInit, Output, EventEmitter  } from '@angular/core';

import { AuthenticationService } from '../../service/authentication.service';
import { TokenService } from '../../service/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
// import { UserService } from '../../service/user.service';
import * as $ from 'jquery';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {  	

	public loginForm: FormGroup;
    step:any;
    hasEmail:any;
    // public spaceData: any;
	public form={
        email: null,
        password: null
    }
    constructor(
        private router: Router,
        public auth: AuthenticationService,
        private token: TokenService,
        private toastr: ToastrService,
        private cookie: CookieService,
        private location: Location,
        // private userService: UserService
    ) { 

        // this.userService.loadScript();

        var url = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);
        $(document).ready(function() {
            $('body').removeClass();
            $('body').addClass(url);
        });

        if (this.auth.isLoggedIn()) {
            this.back(); //this.router.navigate(['/accueil']);
        }

    }

    ngOnInit() {
    }

    onSubmit(){
        // this.form.spaceData = this.cookie.getAll();
        this.auth.login(this.form).subscribe(
            data => {
                if(data['status'] == 'true'){
                    this.token.set('token', data['token']);
                    this.token.set('currentUser', JSON.stringify(data['user']));
                    this.auth.loginEvent.emit(data['user'])

                    var userId =  this.token.getUser();
                    this.token.set('userId', userId);

                    var loginClick = this.token.get('loginClick');

                    if(loginClick == '1'){
                        this.back();
                        this.token.remove('loginClick');
                    }else{
                        if(this.token.get('service') == '1' && this.token.get('event_type')){
                            this.toastr.success("Utilisateur enregistré avec succès.");
                            this.router.navigate(['/récapitulatif-de-la-commande']);
                            //this.back();
                        }else if( this.token.get('service') == '2' && this.token.get('selected_events').length > 0 ){
                            this.toastr.success("Utilisateur enregistré avec succès.");
                            this.router.navigate(['/récapitulatif-de-la-commande']);
                            //this.back();
                        }else{
                            this.router.navigate(['/accueil']);
                        }
                    }    

                }else{
                    this.toastr.error('Quelque chose ne va pas, service réessayer.');
                }
            },
            error => {
                this.toastr.error(error.error.error);
            }
        );
    }

    back(){
        this.step = this.token.get('step');
        this.token.set('step', this.step - 1);
        this.location.back();
    }

}
