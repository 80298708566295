import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class SpaceService {

	baseUrl: any;

	constructor(
		private http:HttpClient,
	) { 
		this.baseUrl = environment.APP_URL;
	}

	fetchEvent(orderType){
  		return this.http.post(`${this.baseUrl}/auth/fetchEvent`, orderType);
  	}

  	allService(){
		return this.http.get(`${this.baseUrl}/auth/service`);
	}

  	getClosingDates(){
  		return this.http.post(`${this.baseUrl}/auth/getClosingDates`,'');
  	}

  	sendRecurringReminder(){
  		return this.http.post( `${this.baseUrl}/auth/sendRecurringReminder`,'' );
  	}
}
