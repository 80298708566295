import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

	constructor() { }
	
	set(key, value){
		localStorage.setItem(key, value);
		// const isValid = this.isValidAccessToken();
	}

	get(key){
		return localStorage.getItem(key);
	}

	remove(key){
		localStorage.removeItem(key);	
	}

	getUser(){
		const token = this.get('token');
		if(token != '' && token != null){
			const payload = this.payload(token);
			return payload.sub;
		}else{
			return false;
		}
	}

	isValidAccessToken(){
		const token = this.get('token');
		if(token != ''){
  			const payload = this.payload(token);
			if(payload){
				if(true){
					return true;
				}
			}
		}
		else{
			return false;
		}
	}

	payload(token){
		const payload = token.split('.')[1];
		return this.decode(payload);
	}

	decode(payload){
		return JSON.parse(atob(payload));
	}	

	userDetail(currentUser){
		const user = this.get(currentUser);
		return JSON.parse(user);
	}
}
