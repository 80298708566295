import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})

export class StoreService {

	set(key, value){
		localStorage.setItem(key, value);
		// const isValid = this.isValidAccessToken();
	}

	get(key){
		return localStorage.getItem(key);
	}

	remove(key){
		localStorage.removeItem(key);	
	}
	
	setSelectedService( selectedService ){
		localStorage.setItem('service', selectedService)
	}

	setServiceType(type){
		localStorage.setItem('service_type', type)
	}	

	setServiceCost(cost){
		localStorage.setItem('service_cost', cost)
	}

	setRoomSize(size){
		localStorage.setItem('room_size', size)
	}

	setHourPrice(hour){
		localStorage.setItem('hour_price', hour)
	}

	setHalfDayPrice(half){
		localStorage.setItem('half_price', half)
	}

	setDayPrice(full){
		localStorage.setItem('full_price', full)
	}

	setMenu(menu){
		localStorage.setItem('menu', JSON.stringify(menu))
	}	

	setEventType(type){
		localStorage.setItem('event_type', type)
	}

	setContract(name){
		localStorage.setItem('contract', name)
	}

	setRoomType( roomId ){
		localStorage.setItem('room_type', roomId)
	}

	setCurrentEventKey( key ){
		localStorage.setItem('event_key', key);
	}

	setCurrentItemKey( key ){
		localStorage.setItem('item_key', key);
	}

	SaveEventSelectionTemp( selected_event ){
		//console.log('SaveEventSelectionTemp');
        var a = [];
	    // Parse the serialized data back into an aray of objects
	    a = JSON.parse(localStorage.getItem('selected_events_temp')) || [];
	    // Push the new data (whether it be an object or anything else) onto the array
	    a.push(selected_event);
	    // Re-serialize the array back into a string and store it in localStorage
	    // console.log(a);
	    // console.log(JSON.stringify(a));
	    localStorage.setItem('selected_events_temp', JSON.stringify(a));
	}

	SaveAllTempEventSelection( selected_event ){
        // Re-serialize the array back into a string and store it in localStorage
        localStorage.setItem('selected_events_temp', JSON.stringify(selected_event));
	}

	RemoveTempEventSelection(){
		return localStorage.removeItem('selected_events_temp');
	}

	GetEventSelectionTemp(){
		return JSON.parse(localStorage.getItem('selected_events_temp') || '[]');
	}

	SaveEventSelection( get_selected_event, selected_event ){
		var a = [];
        // Parse the serialized data back into an aray of objects
        a = get_selected_event || [];
        // Push the new data (whether it be an object or anything else) onto the array
        //selected_event = JSON.parse(selected_event);
        for (let i = 0; i < selected_event.length; i++) {
            if( selected_event != null ){
            	a.push(selected_event[i]);
            }
        }
        // Re-serialize the array back into a string and store it in localStorage
        localStorage.setItem('selected_events', JSON.stringify(a));
	}

	SaveAllEventSelection( selected_event ){
        // Re-serialize the array back into a string and store it in localStorage
        localStorage.setItem('selected_events', JSON.stringify(selected_event));
	}

	GetEventSelection(){
		return localStorage.getItem('selected_events')
	}

	RemoveEventSelection(){
		return localStorage.removeItem('selected_events');
	}

	getServiceType(){
		return localStorage.getItem('service_type')
	}

	getRoomSize(){
		return localStorage.getItem('room_size')
	}
	getHourPrice(){
		return localStorage.getItem('hour_price')
	}
	getHalfDayPrice(){
		return localStorage.getItem('half_price')
	}
	getDayPrice(){
		return localStorage.getItem('full_price')
	}

	getSelectedService(){
		return localStorage.getItem('service')
	}

	getEventType(){
		return localStorage.getItem('event_type')
	}

	getService(){
		return JSON.parse(localStorage.getItem('nomade_service') || '[]')
	}

	getMenu(){
		return JSON.parse(localStorage.getItem('menu') || '[]')
	}

	getContract(){
		return localStorage.getItem('contract')
	}

	getRoomType(){
		return localStorage.getItem('room_type')
	}

	getCurrentEventKey(){
		return localStorage.getItem('event_key');
	}

	getCurrentItemKey(){
		return localStorage.getItem('item_key');
	}

	getAllEventSelection(){
        return localStorage.getItem('selected_events');
	}
}
