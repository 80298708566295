// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,

	
	// Local
	//APP_URL: 'http://localhost/dem-cowork/backend/index.php/api',
	//IMG_URL: 'http://localhost/dem-cowork/backend/public/image',
	//STORAGE_IMG_URL: 'http://localhost/dem-cowork/backend/storage'
	
	//preprod
	// APP_URL: 'https://preprod.admin.coworkoffice.fr/index.php/api',
	// IMG_URL: 'https://preprod.admin.coworkoffice.fr/image',
	// STORAGE_IMG_URL: 'https://preprod.admin.coworkoffice.fr/storage'
	
	//demo
	APP_URL: 'https://coworkadm.10a.consulting/api',
	IMG_URL: 'https://coworkadm.10a.consulting/image',
	STORAGE_IMG_URL: 'https://coworkadm.10a.consulting/storage'
	
	// Live
	// APP_URL: 'https://admin.coworkoffice.fr/index.php/api',
	// IMG_URL: 'https://admin.coworkoffice.fr/image',
	// STORAGE_IMG_URL: 'https://admin.coworkoffice.fr/storage'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
 // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
