import { Component, OnInit, NgZone, AfterViewInit, ElementRef, ViewChild } from '@angular/core';

import { SpaceService } from '../../service/space.service';
import { TokenService } from '../../service/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StoreService } from '../../service/store.service';
import { MeetingRoomService } from '../../service/meeting-room.service';
import { environment } from '../../../environments/environment';
import { DatePipe } from '@angular/common';
import { UserService } from '../../service/user.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import * as $ from 'jquery';
declare var jQuery: any;

@Component({
    selector: 'app-meeting-room',
    templateUrl: './meeting-room.component.html',
    styleUrls: ['./meeting-room.component.css']
})
export class MeetingRoomComponent implements OnInit {

    getAllMeetingRoomTabs: any;
    getMeetingRoomImages: any;
    imageURL: any;
    roomSize: any;
    hourPrice: any;
    halfDayPrice: any;
    perDayPrice: any;
    eventArray: any = [];
    orderType: any = {};
    isSelectedEvent = [];
    calendar: any;
    selectedRoomName: any;
    //purposeMessage: any = "Unavailable";
    selected_event: any = null;
    full_day_label: any = 'J'; //Journée entière';
    morning_label: any = 'M'; //Matin';
    afternoon_label: any = 'A'; //Après-midi';
    unavailable_label: any  = 'F'; //Fermé';

    constructor(
        private router: Router,
        private store: StoreService,
        private meetingRoom: MeetingRoomService,
        private userservice: UserService,
        private toastr: ToastrService,
        private spaceservice: SpaceService,
        public datepipe: DatePipe,
        private token: TokenService,
        private zone: NgZone
    ) { 
        this.userservice.loadScript();
        this.imageURL = environment.STORAGE_IMG_URL;
        this.fetchEvent();
        this.getClosingDates();
        this.store.remove('CurrentItemKey');
    }

    ngOnInit() {

        var id = this.getUrlParameter('id');

        if( id != '' ){
            this.token.set('service', 2);
            setTimeout(function(){
                let elem = document.getElementById( 'meeting_room_'+id );
                if( elem != null ){
                    let evt = new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    });
                    elem.dispatchEvent(evt);
                }
            }, 1000 );
        }

        var self = this;
        this.getMeetingRoomTab();
        this.getMeetingRoomImage();

        self.store.setRoomSize(null);
        self.store.setRoomType(null);

        self.calendar = jQuery('#calendarModal').fullCalendar({
            themeSystem: 'bootstrap4',
            header: {
                left: 'title',
                center: '',
                right: 'prev,next'
            },
            week: {
                dow: 1,
                doy: 4
            },
            buttonText: {
                today: "Aujourd'hui",
                year: "Année",
                month: "Mois",
                week: "Semaine",
                day: "Jour",
                list: "Mon planning"
            },
            firstDay: '1',
            weekLabel: "Sem.",
            monthNames: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
            monthNamesShort: ['Jan','Fév','Mar','Avr','Mai','Jui','Jui','Aoû','Sep','Oct','Nov','Déc'],
            dayNames: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
            dayNamesShort:["Dim.","Lun.","Mar.","Mer.","Jeu.","Ven.","Sam."],
            allDayHtml: "Toute la<br/>journée",
            eventLimitText: "en plus",
            noEventsMessage: "Aucun événement à afficher",
            businessHours: false,
            defaultView: 'month',
            editable: true,
            eventAfterAllRender: function(view){
                console.log('eventAfterAllRender');
                self.fetchEvent(); // get all booked event
                
                $(this).html(' ');
                jQuery('.event_selection').remove();

                var html  = '';
                var today = new Date();
                var dd    = String(today.getDate()).padStart(2, '0');
                var mm    = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy  = today.getFullYear();
                    today = new Date(yyyy+'-'+mm+'-'+dd); // today date

                var roomsize = jQuery('.rooms-boxes:checked').data('roomsize');
                var roomtype = jQuery('.rooms-boxes:checked').data('roomtype');
                
                $('#calendarModal .fc-day.fc-widget-content').each(function(i) {

                    html = '<div class="event_selection foo">';

                    // Fullcalendar includes a date data element on each day.
                    var date          = $(this).data('date');
                    var selected_date = new Date(date);

                    // prevent to past dates.
                    if(selected_date.getTime() < today.getTime()){
                        return;
                    }

                    var get_selected_event    = JSON.parse(self.store.GetEventSelection()) || []; // get all selected event by user.
                    var get_selected_event_temp = self.store.GetEventSelectionTemp() || [];
                    var event_class = '';
                    var event_key             = localStorage.getItem('service')+roomsize+roomtype+date.replace(/-/g,''); // get event key.
                    
                    // check that any event is already booked orchoosed by user in particular day.
                    if( 
                        ( get_selected_event != null && self.in_array_r( event_key, get_selected_event ) ) ||
                        ( get_selected_event_temp != null && self.in_array_r( event_key, get_selected_event_temp ) ) 
                        ){
                        
                        var fullday_item_key = localStorage.getItem('service')+roomsize+roomtype+"fullday"+date.replace(/-/g,''); // get full day event item key.
                        var morning_item_key = localStorage.getItem('service')+roomsize+roomtype+"morning"+date.replace(/-/g,''); // morning item key.
                        var afternoon_item_key = localStorage.getItem('service')+roomsize+roomtype+"afternoon"+date.replace(/-/g,''); // aftrenoon item key.

                        // check that fullday is booked or choosed by user in particular day.
                        if(
                            ( self.get_value_by_key( fullday_item_key, get_selected_event ) != null && self.get_value_by_key( fullday_item_key, get_selected_event ).event_day_type == 'fullday' ) || 
                            ( self.get_value_by_key( fullday_item_key, get_selected_event_temp ) != null && self.get_value_by_key( fullday_item_key, get_selected_event_temp ).event_day_type == 'fullday' ) 
                            ){

                            var hide_fullday    = "none;";
                            var full_day_remove = '';
                                event_class     = 'full_day';

                            // check that fullday is already booked or choosed by user in particular day.
                            /*if( self.get_value_by_key( fullday_item_key, get_selected_event ) != null && self.get_value_by_key( fullday_item_key, get_selected_event ).event_day_type == 'fullday' ){
                                hide_fullday    = "block";
                                full_day_remove = '<a href="#" class="remove_this_event" data-event-key="'+self.get_value_by_key( fullday_item_key, get_selected_event ).event_key+'" data-item-key="'+self.get_value_by_key( fullday_item_key, get_selected_event ).item_key+'" data-type="fullday">X</a>';
                            }*/
                            
                            // check that fullday is already booked and status is complete
                            if( self.get_value_by_key( fullday_item_key, get_selected_event ) != null && self.get_value_by_key( fullday_item_key, get_selected_event ).status == 'complete' ){
                                html += self.unavailableDayHtml(date,'',self.full_day_label); // set that day as unavailable.
                                event_class = 'unavailable_day';
                            }else{
                                if( !self.checkDateInHolidays(date) ){
                                    html += '<div class="add_event_wrapper full_day_section"><button type="button" class="add_event fullday '+fullday_item_key+' " data-type="fullday" data-date="'+date+'" style="display:'+hide_fullday+'">'+self.full_day_label+'</button>'+full_day_remove+'</div>';
                                }else{
                                    if( self.checkDateInHolidays(date) && self.checkMorningAvailable(date) && self.checkAfternoonAvailable(date) ){
                                        html += '<div class="add_event_wrapper full_day_section"><button type="button" class="add_event fullday '+fullday_item_key+' " data-type="fullday" data-date="'+date+'" style="display:'+hide_fullday+'">'+self.full_day_label+'</button>'+full_day_remove+'</div>';
                                    }
                                }

                                var data_event_key = '';
                                
                                if( self.get_value_by_key( fullday_item_key, get_selected_event ) != null ){
                                    data_event_key = self.get_value_by_key( fullday_item_key, get_selected_event ).event_key;
                                }

                                if( self.get_value_by_key( fullday_item_key, get_selected_event_temp ) != null ){
                                    data_event_key = self.get_value_by_key( fullday_item_key, get_selected_event_temp ).event_key;
                                }

                                morning_remove = '<a href="#" class="remove_this_event" data-event-key="'+data_event_key+'" data-item-key="'+morning_item_key+'" data-type="morning">X</a>';

                                // check that morning is already booked and status is complete in particular day and if then set as unavailable.
                                console.log( date );
                                console.log( self.get_value_by_key( morning_item_key, get_selected_event ) );
                                if( self.get_value_by_key( morning_item_key, get_selected_event ) != null && self.get_value_by_key( morning_item_key, get_selected_event ).status == 'complete' ){
                                    html += self.unavailableDayHtml(date,'morning',self.morning_label); // set that day as unavailable.
                                }else{
                                    if( !self.checkDateInHolidays(date) ){
                                        html += '<div class="add_event_wrapper first_half_section"><button type="button" class="add_event morning '+morning_item_key+'" data-type="morning" data-date="'+date+'" >'+self.morning_label+'</button>'+morning_remove+'</div>';
                                    }else{
                                        if( self.checkDateInHolidays(date) && self.checkMorningAvailable(date) ){
                                            html += '<div class="add_event_wrapper first_half_section"><button type="button" class="add_event morning '+morning_item_key+'" data-type="morning" data-date="'+date+'" >'+self.morning_label+'</button>'+morning_remove+'</div>';
                                        }
                                    }
                                }

                                afternoon_remove = '<a href="#" class="remove_this_event" data-event-key="'+data_event_key+'" data-item-key="'+afternoon_item_key+'" data-type="afternoon">X</a>';

                                // check that afternoon is already booked and status is complete in particular day and if then set as unavailable.
                                if( self.get_value_by_key( afternoon_item_key, get_selected_event ) != null && self.get_value_by_key( afternoon_item_key, get_selected_event ).status == 'complete' ){
                                    html += self.unavailableDayHtml(date,'afternoon',self.afternoon_label); // set that day as unavailable.
                                }else{
                                    if( !self.checkDateInHolidays(date) ){
                                        html += '<div class="add_event_wrapper sec_half_section"><button type="button" class="add_event afternoon '+afternoon_item_key+'" data-type="afternoon" data-date="'+date+'" >'+self.afternoon_label+'</button>'+afternoon_remove+'</div>';
                                    }else{
                                        if( self.checkDateInHolidays(date) && self.checkAfternoonAvailable(date) ){
                                            html += '<div class="add_event_wrapper sec_half_section"><button type="button" class="add_event afternoon '+afternoon_item_key+'" data-type="afternoon" data-date="'+date+'" >'+self.afternoon_label+'</button>'+afternoon_remove+'</div>';
                                        }
                                    }
                                }

                                if( 
                                    ( 
                                        self.get_value_by_key( fullday_item_key, get_selected_event ) != null && self.get_value_by_key( fullday_item_key, get_selected_event ).event_day_type == 'fullday'
                                    )
                                    ||
                                    ( 
                                        self.get_value_by_key( fullday_item_key, get_selected_event_temp ) != null && self.get_value_by_key( fullday_item_key, get_selected_event_temp ).event_day_type == 'fullday'
                                    )
                                ){
                                    event_class = 'full_day first_second_section';
                                }else if( 
                                            ( self.get_value_by_key( morning_item_key, get_selected_event ) != null && self.get_value_by_key( morning_item_key, get_selected_event ).event_day_type == 'morning' ) ||
                                            ( self.get_value_by_key( morning_item_key, get_selected_event_temp ) != null && self.get_value_by_key( morning_item_key, get_selected_event_temp ).event_day_type == 'morning' )
                                        ) {
                                            event_class = 'first_half';
                                }else if( 
                                            ( self.get_value_by_key( afternoon_item_key, get_selected_event ) != null && self.get_value_by_key( afternoon_item_key, get_selected_event ).event_day_type == 'afternoon' ) ||
                                            ( self.get_value_by_key( afternoon_item_key, get_selected_event_temp ) != null && self.get_value_by_key( afternoon_item_key, get_selected_event_temp ).event_day_type == 'afternoon' )
                                        ){
                                            event_class = 'sec_half';
                                }else{
                                    event_class = '';
                                }

                                //event_class = 'full_day first_second_section';
                            }

                        }else{

                            var morning_item_key   = localStorage.getItem('service')+roomsize+roomtype+"morning"+date.replace(/-/g,''); // morning item key.
                            var afternoon_item_key = localStorage.getItem('service')+roomsize+roomtype+"afternoon"+date.replace(/-/g,''); // aftrenoon item key.

                            // check that morning and aftrenooon is already booked and status is complete. and if then set that day as unavailable.
                            if( 
                                ( 
                                    self.get_value_by_key( morning_item_key, get_selected_event ) != null && self.get_value_by_key( morning_item_key, get_selected_event ).event_day_type == 'morning' &&
                                    self.get_value_by_key( morning_item_key, get_selected_event ).status == 'complete' 

                                ) &&
                                (
                                    self.get_value_by_key( afternoon_item_key, get_selected_event ) != null && self.get_value_by_key( afternoon_item_key, get_selected_event ).event_day_type == 'afternoon' &&
                                    self.get_value_by_key( afternoon_item_key, get_selected_event ).status == 'complete'
                                )    
                            ){
                                html += self.unavailableDayHtml(date,'',self.full_day_label); // set that day as unavailable.
                                event_class = 'unavailable_day';
                                
                            }else{

                                var hide_fullday    = "none;";
                                var full_day_html = '<div class="add_event_wrapper full_day_section"><button type="button" class="add_event fullday '+fullday_item_key+' " data-type="fullday" data-date="'+date+'" style="display:'+hide_fullday+'">'+self.full_day_label+'</button></div>';

                                // check that fullday is already booked and status is complete
                                                              
                                if( self.checkDateInHolidays(date) && ( !self.checkMorningAvailable(date) || !self.checkAfternoonAvailable(date) ) ){
                                    var full_day_html = '';
                                }

                                html += full_day_html;

                                var hide_morning   = "block;";
                                var morning_remove = '';
                                var complete_class = "";

                                var morning_data_event_key = '';
                                var morning_data_item_key = '';
                                
                                if( self.get_value_by_key( morning_item_key, get_selected_event ) != null ){
                                    morning_data_event_key = self.get_value_by_key( morning_item_key, get_selected_event ).event_key;
                                    morning_data_item_key = self.get_value_by_key( morning_item_key, get_selected_event ).item_key;
                                }

                                if( self.get_value_by_key( morning_item_key, get_selected_event_temp ) != null ){
                                    morning_data_event_key = self.get_value_by_key( morning_item_key, get_selected_event_temp ).event_key;
                                    morning_data_item_key = self.get_value_by_key( morning_item_key, get_selected_event_temp ).item_key;
                                }

                                // check that morning is already booked or choosed by user in particular day.
                                if( 
                                    ( self.get_value_by_key( morning_item_key, get_selected_event ) != null && self.get_value_by_key( morning_item_key, get_selected_event ).event_day_type == 'morning' ) ||
                                    ( self.get_value_by_key( morning_item_key, get_selected_event_temp ) != null && self.get_value_by_key( morning_item_key, get_selected_event_temp ).event_day_type == 'morning' )

                                ){
                                    event_class = 'first_half';
                                    morning_remove = '<a href="#" class="remove_this_event" data-event-key="'+morning_data_event_key+'" data-item-key="'+morning_data_item_key+'" data-type="morning">X</a>';   
                                }

                                console.log( date );
                                console.log( self.get_value_by_key( morning_item_key, get_selected_event ) );
                                // check that morning is already booked and status is complete in particular day and if then set as unavailable.
                                if( self.get_value_by_key( morning_item_key, get_selected_event ) != null && self.get_value_by_key( morning_item_key, get_selected_event ).status == 'complete' ){
                                    html += self.unavailableDayHtml(date,'morning',self.morning_label); // set that day as unavailable.
                                }else{
                                    if( !self.checkDateInHolidays(date) ){
                                        html += '<div class="add_event_wrapper first_half_section"><button type="button" class="add_event morning '+morning_item_key+' '+complete_class+'" data-type="morning" data-date="'+date+'" style="display:'+hide_morning+'">'+self.morning_label+'</button>'+morning_remove+'</div>';
                                    }else{
                                        if( self.checkDateInHolidays(date) && self.checkMorningAvailable(date) ){
                                          //console.log( 'if 8' );
                                            html += '<div class="add_event_wrapper first_half_section"><button type="button" class="add_event morning '+morning_item_key+' '+complete_class+'" data-type="morning" data-date="'+date+'" style="display:'+hide_morning+'">'+self.morning_label+'</button>'+morning_remove+'</div>';
                                        }
                                    }
                                }
                                
                                var hide_afternoon   = "block;";
                                var afternoon_remove = '';
                                var complete_class   = "";

                                var afternoon_data_event_key = '';
                                var afternoon_data_item_key = '';
                                
                                if( self.get_value_by_key( afternoon_item_key, get_selected_event ) != null ){
                                    afternoon_data_event_key = self.get_value_by_key( afternoon_item_key, get_selected_event ).event_key;
                                    afternoon_data_item_key = self.get_value_by_key( afternoon_item_key, get_selected_event ).item_key;
                                }

                                if( self.get_value_by_key( afternoon_item_key, get_selected_event_temp ) != null ){
                                    afternoon_data_event_key = self.get_value_by_key( afternoon_item_key, get_selected_event_temp ).event_key;
                                    afternoon_data_item_key = self.get_value_by_key( afternoon_item_key, get_selected_event_temp ).item_key;
                                }

                                // check that afternoon is already booked or choosed by user in particular day.
                                if( 
                                    ( self.get_value_by_key( afternoon_item_key, get_selected_event ) != null && self.get_value_by_key( afternoon_item_key, get_selected_event ).event_day_type == 'afternoon' ) ||
                                    ( self.get_value_by_key( afternoon_item_key, get_selected_event_temp ) != null && self.get_value_by_key( afternoon_item_key, get_selected_event_temp ).event_day_type == 'afternoon' )
                                ){
                                    event_class = 'sec_half';
                                    afternoon_remove = '<a href="#" class="remove_this_event" data-event-key="'+afternoon_data_event_key+'" data-item-key="'+afternoon_data_item_key+'" data-type="afternoon">X</a>';
                                }

                                // check that afternoon is already booked and status is complete in particular day and if then set as unavailable.
                                if( self.get_value_by_key( afternoon_item_key, get_selected_event ) != null && self.get_value_by_key( afternoon_item_key, get_selected_event ).status == 'complete' ){
                                    html += self.unavailableDayHtml(date,'afternoon',self.afternoon_label); // set that day as unavailable.
                                }else{
                                    if( !self.checkDateInHolidays(date) ){
                                        html += '<div class="add_event_wrapper sec_half_section"><button type="button" class="add_event afternoon '+afternoon_item_key+' '+complete_class+'" data-type="afternoon" data-date="'+date+'" style="display:'+hide_afternoon+'">'+self.afternoon_label+'</button>'+afternoon_remove+'</div>';
                                    }else{
                                        if( self.checkDateInHolidays(date) && self.checkAfternoonAvailable(date) ){
                                            html += '<div class="add_event_wrapper sec_half_section"><button type="button" class="add_event afternoon '+afternoon_item_key+' '+complete_class+'" data-type="afternoon" data-date="'+date+'" style="display:'+hide_afternoon+'">'+self.afternoon_label+'</button>'+afternoon_remove+'</div>';
                                        }
                                    }
                                }
                                
                                if( 
                                    (
                                        self.get_value_by_key( morning_item_key, get_selected_event ) != null && 
                                        self.get_value_by_key( morning_item_key, get_selected_event ).event_day_type == 'morning' &&
                                        self.get_value_by_key( morning_item_key, get_selected_event ).status != 'complete'
                                    ) &&
                                    (
                                        self.get_value_by_key( afternoon_item_key, get_selected_event ) != null && 
                                        self.get_value_by_key( afternoon_item_key, get_selected_event ).event_day_type == 'afternoon' &&
                                        self.get_value_by_key( afternoon_item_key, get_selected_event ).status == 'complete'
                                    )
                                ){
                                    event_class = 'full_day first_second_section';
                                }

                            }

                        }  

                    }else{

                        if(self.token.get('getClosingDates')){

                            var getClosingDates = JSON.parse(self.token.get('getClosingDates'));
                            
                            if( typeof getClosingDates !== 'undefined' ){

                                if( typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 && self.checkDateInHolidays(date) ){

                                    var fullday_item_key = localStorage.getItem('service')+roomsize+roomtype+"fullday"+date.replace(/-/g,''); // get full day event item key.
                                    var morning_item_key = localStorage.getItem('service')+roomsize+roomtype+"morning"+date.replace(/-/g,''); // morning item key.
                                    var afternoon_item_key = localStorage.getItem('service')+roomsize+roomtype+"afternoon"+date.replace(/-/g,''); // aftrenoon item key.

                                    if( self.checkMorningAvailable(date) && self.checkAfternoonAvailable(date) ){

                                        html += '<div class="add_event_wrapper full_day_section"><button type="button" class="add_event fullday '+fullday_item_key+' " data-type="fullday" data-date="'+date+'">'+self.full_day_label+'</button></div>';
                                        html += '<div class="add_event_wrapper first_half_section"><button type="button" class="add_event morning '+morning_item_key+' " data-type="morning" data-date="'+date+'">'+self.morning_label+'</button></div>';
                                        html += '<div class="add_event_wrapper sec_half_section"><button type="button" class="add_event afternoon '+afternoon_item_key+' " data-type="afternoon" data-date="'+date+'">'+self.afternoon_label+'</button></div>';

                                    }else if( self.checkMorningAvailable(date) && !self.checkAfternoonAvailable(date) ){
                                        html += '<div class="add_event_wrapper first_half_section"><button type="button" class="add_event morning '+morning_item_key+'" data-type="morning" data-date="'+date+'">'+self.morning_label+'</button></div>';
                                    }else if( !self.checkMorningAvailable(date) && self.checkAfternoonAvailable(date) ){
                                        html += '<div class="add_event_wrapper sec_half_section"><button type="button" class="add_event afternoon '+afternoon_item_key+'" data-type="afternoon" data-date="'+date+'">'+self.afternoon_label+'</button></div>';
                                        
                                    }else{
                                        
                                        if( typeof self.get_closing_dates_holiday_data( date, getClosingDates.holidays ).purpose !== 'undefined' && self.get_closing_dates_holiday_data( date, getClosingDates.holidays ).purpose != '' ){
                                            this.purposeMessage = self.get_closing_dates_holiday_data( date, getClosingDates.holidays ).purpose;
                                        }
                                        event_class = 'holiday';
                                        html += self.unavailableDayHtml(date);
                                    }

                                }else{
                                    
                                    if( typeof getClosingDates.week_days !== 'undefined' && getClosingDates.week_days.length > 0 ){

                                        var currentDate = moment(date);

                                        if( currentDate.isoWeekday() == 1 ){
                                            
                                            if( getClosingDates.week_days[0].monday_morning == '1' && getClosingDates.week_days[0].monday_afternoon == '1' ){
                                                html += self.fullDayHtml(date);
                                                jQuery( '[data-date="'+date+'"].fc-day-number' ).addClass('date-highlight');
                                            }

                                            if( getClosingDates.week_days[0].monday_morning == '1' ){
                                                html += self.morningDayHtml(date);
                                                jQuery( '[data-date="'+date+'"].fc-day-number' ).addClass('date-highlight');
                                            }

                                            if( getClosingDates.week_days[0].monday_afternoon == '1' ){
                                                html += self.afternoonDayHtml(date);
                                                jQuery( '[data-date="'+date+'"].fc-day-number' ).addClass('date-highlight');
                                            }

                                            if( getClosingDates.week_days[0].monday_morning == '0' && getClosingDates.week_days[0].monday_afternoon == '0' ){
                                                html += self.unavailableDayHtml(date);
                                                event_class = 'unavailable_day';
                                            }
                                            
                                        }else if( currentDate.isoWeekday() == 2 ){

                                            if( getClosingDates.week_days[0].tuesday_morning == '1' && getClosingDates.week_days[0].tuesday_afternoon == '1' ){
                                                html += self.fullDayHtml(date);
                                                jQuery( '[data-date="'+date+'"].fc-day-number' ).addClass('date-highlight');
                                            }

                                            if( getClosingDates.week_days[0].tuesday_morning == '1' ){
                                                html += self.morningDayHtml(date);
                                                jQuery( '[data-date="'+date+'"].fc-day-number' ).addClass('date-highlight');
                                            }

                                            if( getClosingDates.week_days[0].tuesday_afternoon == '1' ){
                                                html += self.afternoonDayHtml(date);
                                                jQuery( '[data-date="'+date+'"].fc-day-number' ).addClass('date-highlight');
                                            }

                                            if( getClosingDates.week_days[0].tuesday_morning == '0' && getClosingDates.week_days[0].tuesday_afternoon == '0' ){
                                                html += self.unavailableDayHtml(date);
                                                event_class = 'unavailable_day';
                                            }

                                        }else if( currentDate.isoWeekday() == 3 ){
                                            
                                            if( getClosingDates.week_days[0].wednesday_morning == '1' && getClosingDates.week_days[0].wednesday_afternoon == '1' ){
                                                html += self.fullDayHtml(date);
                                                jQuery( '[data-date="'+date+'"].fc-day-number' ).addClass('date-highlight');
                                            }

                                            if( getClosingDates.week_days[0].wednesday_morning == '1' ){
                                                html += self.morningDayHtml(date);
                                                jQuery( '[data-date="'+date+'"].fc-day-number' ).addClass('date-highlight');
                                            }

                                            if( getClosingDates.week_days[0].wednesday_afternoon == '1' ){
                                                html += self.afternoonDayHtml(date);
                                                jQuery( '[data-date="'+date+'"].fc-day-number' ).addClass('date-highlight');
                                            }

                                            if( getClosingDates.week_days[0].wednesday_morning == '0' && getClosingDates.week_days[0].wednesday_afternoon == '0' ){
                                                html += self.unavailableDayHtml(date);
                                                event_class = 'unavailable_day';
                                            }

                                        }else if( currentDate.isoWeekday() == 4 ){
                                            
                                            if( getClosingDates.week_days[0].thursday_morning == '1' && getClosingDates.week_days[0].thursday_afternoon == '1' ){
                                                html += self.fullDayHtml(date);
                                                jQuery( '[data-date="'+date+'"].fc-day-number' ).addClass('date-highlight');
                                            }

                                            if( getClosingDates.week_days[0].thursday_morning == '1' ){
                                                html += self.morningDayHtml(date);
                                                jQuery( '[data-date="'+date+'"].fc-day-number' ).addClass('date-highlight');
                                            }

                                            if( getClosingDates.week_days[0].thursday_afternoon == '1' ){
                                                html += self.afternoonDayHtml(date);
                                                jQuery( '[data-date="'+date+'"].fc-day-number' ).addClass('date-highlight');
                                            }

                                            if( getClosingDates.week_days[0].thursday_morning == '0' && getClosingDates.week_days[0].thursday_afternoon == '0' ){
                                                html += self.unavailableDayHtml(date);
                                                event_class = 'unavailable_day';
                                            }

                                        }else if( currentDate.isoWeekday() == 5 ){
                                            
                                            if( getClosingDates.week_days[0].friday_morning == '1' && getClosingDates.week_days[0].friday_afternoon == '1' ){
                                                html += self.fullDayHtml(date);
                                                jQuery( '[data-date="'+date+'"].fc-day-number' ).addClass('date-highlight');
                                            }

                                            if( getClosingDates.week_days[0].friday_morning == '1' ){
                                                html += self.morningDayHtml(date);
                                                jQuery( '[data-date="'+date+'"].fc-day-number' ).addClass('date-highlight');
                                            }

                                            if( getClosingDates.week_days[0].friday_afternoon == '1' ){
                                                html += self.afternoonDayHtml(date);
                                                jQuery( '[data-date="'+date+'"].fc-day-number' ).addClass('date-highlight');
                                            }

                                            if( getClosingDates.week_days[0].friday_morning == '0' && getClosingDates.week_days[0].friday_afternoon == '0' ){
                                                html += self.unavailableDayHtml(date);
                                                event_class = 'unavailable_day';
                                            }

                                        }else if( currentDate.isoWeekday() == 6 ){
                                            
                                            if( getClosingDates.week_days[0].saturday_morning == '1' && getClosingDates.week_days[0].saturday_afternoon == '1' ){
                                                html += self.fullDayHtml(date);
                                            }

                                            if( getClosingDates.week_days[0].saturday_morning == '1' ){
                                                html += self.morningDayHtml(date);
                                            }

                                            if( getClosingDates.week_days[0].saturday_afternoon == '1' ){
                                                html += self.afternoonDayHtml(date);
                                            }

                                            if( getClosingDates.week_days[0].saturday_morning == '0' && getClosingDates.week_days[0].saturday_afternoon == '0' ){
                                                html += self.unavailableDayHtml(date);
                                                event_class = 'unavailable_day';
                                            }

                                        }else if( currentDate.isoWeekday() == 7 ){
                                            
                                            if( getClosingDates.week_days[0].sunday_morning == '1' && getClosingDates.week_days[0].sunday_afternoon == '1' ){
                                                html += self.fullDayHtml(date);
                                            }

                                            if( getClosingDates.week_days[0].sunday_morning == '1' ){
                                                html += self.morningDayHtml(date);
                                            }

                                            if( getClosingDates.week_days[0].sunday_afternoon == '1' ){
                                                html += self.afternoonDayHtml(date);
                                            }

                                            if( getClosingDates.week_days[0].sunday_morning == '0' && getClosingDates.week_days[0].sunday_afternoon == '0' ){
                                                html += self.unavailableDayHtml(date);
                                                event_class = 'unavailable_day';
                                            }

                                        }
                                    }else{
                                        html += self.allDayHtml(date);
                                    }
                                }
                            }else{
                                html += self.allDayHtml(date);
                            }
                        }else{
                            html += self.allDayHtml(date);
                        }

                    }

                    html += '</div>';

                    $(this).removeClass('full_day');
                    $(this).removeClass('first_half');
                    $(this).removeClass('sec_half');

                    $(this).append(html).addClass(event_class);

                    if( self.getUnavailableFullMsg( date ) ){
                        var unavailableFullMsg = self.getUnavailableFullMsg( date );
                        jQuery(this).attr( "data-tooltip", unavailableFullMsg );
                    }

                });
            },dayClick: function(date, jsEvent, view, element) {
                if (moment().format('YYYY-MM-DD') === date.format('YYYY-MM-DD') || date.isAfter(moment())) {
                } else {
                    // Else part is for past dates
                    jQuery('#pastDayClick').modal();
                }
            }
        })

        jQuery('#calendar-modal-view-event').on('shown.bs.modal', function () {
            self.store.remove('selected_events_temp');
            jQuery("#calendarModal").fullCalendar('render');
            jQuery('#calendarModal').fullCalendar('refetchEvents');
        });

        jQuery('#calendar-modal-view-event').on('hidden.bs.modal', function () {
            self.store.remove('CurrentItemKey');
            self.store.remove('selected_events_temp');
            self.token.remove( 'edit_item' );
        });

        jQuery(document).on("click",".add_event",function(e) {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            self.add_event($(this));
        });

        jQuery(document).on("click",".remove_this_event",function(e) {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            self.remove_event($(this));
        });

    }

    fullDayHtml( date ){
        
        var roomsize  = jQuery('.rooms-boxes:checked').data('roomsize');
        var roomtype  = jQuery('.rooms-boxes:checked').data('roomtype');
        var roomname  = jQuery('.rooms-boxes:checked').data('roomname');
        var event_key = localStorage.getItem('service')+roomsize+roomtype+date.replace(/-/g,'');
        var item_key  = localStorage.getItem('service')+roomsize+roomtype+'fullday'+date.replace(/-/g,'');

        return '<div class="add_event_wrapper full_day_section"><button type="button" class="add_event fullday '+item_key+' " data-type="fullday" data-date="'+date+'">'+this.full_day_label+'</button></div>';
    }

    morningDayHtml( date ){

        var roomsize  = jQuery('.rooms-boxes:checked').data('roomsize');
        var roomtype  = jQuery('.rooms-boxes:checked').data('roomtype');
        var roomname  = jQuery('.rooms-boxes:checked').data('roomname');
        var event_key = localStorage.getItem('service')+roomsize+roomtype+date.replace(/-/g,'');
        var item_key  = localStorage.getItem('service')+roomsize+roomtype+'morning'+date.replace(/-/g,'');

        return '<div class="add_event_wrapper first_half_section"><button type="button" class="add_event morning '+item_key+'" data-type="morning" data-date="'+date+'">'+this.morning_label+'</button></div>';
    }

    afternoonDayHtml( date ){

        var roomsize  = jQuery('.rooms-boxes:checked').data('roomsize');
        var roomtype  = jQuery('.rooms-boxes:checked').data('roomtype');
        var roomname  = jQuery('.rooms-boxes:checked').data('roomname');
        var event_key = localStorage.getItem('service')+roomsize+roomtype+date.replace(/-/g,'');
        var item_key  = localStorage.getItem('service')+roomsize+roomtype+'afternoon'+date.replace(/-/g,'');


        return '<div class="add_event_wrapper sec_half_section"><button type="button" class="add_event afternoon '+item_key+'" data-type="afternoon" data-date="'+date+'">'+this.afternoon_label+'</button></div>';
    }

    allDayHtml( date ){

        var roomsize           = jQuery('.rooms-boxes:checked').data('roomsize');
        var roomtype           = jQuery('.rooms-boxes:checked').data('roomtype');
        var roomname           = jQuery('.rooms-boxes:checked').data('roomname');
        var event_key          = localStorage.getItem('service')+roomsize+roomtype+date.replace(/-/g,'');
        var fullday_item_key   = localStorage.getItem('service')+roomsize+roomtype+'fullday'+date.replace(/-/g,'');
        var morning_item_key   = localStorage.getItem('service')+roomsize+roomtype+'morning'+date.replace(/-/g,'');
        var afternoon_item_key = localStorage.getItem('service')+roomsize+roomtype+'afternoon'+date.replace(/-/g,'');

        var html = '<div class="add_event_wrapper full_day_section"><button type="button" class="add_event fullday '+fullday_item_key+'" data-type="fullday" data-date="'+date+'">'+this.full_day_label+'</button></div>';
        html += '<div class="add_event_wrapper first_half_section"><button type="button" class="add_event morning '+morning_item_key+'" data-type="morning" data-date="'+date+'">'+this.morning_label+'</button></div>';
        html += '<div class="add_event_wrapper sec_half_section"><button type="button" class="add_event afternoon '+afternoon_item_key+'" data-type="afternoon" data-date="'+date+'">'+this.afternoon_label+'</button></div>';

        return html;
    }

    unavailableDayHtml( date, className = '', msg = '' ){
        var unavailableMsg = this.unavailable_label;
        var parent_class = '';
        if( className == 'morning' ){
            parent_class = 'morning_unavailable';
        } else if( className == 'afternoon' ){
            parent_class = 'afternoon_unavailable';
        }
        if(msg != ''){
            unavailableMsg = msg;
        }

        if(this.getUnavailableMsg(date)){
            unavailableMsg = this.getUnavailableMsg(date);
        }
        return '<div class="add_event_wrapper unavailable '+parent_class+'"><span class="complete '+className+' ">'+unavailableMsg+'</span></div>';
    }

    getUnavailableFullMsg( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));   
        if( typeof getClosingDates !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 ){
            for (var i = getClosingDates.holidays.length - 1; i >= 0; --i) {
                if ( getClosingDates.holidays[i].date == date) {
                    if( typeof getClosingDates.holidays[i].purpose !== 'undefined' && getClosingDates.holidays[i].purpose != '' ){
                        let holiday_purpose = getClosingDates.holidays[i].purpose.split(" ");
                        let short_purpose_text = '';
                        if( holiday_purpose.length > 1 ){
                            for ( var k = 0; k <= 1; k++ ) {
                                short_purpose_text += holiday_purpose[k].charAt(0);
                            }
                        } else{
                            short_purpose_text = holiday_purpose.charAt(0);
                        }
                        return getClosingDates.holidays[i].purpose;
                    }
                }
            }
        }
        return false;
    }

    getUnavailableMsg( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));   
        if( typeof getClosingDates !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 ){
            for (var i = getClosingDates.holidays.length - 1; i >= 0; --i) {
                if ( getClosingDates.holidays[i].date == date) {
                    if( typeof getClosingDates.holidays[i].purpose !== 'undefined' && getClosingDates.holidays[i].purpose != '' ){
                        let holiday_purpose = getClosingDates.holidays[i].purpose.split(" ");
                        let short_purpose_text = '';
                        if( holiday_purpose.length > 1 ){
                            for ( var k = 0; k <= 1; k++ ) {
                                short_purpose_text += holiday_purpose[k].charAt(0);
                            }
                        } else{
                            short_purpose_text = holiday_purpose.charAt(0);
                        }
                        return short_purpose_text;
                    }
                }
            }
        }
        return false;
    }

    checkDateInHolidays( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));
        
        if( typeof getClosingDates !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 && this.in_array_r( date, getClosingDates.holidays )){
            return true;
        }

        return false;
    }

    checkMorningAvailable( date ){
        
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));
            
        if( typeof getClosingDates !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 ){
            for (var i = getClosingDates.holidays.length - 1; i >= 0; --i) {
                if ( getClosingDates.holidays[i].date == date && getClosingDates.holidays[i].morning == '1' ) {
                    return true;
                }
            }
        }

        return false;
    }

    checkAfternoonAvailable( date ){

        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));
        
        if( typeof getClosingDates !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 ){
            for (var i = getClosingDates.holidays.length - 1; i >= 0; --i) {
                if ( getClosingDates.holidays[i].date == date && getClosingDates.holidays[i].afternoon == '1' ) {
                    return true;
                }
            }
        }

        return false;
    }

    checkSelectDayIsAvailable( date ){
        var self = this;
        if(self.token.get('getClosingDates')){
            var getClosingDates = JSON.parse(self.token.get('getClosingDates'));
            if( typeof getClosingDates !== 'undefined' ){
                if( typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 && self.checkDateInHolidays(date) ){
                    if( self.checkMorningAvailable(date) || self.checkAfternoonAvailable(date) ){   
                        return true;
                    }
                }else{
                    if( self.checkDateInWeekdays(date) ){
                        return true;
                    }
                }
            }
        }
        return false;
    }

    checkMorningAvailableInWeekDays( date ){
        var self = this;

        if(self.token.get('getClosingDates')){

            var getClosingDates = JSON.parse(self.token.get('getClosingDates'));
            
            if( typeof getClosingDates !== 'undefined' ){

                if( typeof getClosingDates.week_days !== 'undefined' && getClosingDates.week_days.length > 0 ){

                    var currentDate = moment(date);

                    if( currentDate.isoWeekday() == 1 ){
                        if( getClosingDates.week_days[0].monday_morning == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 2 ){
                        if( getClosingDates.week_days[0].tuesday_morning == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 3 ){
                        if( getClosingDates.week_days[0].wednesday_morning == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 4 ){
                        if( getClosingDates.week_days[0].thursday_morning == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 5 ){
                        if( getClosingDates.week_days[0].friday_morning == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 6 ){
                        if( getClosingDates.week_days[0].saturday_morning == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 7 ){
                        if( getClosingDates.week_days[0].sunday_morning == '1' ){
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    checkFullDayAvailableInWeekDays( date ){
        
        var self = this;

        if(self.token.get('getClosingDates')){

            var getClosingDates = JSON.parse(self.token.get('getClosingDates'));
            
            if( typeof getClosingDates !== 'undefined' ){

                if( typeof getClosingDates.week_days !== 'undefined' && getClosingDates.week_days.length > 0 ){

                    var currentDate = moment(date);

                    if( currentDate.isoWeekday() == 1 ){
                        if( getClosingDates.week_days[0].monday_morning == '1' && getClosingDates.week_days[0].monday_afternoon == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 2 ){
                        if( getClosingDates.week_days[0].tuesday_morning == '1' && getClosingDates.week_days[0].tuesday_afternoon == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 3 ){
                        if( getClosingDates.week_days[0].wednesday_morning == '1' && getClosingDates.week_days[0].wednesday_afternoon == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 4 ){
                        if( getClosingDates.week_days[0].thursday_morning == '1' && getClosingDates.week_days[0].thursday_afternoon == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 5 ){
                        if( getClosingDates.week_days[0].friday_morning == '1' && getClosingDates.week_days[0].friday_afternoon == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 6 ){
                        if( getClosingDates.week_days[0].saturday_morning == '1' && getClosingDates.week_days[0].saturday_afternoon == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 7 ){
                        if( getClosingDates.week_days[0].sunday_morning == '1' && getClosingDates.week_days[0].sunday_afternoon == '1' ){
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    checkAfternoonAvailableInWeekDays( date ){
        var self = this;

        if(self.token.get('getClosingDates')){

            var getClosingDates = JSON.parse(self.token.get('getClosingDates'));
            
            if( typeof getClosingDates !== 'undefined' ){

                if( typeof getClosingDates.week_days !== 'undefined' && getClosingDates.week_days.length > 0 ){

                    var currentDate = moment(date);

                    if( currentDate.isoWeekday() == 1 ){
                        if( getClosingDates.week_days[0].monday_afternoon == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 2 ){
                        if( getClosingDates.week_days[0].tuesday_afternoon == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 3 ){
                        if( getClosingDates.week_days[0].wednesday_afternoon == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 4 ){
                        if( getClosingDates.week_days[0].thursday_afternoon == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 5 ){
                        if( getClosingDates.week_days[0].friday_afternoon == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 6 ){
                        if( getClosingDates.week_days[0].saturday_afternoon == '1' ){
                            return true;
                        }
                    }else if( currentDate.isoWeekday() == 7 ){
                        if( getClosingDates.week_days[0].sunday_afternoon == '1' ){
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    checkDateInWeekdays( date ){
        
        var self = this;

        if(self.token.get('getClosingDates')){

            var getClosingDates = JSON.parse(self.token.get('getClosingDates'));
            
            if( typeof getClosingDates !== 'undefined' ){

                if( typeof getClosingDates.week_days !== 'undefined' && getClosingDates.week_days.length > 0 ){

                    var currentDate = moment(date);

                    if( currentDate.isoWeekday() == 1 ){
                        
                        if( getClosingDates.week_days[0].monday_morning == '0' || getClosingDates.week_days[0].monday_afternoon == '0' ){
                            return false;
                        }
                        
                    }else if( currentDate.isoWeekday() == 2 ){

                        if( getClosingDates.week_days[0].tuesday_morning == '0' || getClosingDates.week_days[0].tuesday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 3 ){
                        
                        if( getClosingDates.week_days[0].wednesday_morning == '0' || getClosingDates.week_days[0].wednesday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 4 ){
                        
                        if( getClosingDates.week_days[0].thursday_morning == '0' || getClosingDates.week_days[0].thursday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 5 ){
                        
                        if( getClosingDates.week_days[0].friday_morning == '0' || getClosingDates.week_days[0].friday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 6 ){

                        if( getClosingDates.week_days[0].saturday_morning == '0' || getClosingDates.week_days[0].saturday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 7 ){
                        
                        if( getClosingDates.week_days[0].sunday_morning == '0' || getClosingDates.week_days[0].sunday_afternoon == '0' ){
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    add_event( clicked_obj ){

      //console.log( 'clicked_obj' );

        //jQuery( '[data-date="'+clicked_obj.data('date')+'"].fc-day-number' ).addClass('date-highlight');

        var roomsize       = jQuery('.rooms-boxes:checked').data('roomsize'),
            roomtype       = jQuery('.rooms-boxes:checked').data('roomtype'),
            roomname       = jQuery('.rooms-boxes:checked').data('roomname'),
            roomdesc       = jQuery('.rooms-boxes:checked').data('roomdesc'),
            roomimge       = jQuery('.rooms-boxes:checked').siblings('.img-wrap').find('img').attr('src'),
            event_key      = localStorage.getItem('service')+roomsize+roomtype+clicked_obj.data('date').replace(/-/g,''),
            item_key       = localStorage.getItem('service')+roomsize+roomtype+clicked_obj.data('type')+clicked_obj.data('date').replace(/-/g,''),
            CurrentItemKey = this.store.get('CurrentItemKey');

        // if(CurrentItemKey !== null){
        //     var x = document.getElementsByClassName(CurrentItemKey);
        //     this.remove_event(jQuery(x).next());
        // }

        this.store.setCurrentEventKey( event_key );
        this.store.setCurrentItemKey( item_key );

        var get_selected_event = JSON.parse(this.store.GetEventSelection()) || [];
        var get_selected_event_temp = this.store.GetEventSelectionTemp() || [];
        var price              = 0;
        // if user clicked in already choosed day then return from here.
        if( get_selected_event_temp != null && this.in_array_r( item_key, get_selected_event_temp ) ){
          //console.log('already there remove');
            return; //get_selected_event = this.remove_event_if_already( item_key, get_selected_event );
        }

        if( get_selected_event != null && this.in_array_r( item_key, get_selected_event ) ){
          //console.log('already there remove');
            return; //get_selected_event = this.remove_event_if_already( item_key, get_selected_event );
        }

        // if user select full day then hide mornig and afternoon option because that consider as booking whole day.
        if( clicked_obj.data('type') == 'fullday'){
            price = Number( localStorage.getItem('full_price') );
            jQuery(clicked_obj).parents('.fc-day').removeClass('first_half');
            jQuery(clicked_obj).parents('.fc-day').removeClass('sec_half');
            jQuery(clicked_obj).parents('.fc-day').removeClass('first_second_section');
            jQuery(clicked_obj).parents('.fc-day').addClass('full_day');
            jQuery(clicked_obj).parents('.event_selection').find('.morning').hide();
            jQuery(clicked_obj).parents('.event_selection').find('.afternoon').hide();
        }

        // if user select morning or afternoon then hide fullday.
        if( clicked_obj.data('type') == 'morning' || clicked_obj.data('type') == 'afternoon' ){

            jQuery( clicked_obj ).addClass( 'selected' );
            
            if( clicked_obj.data('type') == 'morning' ){
                jQuery(clicked_obj).parents('.fc-day').removeClass('sec_half');
                jQuery(clicked_obj).parents('.fc-day').removeClass('full_day');
                jQuery(clicked_obj).parents('.fc-day').removeClass('first_second_section');
                jQuery(clicked_obj).parents('.fc-day').addClass('first_half');
            }

            if( clicked_obj.data('type') == 'afternoon' ){
                jQuery(clicked_obj).parents('.fc-day').removeClass('first_half');
                jQuery(clicked_obj).parents('.fc-day').removeClass('full_day');       
                jQuery(clicked_obj).parents('.fc-day').removeClass('first_second_section');       
                jQuery(clicked_obj).parents('.fc-day').addClass('sec_half');
            }

            jQuery(clicked_obj).parents('.event_selection').find('.add_event_wrapper').each(function(){
                
                if( jQuery(this).find('.remove_this_event').length > 0 && ( jQuery(this).find('.remove_this_event').data('type') == 'morning' || jQuery(this).find('.remove_this_event').data('type') == 'afternoon' ) ){
                    //console.log('if');
                    jQuery(clicked_obj).parents('.fc-day').removeClass('first_half');
                    jQuery(clicked_obj).parents('.fc-day').removeClass('sec_half');
                    jQuery(clicked_obj).parents('.fc-day').addClass('full_day first_second_section');
                }else{
                    //console.log('else');
                    if( jQuery(this).find('.remove_this_event').length > 0 && jQuery(this).find('.remove_this_event').data('type') == 'morning' ){
                        jQuery(clicked_obj).parents('.fc-day').removeClass('full_day');
                        jQuery(clicked_obj).parents('.fc-day').removeClass('sec_half');
                        jQuery(clicked_obj).parents('.fc-day').removeClass('first_second_section');
                        jQuery(clicked_obj).parents('.fc-day').addClass('first_half');
                    }

                    if( jQuery(this).find('.remove_this_event').length > 0 && jQuery(this).find('.remove_this_event').data('type') == 'afternoon' ) {
                        jQuery(clicked_obj).parents('.fc-day').removeClass('first_half');
                        jQuery(clicked_obj).parents('.fc-day').removeClass('full_day');
                        jQuery(clicked_obj).parents('.fc-day').removeClass('first_second_section');
                        jQuery(clicked_obj).parents('.fc-day').addClass('sec_half');
                    }
                }
            });
            
            price = Number( localStorage.getItem('half_price') );
            jQuery(clicked_obj).parents('.event_selection').find('.fullday').hide();
            jQuery(clicked_obj).parents('.event_selection').find('.morning').show();
            jQuery(clicked_obj).parents('.event_selection').find('.afternoon').show();
        }

        var day_type = clicked_obj.data('type');

        if( 
            ( 
                this.in_array_r( event_key, get_selected_event_temp ) || 
                this.in_array_r( event_key, get_selected_event ) 
            ) &&
            (
                jQuery( clicked_obj ).parent().next().find('.remove_this_event').length > 0 ||
                jQuery( clicked_obj ).parent().prev().find('.remove_this_event').length > 0
            )
        ){
            if( get_selected_event_temp != null ){
                this.remove_temp_event_if_already(event_key,get_selected_event_temp);
            }

            if( get_selected_event != null ){
                this.remove_event_if_already(event_key,get_selected_event);
            }
            item_key = localStorage.getItem('service')+roomsize+roomtype+'fullday'+clicked_obj.data('date').replace(/-/g,'');
            day_type = 'fullday';
            price = Number( localStorage.getItem('full_price') );
            this.store.setCurrentItemKey( item_key );
        }

        // set this data in selected_event as obejct.
        this.selected_event = {
            "event_key": event_key,
            "item_key": item_key,
            "order_type": localStorage.getItem('service'),
            "room_id": roomtype,
            "room_size": roomsize,
            "room_name": roomname,
            "room_desc": roomdesc,
            "room_image": roomimge,
            "event_day_type": day_type,
            "event_start_date": clicked_obj.data('date'),
            "event_end_date": clicked_obj.data('date'),
            "price": price,
            "status": "pending"
        };

        // save user seleted event day in localstorage.
        this.store.SaveEventSelectionTemp( this.selected_event );

      //console.log(this.selected_event);
        if (!jQuery(clicked_obj).next('.remove_this_event').length) {
          //console.log('remove added');
            jQuery('<a href="#" class="remove_this_event" data-event-key="'+event_key+'" data-item-key="'+item_key+'" data-type="'+clicked_obj.data('type')+'">X</a>').insertAfter(clicked_obj);
        }
      //console.log(this.selected_event);
        // hide calender after event added.
        //jQuery('#calendar-modal-view-event').modal('hide');

        // show add event added successfully message after event added.

        // if event added then redirect to next step.
        //this.zone.run(() => {
            //localStorage.setItem('step', '4');
            //this.router.navigate(['/catégorie']);
        //});
        //console.log(this.selected_event);
        this.store.set('CurrentItemKey',this.selected_event.item_key);
        //console.log(this.selected_event);
    }

    remove_event( clicked_obj ){
        // console.log( clicked_obj );
        // console.log('remove_event');

        var get_all_selected_event = JSON.parse(this.store.GetEventSelection()) || [];
            get_all_selected_event = this.remove_event_if_already( jQuery(clicked_obj).data('event-key'), get_all_selected_event );

        var get_all_temp_selected_event = this.store.GetEventSelectionTemp() || [];
            get_all_temp_selected_event = this.remove_temp_event_if_already( jQuery(clicked_obj).data('event-key'), get_all_temp_selected_event );

        //console.log( get_all_selected_event );
        
        if( clicked_obj.data('type') == 'morning' || clicked_obj.data('type') == 'afternoon' ){

            if( get_all_selected_event != null && this.in_array_r( jQuery(clicked_obj).data('event-key'), get_all_selected_event ) ){

                //console.log('if');
                
                if( clicked_obj.data('type') == 'morning' ){
                    
                    jQuery(clicked_obj).parents('.fc-day').removeClass('sec_half');
                    jQuery(clicked_obj).parents('.fc-day').removeClass('full_day');
                    jQuery(clicked_obj).parents('.fc-day').removeClass('first_half');
                    jQuery(clicked_obj).parents('.fc-day').removeClass('first_second_section');
                    jQuery(clicked_obj).parents('.event_selection').find('.fullday').hide();
                    jQuery(clicked_obj).parents('.event_selection').find('.morning').show();
                    jQuery(clicked_obj).parents('.event_selection').find('.afternoon').show();

                    jQuery(clicked_obj).parents('.event_selection').find('.add_event_wrapper').each(function(){

                        if( ( jQuery(this).find('.remove_this_event').length > 0 && jQuery(this).find('.remove_this_event').data('type') == 'afternoon' ) || jQuery(this).find('.complete').hasClass('afternoon') ) {

                            jQuery(clicked_obj).parents('.fc-day').removeClass('first_half');
                            jQuery(clicked_obj).parents('.fc-day').removeClass('full_day');
                            jQuery(clicked_obj).parents('.fc-day').addClass('sec_half');

                        }

                    });
                }

                if( clicked_obj.data('type') == 'afternoon' ){

                    jQuery(clicked_obj).parents('.fc-day').removeClass('first_half');
                    jQuery(clicked_obj).parents('.fc-day').removeClass('full_day');       
                    jQuery(clicked_obj).parents('.fc-day').removeClass('sec_half');
                    jQuery(clicked_obj).parents('.fc-day').removeClass('first_second_section');
                    jQuery(clicked_obj).parents('.event_selection').find('.fullday').hide();
                    jQuery(clicked_obj).parents('.event_selection').find('.morning').show();
                    jQuery(clicked_obj).parents('.event_selection').find('.afternoon').show();

                    jQuery(clicked_obj).parents('.event_selection').find('.add_event_wrapper').each(function(){

                        if( ( jQuery(this).find('.remove_this_event').length > 0  && jQuery(this).find('.remove_this_event').data('type') == 'morning' ) || jQuery(this).find('.complete').hasClass('morning') ) {

                            jQuery(clicked_obj).parents('.fc-day').removeClass('sec_half');
                            jQuery(clicked_obj).parents('.fc-day').removeClass('full_day');
                            jQuery(clicked_obj).parents('.fc-day').addClass('first_half');

                        }
                        
                    });
                }

            }else{

                if( clicked_obj.data('type') == 'morning' ){

                    jQuery(clicked_obj).parents('.fc-day').removeClass('sec_half');
                    jQuery(clicked_obj).parents('.fc-day').removeClass('full_day');
                    jQuery(clicked_obj).parents('.fc-day').removeClass('first_half');
                    jQuery(clicked_obj).parents('.fc-day').removeClass('first_second_section');
 
                    if( clicked_obj.parent('.add_event_wrapper').next().find('.remove_this_event').length > 0){

                        jQuery(clicked_obj).parents('.event_selection').find('.fullday').hide();
                        jQuery(clicked_obj).parents('.event_selection').find('.afternoon').show();
                        jQuery(clicked_obj).parents('.fc-day').removeClass('first_half');
                        jQuery(clicked_obj).parents('.fc-day').removeClass('full_day');
                        jQuery(clicked_obj).parents('.fc-day').addClass('sec_half');

                    }else{
                        
                        jQuery(clicked_obj).parents('.fc-day').removeClass('sec_half');
                        jQuery(clicked_obj).parents('.fc-day').removeClass('full_day');
                        jQuery(clicked_obj).parents('.fc-day').removeClass('first_half');
                        jQuery(clicked_obj).parents('.event_selection').find('.fullday').show();
                        jQuery(clicked_obj).parents('.event_selection').find('.morning').show();
                        jQuery(clicked_obj).parents('.event_selection').find('.afternoon').show(); 

                    }
                }

                if( clicked_obj.data('type') == 'afternoon' ){

                    jQuery(clicked_obj).parents('.fc-day').removeClass('sec_half');
                    jQuery(clicked_obj).parents('.fc-day').removeClass('full_day');
                    jQuery(clicked_obj).parents('.fc-day').removeClass('first_half');
                    jQuery(clicked_obj).parents('.fc-day').removeClass('first_second_section');

                    if( clicked_obj.parent('.add_event_wrapper').prev().find('.remove_this_event').length > 0){

                        jQuery(clicked_obj).parents('.event_selection').find('.fullday').hide();
                        jQuery(clicked_obj).parents('.event_selection').find('.morning').show();
                        jQuery(clicked_obj).parents('.fc-day').removeClass('sec_half');
                        jQuery(clicked_obj).parents('.fc-day').removeClass('full_day');
                        jQuery(clicked_obj).parents('.fc-day').addClass('first_half');

                    }else{

                        jQuery(clicked_obj).parents('.fc-day').removeClass('sec_half');
                        jQuery(clicked_obj).parents('.fc-day').removeClass('full_day');
                        jQuery(clicked_obj).parents('.fc-day').removeClass('first_half');
                        jQuery(clicked_obj).parents('.event_selection').find('.fullday').show();
                        jQuery(clicked_obj).parents('.event_selection').find('.morning').show();
                        jQuery(clicked_obj).parents('.event_selection').find('.afternoon').show(); 

                    }
                }
                
            }

        }else{

            jQuery(clicked_obj).parents('.fc-day').removeClass('first_half');
            jQuery(clicked_obj).parents('.fc-day').removeClass('sec_half');
            jQuery(clicked_obj).parents('.fc-day').removeClass('full_day');
            jQuery(clicked_obj).parents('.fc-day').removeClass('first_second_section');
            jQuery(clicked_obj).parents('.event_selection').find('.fullday').show();
            jQuery(clicked_obj).parents('.event_selection').find('.morning').show();
            jQuery(clicked_obj).parents('.event_selection').find('.afternoon').show();

        }

        jQuery( clicked_obj ).prev().removeClass( 'selected' );
        //jQuery( '[data-date="'+jQuery( clicked_obj ).prev().data('date')+'"].fc-day-number' ).removeClass('date-highlight');

        //clicked_obj.prev().removeClass('complete_booked');

        if( jQuery( clicked_obj ).data('type') == 'morning' ){
            //console.log('morning');
            if( jQuery( clicked_obj ).parent().next().find('.remove_this_event').length > 0 ){
                //console.log(jQuery( clicked_obj ).parent().next().find('.remove_this_event').prev());
                this.add_morning_afternoon_event(jQuery( clicked_obj ).parent().next().find('.remove_this_event').prev());
            }
        }

        if( jQuery( clicked_obj ).data('type') == 'afternoon' ){
            if( jQuery( clicked_obj ).parent().prev().find('.remove_this_event').length > 0 ){
                this.add_morning_afternoon_event(jQuery( clicked_obj ).parent().prev().find('.remove_this_event').prev());
            }
        }

        jQuery(clicked_obj).remove();

        var CurrentItemKey = this.store.get('CurrentItemKey');

        // if(jQuery(clicked_obj).data('item-key') == CurrentItemKey){
        //     this.store.remove('CurrentItemKey');
        // }



    }


    add_morning_afternoon_event( clicked_obj ){

      console.log( 'add_morning_afternoon_event' );

        var roomsize       = jQuery('.rooms-boxes:checked').data('roomsize'),
            roomtype       = jQuery('.rooms-boxes:checked').data('roomtype'),
            roomname       = jQuery('.rooms-boxes:checked').data('roomname'),
            roomdesc       = jQuery('.rooms-boxes:checked').data('roomdesc'),
            roomimge       = jQuery('.rooms-boxes:checked').siblings('.img-wrap').find('img').attr('src'),
            event_key      = localStorage.getItem('service')+roomsize+roomtype+clicked_obj.data('date').replace(/-/g,''),
            item_key       = localStorage.getItem('service')+roomsize+roomtype+clicked_obj.data('type')+clicked_obj.data('date').replace(/-/g,''),
            CurrentItemKey = this.store.get('CurrentItemKey');

      
        this.store.setCurrentEventKey( event_key );
        this.store.setCurrentItemKey( item_key );

        /*var get_selected_event = JSON.parse(this.store.GetEventSelection()) || [];
        var get_selected_event_temp = this.store.GetEventSelectionTemp() || [];
        var price              = 0;*/
        
        /*// if user clicked in already choosed day then return from here.
        if( get_selected_event_temp != null && this.in_array_r( item_key, get_selected_event_temp ) ){
          //console.log('already there remove');
            return; //get_selected_event = this.remove_event_if_already( item_key, get_selected_event );
        }

        if( get_selected_event != null && this.in_array_r( item_key, get_selected_event ) ){
          //console.log('already there remove');
            return; //get_selected_event = this.remove_event_if_already( item_key, get_selected_event );
        }
*/

        var price = Number( localStorage.getItem('half_price') );

        // set this data in selected_event as obejct.
        this.selected_event = {
            "event_key": event_key,
            "item_key": item_key,
            "order_type": localStorage.getItem('service'),
            "room_id": roomtype,
            "room_size": roomsize,
            "room_name": roomname,
            "room_desc": roomdesc,
            "room_image": roomimge,
            "event_day_type": clicked_obj.data('type'),
            "event_start_date": clicked_obj.data('date'),
            "event_end_date": clicked_obj.data('date'),
            "price": price,
            "status": "pending"
        };
        //console.log(this.selected_event);
        // save user seleted event day in localstorage.
        this.store.SaveEventSelectionTemp( this.selected_event );

        if (!jQuery(clicked_obj).next('.remove_this_event').length) {
            jQuery('<a href="#" class="remove_this_event" data-event-key="'+event_key+'" data-item-key="'+item_key+'" data-type="'+clicked_obj.data('type')+'">X</a>').insertAfter(clicked_obj);
        }
      
        this.store.set('CurrentItemKey',this.selected_event.item_key);

        /*var CurrentItemKey = this.store.get('CurrentItemKey');
        var GetEventSelectionTemp = this.store.GetEventSelectionTemp();
        var get_selected_event = JSON.parse(this.store.GetEventSelection()) || [];
        this.store.SaveEventSelection( get_selected_event, GetEventSelectionTemp );
        this.store.remove('selected_events_temp');*/
    }

    saveToNext(){

        var CurrentItemKey = this.store.get('CurrentItemKey');
        var GetEventSelectionTemp = this.store.GetEventSelectionTemp();
        var check_edit_item = this.token.get( 'edit_item' ) || '';
        // console.log(GetEventSelectionTemp.length);
        // return;    
        console.log( check_edit_item );
        console.log( GetEventSelectionTemp.length );
        if( GetEventSelectionTemp.length < 1 && check_edit_item == '' ){
            jQuery('#noSelectedEvent').modal();
            return;
        }

        ////console.log(this.selected_event);
        var get_selected_event = JSON.parse(this.store.GetEventSelection()) || [];
        //console.log(get_selected_event);
        //console.log(this.selected_event); 
        // save user seleted event day in localstorage.
        this.store.SaveEventSelection( get_selected_event, GetEventSelectionTemp );

        this.store.remove('CurrentItemKey');
        this.store.remove('selected_events_temp');

        this.toastr.success("Ajouter un événement avec succès.");

        jQuery('#calendar-modal-view-event').modal('hide');

        var getMenuLocal = JSON.parse(this.token.get('menu'));
        if(getMenuLocal){
            this.token.remove('menu');
        }

        this.zone.run(() => {
            //console.log('zone calling');
            this.token.remove( 'edit_item' );
            localStorage.setItem('step', '4');
            this.router.navigate(['/catégorie']);
            //this.router.navigate(['/working-progress']);
        });
    }


    remove_event_if_already( event_key, get_selected_event ){
        
        for (var i = get_selected_event.length - 1; i >= 0; --i) {
            if (get_selected_event[i].event_key == event_key) {        
                get_selected_event.splice(i,1);
            }
        }
        
        if (get_selected_event.length <= 0) {
            this.store.RemoveEventSelection();
        }else{
            this.store.SaveAllEventSelection( get_selected_event );
        }

        return get_selected_event;
    }

    remove_temp_event_if_already( event_key, get_selected_event ){
        
        for (var i = get_selected_event.length - 1; i >= 0; --i) {
            if (get_selected_event[i].event_key == event_key) {        
                get_selected_event.splice(i,1);
            }
        }
        
        if (get_selected_event.length <= 0) {
            this.store.RemoveTempEventSelection();
        }else{
            this.store.SaveAllTempEventSelection( get_selected_event );
        }

        return get_selected_event;
    }

   /* remove_temp_event( event_key, get_selected_event ){
        
        for (var i = get_selected_event.length - 1; i >= 0; --i) {
            if (get_selected_event[i].event_key == event_key) {        
                get_selected_event.splice(i,1);
            }
        }
        
        if (get_selected_event.length <= 0) {
            this.store.RemoveTempEventSelection();
        }else{
            this.store.SaveAllTempEventSelection( get_selected_event );
        }

        return get_selected_event;
    }*/

    get_value_by_key( item_key, get_selected_event ){
        for (var i = get_selected_event.length - 1; i >= 0; --i) {
            if (get_selected_event[i].item_key == item_key) {
                return get_selected_event[i];
            }
        }
        return null;
    }

    get_closing_dates_holiday_data( date, closing_dates_holiday_data ){
        for (var i = closing_dates_holiday_data.length - 1; i >= 0; --i) {
            if (closing_dates_holiday_data[i].date == date) {
                return closing_dates_holiday_data[i];
            }
        }
        return null;
    }

    in_array_r(needle, haystack) {
        //console.log(haystack.length);
        var length = haystack.length;
        if( length > 0 ){
            for(var key in haystack) {
                if(haystack[key]['event_key'] == needle){
                    return true;
                }
                if(typeof haystack[key]=='object'){
                    if(this.in_array_r(needle, haystack[key])){
                        return true;
                    }
                } 
            }
        }
        return false;
    }

    is_item_key_exist(needle, haystack) {
        //console.log(haystack.length);
        var length = haystack.length;
        if( length > 0 ){
            for(var key in haystack) {
                if(haystack[key]['item_key'] == needle){
                    return true;
                }
                if(typeof haystack[key]=='object'){
                    if(this.is_item_key_exist(needle, haystack[key])){
                        return true;
                    }
                } 
            }
        }
        return false;
    }

    keyExists(key, search) {
        if (!search || (search.constructor !== Array && search.constructor !== Object)) {
            return false;
        }
        for (var i = 0; i < search.length; i++) {
            if (search[i] === key) {       
                return true;
            }
        }
        return key in search;
    }

    // SaveDataToLocalStorage(data){
    //     var a = [];
    //     // Parse the serialized data back into an aray of objects
    //     a = JSON.parse(localStorage.getItem('session')) || [];
    //     // Push the new data (whether it be an object or anything else) onto the array
    //     a.push(data);
    //     // Re-serialize the array back into a string and store it in localStorage
    //     localStorage.setItem('session', JSON.stringify(a));
    // }

    selectRoomSize( size, hour, half, full, tab_class ){
        let set_roomsize = this.store.getRoomSize();
        let set_tab_class = this.token.get( 'tab_class' );
        if( this.store.getRoomSize() != 'null' && set_tab_class == tab_class ){
            console.log('if');
            this.store.setRoomSize(null);
            this.store.setRoomType(null);
            $('.'+tab_class).removeClass('active');
            this.token.set( 'tab_class', tab_class );
            //this.roomSelectedSize = false;
        } else {
            console.log('else');
            this.store.setRoomSize(size);
            this.store.setHourPrice(hour);
            this.store.setHalfDayPrice(half);
            this.store.setDayPrice(full);
            this.token.set( 'tab_class', tab_class );
            //this.roomSelectedSize = true;
        }
    }

    isSelectedSize( size, type ){
        ////console.log(type+' '+this.store.getRoomSize());
        if(type == 'roomtype' && this.store.getRoomSize() == 'null'){
            ////console.log('if');
            return true;
        }
        ////console.log('isSelectedSize');
        return this.store.getRoomSize() == size;
    }

    selectEventType( type ){
        this.store.setEventType(type);
    }

    isSelectedType( type ){
        return this.store.getEventType() == type;
    }

    getMeetingRoomTab(){
        this.meetingRoom.getAllMeetingRoomTabs().subscribe(
            data => {
                this.getAllMeetingRoomTabs = data; 

                this.roomSize = this.getAllMeetingRoomTabs[0].lower_capacity+'/'+this.getAllMeetingRoomTabs[0].higher_capacity;
                this.hourPrice = this.getAllMeetingRoomTabs[0].hourly_price;
                this.halfDayPrice = this.getAllMeetingRoomTabs[0].half_day_price;
                this.perDayPrice = this.getAllMeetingRoomTabs[0].per_day_price;

                //this.store.setRoomSize(this.store.getRoomSize() || this.roomSize);
                this.store.setHourPrice(this.store.getHourPrice() || this.hourPrice);
                this.store.setHalfDayPrice(this.store.getHalfDayPrice() || this.halfDayPrice);
                this.store.setDayPrice(this.store.getDayPrice() || this.perDayPrice);

                ////console.log(this.roomSize);
            },
            error => {

            }
        );
    }

    getMeetingRoomImage(){
        this.meetingRoom.getMeetingRoomImages().subscribe(
            data => {
                this.getMeetingRoomImages = data

                var check_edit_item = this.token.get( 'edit_item' ) || '';
                var self = this;
                if( check_edit_item != '' ){
                    setTimeout(function(){
                        let elem = document.getElementById( 'meeting_room_'+check_edit_item );
                        let evt = new MouseEvent('click', {
                                bubbles: true,
                                cancelable: true,
                                view: window
                            });

                        elem.dispatchEvent(evt);
                        //self.token.remove( 'edit_item' );
                    }, 1000 );
                }

                /*for ( var i = 0; i < this.getMeetingRoomImages.length; i++ ) {
                    let desc = this.getMeetingRoomImages[i].description.substr( 1, 110 )+'...';
                    this.getMeetingRoomImages[i].description = desc;
                }*/
            },
            error => {

            }
        );
    }

    getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };

    isSelectedRoomType( roomId ){
        return this.store.getRoomType() == roomId;
    }

    selectRoomType(roomName, roomId, roomSize, hour, half, full ){

        this.store.setHourPrice(hour);
        this.store.setHalfDayPrice(half);
        this.store.setDayPrice(full);

        //this.store.setRoomSize( roomSize );
        this.store.setRoomType( roomId );
        //this.store.setRoomSize(roomSize);
        ////console.log( roomId );
        this.getfullCalendarData();
        this.selectedRoomName = roomName;
        jQuery('#calendar-modal-view-event').modal();
    }

    getfullCalendarData(){
        var icon = "X";
        //jQuery( this.calendar ).on( 'eventRender',function( event, element, view ){
            //var compiled = $compile('<div your-directive>Test</div>')($scope);
            //element.find('.fc-content').replaceWith('<div your-directive>Test</div>');
        //})

        jQuery( this.calendar ).on( 'dayClick',function( date, jsEvent, view, element ){
            ////console.log(date);
        })
    }

    fetchEvent(){
        var self = this;
        this.orderType['order_type'] = this.token.get('service');
        
        var allEvents = [];
        this.spaceservice.fetchEvent(this.orderType).subscribe(
            data => {
                
                if(data['status'] == 'true'){
                    for (let i = 0; i < data['events'].length; i++) {
                        
                        var title = '';
                        
                        if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 1){
                            title = '30 Jours'
                        }else if(data['events'][i].order_type == 2 && data['events'][i].length_of_booking == 2){
                            title = 'Half Day';
                        }else if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 2 || data['events'][i].order_type == 2 && data['events'][i].length_of_booking == 3){
                            title = 'La journée';
                        }else if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 3){
                            title = 'Le mois';
                        }else if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 4){
                            title = '10 Jours';
                        }else if(data['events'][i].order_type == 2 && data['events'][i].length_of_booking == 1){
                            title = 'Hourly';
                        }

                        if(data['events'][i].event_start_date != null && data['events'][i].event_end_date != null){
                            var Fetchedevent = new Object();
                            Fetchedevent = {
                                title: title,
                                start: data['events'][i].event_start_date,
                                end: data['events'][i].event_end_date,
                                allDay: false,
                                editable:true,
                                color: 'yellow',
                                className: 'fetchEvent',
                                order_type: data['events'][i].order_type,
                                length_of_booking: data['events'][i].length_of_booking,
                                time_of_day: data['events'][i].time_of_day,
                            };
                            this.eventArray.push( Fetchedevent );
                        }
                    }
                }
                //self.store.RemoveEventSelection();
                var allEvents = JSON.parse(self.store.GetEventSelection()) || [];
                
                var allEvents = allEvents.filter(function (el) {
                  return el != null;
                });

                if (data['events'] !== undefined && Object.keys(data['events']).length > 0) {
                    
                    for (let i = 0; i < data['events'].length; i++) {
                        if( allEvents != null && !self.is_item_key_exist( data['events'][i].item_key ,allEvents )){
                            allEvents.push(data['events'][i]);
                        }
                    }

                    var totalLength = data['events'].length + allEvents.length;
                    var k = 0;
                    
                    for (let i = data['events'].length; i < totalLength; i++) {
                        if( allEvents != null && !self.is_item_key_exist( allEvents[k].item_key ,allEvents )){
                            allEvents.push(allEvents[k]);
                            k++;
                        }
                    }
                    
                    this.store.SaveAllEventSelection( allEvents );

                }
                    
                this.token.set('fetchEvent', JSON.stringify(this.eventArray));

                var event_date = JSON.parse(this.token.get('eventData'));
                var title = this.token.get('event_type');
                if(event_date){
                    for( let z=0; z < event_date.length; z++ ){
                        this.isSelectedEvent.push({
                            title: title,
                            start: event_date[z].start,
                            end: event_date[z].end,
                            editable: false,
                            allDay: true,
                            color: '#90EE90'
                        });
                    }
                }
            },
            error => {
            }
        );
    }

    getClosingDates(){
        var self = this;
        this.spaceservice.getClosingDates().subscribe(
            data => {
                if(data['status'] == 'true'){

                    var getClosingDates = {
                        "holidays": data['holidays'],
                        "week_days": data['week_days']
                    }

                    this.token.set('getClosingDates', JSON.stringify(getClosingDates));    
                    var closingData = this.token.get('getClosingDates');
                }
            },
            error => {
              //console.log(error);
            }
        );
    }

}
