import { Component, OnInit, NgZone } from '@angular/core';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../service/authentication.service';
import { UserService } from '../../service/user.service';
import { StoreService } from '../../service/store.service';
import { MeetingRoomService } from '../../service/meeting-room.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TokenService } from '../../service/token.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

	categories: any = [];
	contractors: any = [];
	contractor_id: any = null;
	selectedMenu: any = [];
	IMG_URL: any;
	currentCategory: any;
	catName:any;
	catImg:any;

	constructor(
		private router: Router,
		private userservice: UserService,
		private token: TokenService,
		private store: StoreService,
		private auth: AuthenticationService,
		private room: MeetingRoomService,
		private cookie: CookieService,
		private toastr: ToastrService,
		private zone: NgZone
	) { 
		this.IMG_URL = environment.STORAGE_IMG_URL;
		this.userservice.loadScript();
		this.getCategory();

		if(this.token.get('editMenu')){
			var menu = JSON.parse(this.token.get('editMenu'));
			this.selectedMenu = JSON.parse(this.token.get('menu'));
			var id = menu.category_id;
			var name = this.token.get('contract');
			this.getContractors( id, name );
			this.isSelectedMenu(this.selectedMenu);
		}

		// if(this.token.get('walletDiscount') && this.token.get('editMainOrder') != 'true'){
  //           this.router.navigate(['/récapitulatif-de-la-commande']);
  //       }

		// if(this.token.get('walletDiscount') && this.token.get('editMenuOrder') != 'true'){
		// 	this.router.navigate(['/récapitulatif-de-la-commande']);
		// }
	}

	ngOnInit() { 
		this.selectedMenu = this.store.getMenu();
	}

	transform( text ) {
      	//return text.split(/\n/g).join('<br/>');
      	return text.replace(/\n/g, '<br/>');
   	}

	getContractors( id, name, img = '' ){
		
		this.catName = name;
		this.catImg = img;

		this.store.setContract(name);
		this.currentCategory = id;
		this.room.getContractors( id ).subscribe(
            data => {
  				this.contractors = data
				$('html').addClass('details-wraper-open');
		    	$('.details-wraper').addClass('open');
            },
            error => {
                this.toastr.error(error.error.error);
            }
        );
	}

	getCategory(){
		this.room.allCategory().subscribe(
            data => {
  				this.categories = data
            },
            error => {
                this.toastr.error(error.error.error);
            }
        );
	}

	menuChange(event, contractor, menu, qtychnage = false){
		//console.log('menuChange');
	  	var checkbox = $(event.target),
	  	  	menuQty = parseInt( ( checkbox.parents('.item-qty').find('.menu-qty').val() != '' ) ? checkbox.parents('.item-qty').find('.menu-qty').val() : 0);
	  	  	//console.log(menuQty);

	  	  	if(event.target.checked){
	  	  		menuQty = 1;
	  	  		var	qty_input 	  = checkbox.parents('.item-qty').find('input[type="number"]');
	  	  		var	price         = menuQty * parseFloat( qty_input.data('price') );
	  	  		checkbox.parents('.item-qty').find('.price').text(price.toFixed(2).replace(".", ",")+ ' € HT');
	  	  	}

	  	let exist = false;

	  	// if(this.contractor_id != contractor.id){
	  	// 	this.selectedMenu = [];
	  	// }

	  	this.contractor_id = contractor.id;

	  	if(( event.target.checked || qtychnage ) && menuQty > 0){

		  	this.selectedMenu.forEach((item, index) => {
		  		if(item.id == menu.id && item.category_id == this.currentCategory){
		  			exist = true
		  		}
		  	})

		  	if(!exist){
		  		//console.log(menu);
		  		menu.qty = menuQty;
		  		menu.category_id = this.currentCategory;
		  		menu.category_name = this.catName;
		  		// console.log('menu.id ==>' + menu.id);
		  		// console.log('menu.contractor_id ==> ' + menu.contractor_id);
		  		// console.log('menu.category_id ==> ' + menu.category_id);
		  		menu.menu_item_id = menu.id+'-'+menu.contractor_id+'-'+menu.category_id;
		  		this.selectedMenu.push( menu );
		  		//console.log(this.selectedMenu);
		  	}
	  	} else {
		  	this.selectedMenu.forEach((item, index) => {
		  		if(item.id == menu.id && item.category_id == this.currentCategory){
		  			this.selectedMenu.splice( index, 1 )
		  		}
		  	});
		  	//console.log('else');
		  	menuQty = 0;
  	  		var	qty_input 	  = checkbox.parents('.item-qty').find('input[type="number"]');
  	  		var	price         = menuQty * parseFloat( qty_input.data('price') );
  	  		checkbox.parents('.item-qty').find('.price').text(price.toFixed(2).replace(".", ",")+ ' € HT');
	  	}

	  	// this.store.setMenu( this.selectedMenu )

	  	//this.token.remove('editMenu');
		// var getMenuLocal = JSON.parse(this.token.get('menu'));
		
		// if(getMenuLocal){
		// 	getMenuLocal.forEach((item, itemIndex) => {
		// 		if(item.category_id == this.currentCategory){
		// 			getMenuLocal.splice( itemIndex, 1 )
		// 		}
		//   	})

		//   	this.selectedMenu.forEach((menu, menuIndex) => {
		//   		if(menu.category_id == this.currentCategory){
		// 			getMenuLocal.push( menu )
		// 		}	
		//   	})
		// }

		// var selected_events = JSON.parse(this.store.getAllEventSelection());
		// var allselectedMenu = this.selectedMenu;
		// var event_key 		= this.store.getCurrentEventKey();
		// var item_key 		= this.store.getCurrentItemKey();

		// Object.keys(selected_events).forEach(function (key) {
		// 	if (selected_events[key]['item_key'] == item_key) {
		//    		selected_events[key]['menu'] = allselectedMenu;
		//    	}
		// });

		this.store.setMenu( this.selectedMenu );
		//this.store.SaveAllEventSelection( selected_events );

	}

	test(){
		return "ddssd";
	}

	isSelectedMenu(menu){
		//console.log('isSelectedMenu');
		let checked = false;
		this.selectedMenu.forEach((item, index) => {
	  		if(item.id == menu.id && this.currentCategory == item.category_id){
	  			checked = true;
	  		}
	  	})
	  	return checked;
	}

	menuQtyChange(qty, menu, contractor_id){
		//console.log('menuQtyChange');
	  	this.selectedMenu.forEach((item, index) => {
	  		if(item.id == menu.id && item.category_id == this.currentCategory && item.contractor_id == contractor_id){
	  			item.qty = parseInt( qty )
	  		}
	  	})
	  	this.store.setMenu( this.selectedMenu );
	}

	getMenuPrice( price, menu, contractor_id ){
		//console.log('getQtyValue');
		let qty = "";
		price = 0;
		this.selectedMenu.forEach((item, index) => {
	  		if(item.id == menu.id && item.category_id == this.currentCategory && item.contractor_id == contractor_id){
	  			price = parseFloat( item.price ) * item.qty;
	  		}
	  	})
	  	return price;
	}

	getQtyValue( menu, contractor_id ){
		//console.log('getQtyValue');
		let qty = "";
		this.selectedMenu.forEach((item, index) => {
	  		if(item.id == menu.id && item.category_id == this.currentCategory && item.contractor_id == contractor_id){
	  			qty = item.qty;
	  		}
	  	})
	  	return qty;
	}

	saveContract(){

		$('.details-wraper').removeClass('open');
		$('html').removeClass('details-wraper-open');

		var getMenuLocal = JSON.parse(this.token.get('menu'));
		
		if(getMenuLocal){
			getMenuLocal.forEach((item, itemIndex) => {
				if(item.category_id == this.currentCategory){
					getMenuLocal.splice( itemIndex, 1 )
				}
		  	})

		  	this.selectedMenu.forEach((menu, menuIndex) => {
		  		if(menu.category_id == this.currentCategory){
					getMenuLocal.push( menu )
				}	
		  	})
		}

		var selected_events = JSON.parse(this.store.getAllEventSelection());
		var allselectedMenu = this.selectedMenu;
		var event_key 		= this.store.getCurrentEventKey();
		var item_key 		= this.store.getCurrentItemKey();

		Object.keys(selected_events).forEach(function (key) {
			if (selected_events[key]['item_key'] == item_key) {
		   		selected_events[key]['menu'] = allselectedMenu;
		   	}
		});

		this.store.setMenu( this.selectedMenu );
		this.store.SaveAllEventSelection( selected_events );

	}

	isSelectedCategory(itemId){
		//console.log('isSelectedCategory');
		let isSelected = false;
		var getMenuLocal = JSON.parse(this.token.get('menu'));
		if(getMenuLocal){
			getMenuLocal.forEach((item, index) => {
		  		if(item.category_id == itemId){
		  			isSelected = true;
		  		}
		  	})
		}
		return isSelected;
	}

	minusQty( event, menu, contractor_id, contractor){
		var minus_qty 	  = $(event.target);
		var	food_menu_box = minus_qty.parents('.item-qty');
		var	qty_input 	  = food_menu_box.find('input[type="number"]');
		var	qty 		  = parseInt( ( qty_input.val() != '' ) ? qty_input.val() : 0  );
			qty 		  = qty - 1;
		var	price         = qty * parseFloat( qty_input.data('price') );
		console.log(qty);
		console.log(price);
			if(qty >= 0){
				qty_input.val(qty);
				if(qty >= 0){
					food_menu_box.find('.price').text(price.toFixed(2).replace(".", ",")+ ' € HT');
				}
				this.menuQtyChange( qty, menu, contractor_id );
			}
			this.menuChange(event, contractor, menu,true);
	}

	plusQty( event, menu, contractor_id, contractor ){
		
		var plus_qty 	  = $(event.target);
		var	food_menu_box = plus_qty.parents('.item-qty');
		var	qty_input 	  = food_menu_box.find('input[type="number"]');
		var val 		  = ( qty_input.val() ) ? qty_input.val() : 0;
		var	qty 		  = parseInt( val );
			qty 		  = qty + 1;
		var	price         = qty * parseFloat( qty_input.data('price') );

		qty_input.val(qty);
		this.menuQtyChange( qty, menu, contractor_id );
		this.menuChange(event, contractor, menu, true);
		food_menu_box.find('.price').text(price.toFixed(2).replace(".", ",")+ ' € HT' );
	}

	removeAllData(){
		console.log('remove cart data');
		this.token.remove('menu');
		this.token.remove('nomade_service');
		this.token.remove('eventData');
		this.token.remove('service');
		this.token.remove('event_type');
		this.token.remove('room_size');
		this.token.remove('full_price');
		this.token.remove('hour_price');
		this.token.remove('half_price');
		this.token.remove('menuPrice');
		this.token.remove('totalPrice');
		this.token.remove('totalSubmitPrice');
		this.token.remove('walletTotalDesc');
		this.token.remove('service_cost');
		this.token.remove('service_type');
		this.token.remove('fetchEvent');
		this.token.remove('walletDiscount');
		this.token.remove('price');
		this.token.remove('walletUpdatePrice');
		this.token.remove('contract');
		this.token.remove('amountType');
		this.token.remove('percentage');
		this.token.remove('couponDiscount');
		this.token.remove('couponName');
		this.token.remove('taxAmount');
		this.token.remove('selected_events');
		this.token.remove('room_type');
		this.token.remove('item_key');
		this.token.remove('event_key');
		this.token.remove('CurrentItemKey');
		this.token.remove('selected_events_temp');
		this.cookie.delete('service');
		this.cookie.delete('space');
		this.cookie.delete('eventData');
		this.cookie.delete('totalPrice');

		this.router.navigate(['/accueil']);
	}

	backToEdit(){
		console.log( 'back to edit' );
		var eventData = JSON.parse( this.token.get( 'selected_events' ) ) || [];
		if( eventData.length > 1 ){
			console.log( eventData );
			var last_edit_item = eventData[ eventData.length - 1 ];
			console.log( last_edit_item );
			var last_edit_room = last_edit_item['room_id'];
			console.log( last_edit_room );
			this.token.set( 'edit_item', last_edit_room );
			this.router.navigate(['/salle-de-réunion']);
		}
	}
}	
