import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from '../service/authentication.service';
import { environment } from '../../environments/environment';
import { TokenService } from '../service/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

	userDetail: any;
	baseURL: any;
	storageURL: any;

  	constructor(
  		private auth: AuthenticationService,
		private router: Router,
		private toastr: ToastrService,
		private token: TokenService,
  	) { 
	  	var url = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);
	  	$(document).ready(function() {
	  		$('.acc-option li').removeClass('active');
	  		$('[href$="'+url+'"]').parent().addClass("active");
	  	});
	  	this.baseURL = environment.IMG_URL;
		this.storageURL = environment.STORAGE_IMG_URL;
	  	this.userDetail = this.token.userDetail('currentUser');
  	}

  	ngOnInit() {
  	}

  	Logout(){
		this.auth.logout();
		this.toastr.error("Déconnexion utilisateur réussie.");
        this.router.navigate(['/login']);
	}
}
