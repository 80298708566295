import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class MeetingRoomService {

	baseUrl: any;

	constructor(
		private http: HttpClient
	) { 
		this.baseUrl = environment.APP_URL;
	}

	allCategory(){
		return this.http.get(`${this.baseUrl}/auth/category`);
	}

	getContractors(id){
		return this.http.get(`${this.baseUrl}/auth/category/${id}`);	
	}

	getAllMeetingRoomTabs(){
		return this.http.get(`${this.baseUrl}/getAllMeetingRoomTabs`);	
	}

	getMeetingRoomImages(){
		return this.http.get(`${this.baseUrl}/getMeetingRoomImages`);	
	}
}
