import { Injectable, EventEmitter } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TokenService } from './token.service';
import { Observable} from 'rxjs';
import { Location } from '@angular/common';


@Injectable({
	providedIn: 'root'
})
export class UserService {

	baseUrl: any;
	step:any;

	constructor(
		private http:HttpClient,
		private location: Location,
		private token: TokenService
	) { 
		this.baseUrl = environment.APP_URL;
	}

	loadScript() {        
		var isFound = false;
		var scripts = document.getElementsByTagName("script")
		for (var i = 0; i < scripts.length; ++i) {
			if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
				isFound = true;
			}
		}

		if (!isFound) {
			var dynamicScripts = [
									"https://unpkg.com/masonry-layout@4/dist/masonry.pkgd.min.js",
    								"https://unpkg.com/imagesloaded@4/imagesloaded.pkgd.js",
    								"https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.js",
									"assets/js/script.js", 
									"assets/js/wow.js", 
									"assets/js/main.js",
									"assets/js/moment.min.js",
									"assets/js/fullcalendar.min.js",
									"assets/js/calendar.js",
								];

			for (var i = 0; i < dynamicScripts.length; i++) {
				let node = document.createElement('script');
				node.src = dynamicScripts [i];
				document.getElementsByTagName('head')[0].appendChild(node);
			}
		}
	}

	allCountries(){
		return this.http.get(`${this.baseUrl}/getCountry`);
	}

	back(){
		this.step = this.token.get('step');
		this.token.set('step', this.step - 1);
  		this.location.back();
  	}

  	userDetailUpdate(data){
  		return this.http.post(`${this.baseUrl}/auth/updateUserDetail`, data);
  	}

  	changePassword(data){
  		return this.http.post(`${this.baseUrl}/auth/changePassword`, data);
  	}

  	getOrder(userId){
  		return this.http.post(`${this.baseUrl}/auth/getAllOrder`, userId);
  	}

    getEditOrder( orderId ){
      return this.http.post(`${this.baseUrl}/auth/getEditOrder`, orderId);
    }

  	getThankyoumsg( orderType ){
  		return this.http.post(`${this.baseUrl}/auth/getOrderThankyouMsg`, orderType);
  	}

  	getRefundOrder(userId){
  		return this.http.post(`${this.baseUrl}/auth/getAllRefundOrder`, userId);
  	}

  	genratePDF(orderData){
  		//const options = {headers:HttpHeaders, orderData, responseType: 'text' as 'text'};

  // 		let headers = new Headers({ 'Content-Type': 'application/json', 'MyApp-Application' : 'AppName', 'Accept': 'application/pdf' });
		// let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });

  		return this.http.post(`${this.baseUrl}/auth/createPDF`, orderData,{
		    responseType: 'json',
		    headers: new HttpHeaders().append("Content-Type", "application/json").append("Accept", "application/pdf")
		});

		//return this.http.post(`${this.baseUrl}/auth/createPDF`, orderData);
  	}

    genrateDocument(documentsData){
      //const options = {headers:HttpHeaders, orderData, responseType: 'text' as 'text'};

  //     let headers = new Headers({ 'Content-Type': 'application/json', 'MyApp-Application' : 'AppName', 'Accept': 'application/pdf' });
    // let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });

      return this.http.post(`${this.baseUrl}/auth/genrateDocument`, documentsData,{
        responseType: 'json',
        headers: new HttpHeaders().append("Content-Type", "application/json").append("Accept", "application/pdf")
    });

    //return this.http.post(`${this.baseUrl}/auth/createPDF`, orderData);
    }

  	// exportPdf(orderIds){
  	// 	return this.http.post(`${this.baseUrl}/auth/exportPdf`, orderIds);
  	// }

  	saveRemainDays( data ){
  		return this.http.post( `${this.baseUrl}/auth/saveRemainDays`, data );
  	}
  	
  	walletBalance(userId){
  		return this.http.post(`${this.baseUrl}/auth/walletBalance`, userId);
  	}

  	updateWalletBalance(userId){
  		return this.http.post(`${this.baseUrl}/auth/updateWalletBalance`, userId);
  	}

  	applyCoupon(data){
  		return this.http.post(`${this.baseUrl}/auth/applyCoupon`, data);
  	}

  	refundOrder(refundOrderData){
  		return this.http.post(`${this.baseUrl}/auth/refundOrder`, refundOrderData);
  	}

  	getNomadeOrderImage(){
  		return this.http.get(`${this.baseUrl}/auth/getNomadeOrderImage`);
  	}

  	getMeetingRoomOrderImage(){
  		return this.http.get(`${this.baseUrl}/auth/getMeetingRoomOrderImage`);
  	}

    getDocumentType(){
      return this.http.get(`${this.baseUrl}/auth/getDocumentType`);
    }

    getUserDocuments(){
      return this.http.get(`${this.baseUrl}/auth/getUserDocuments`);
    }

    fetchRecurringOrderValue( orderData ){
      return this.http.post(`${this.baseUrl}/auth/fetchRecurringOrderValue`, orderData);
    }

    saveUserDocumnet( formData ){
      return this.http.post(`${this.baseUrl}/auth/document`, formData);
    }

    repalceDocument( formData ){
      return this.http.post(`${this.baseUrl}/auth/repalceDocument`, formData);
    }
}
