import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { TokenService } from '../service/token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PaymentService {

	baseUrl: any;
	paymentInit: string = " https://payment-webinit-mercanet.test.sips-atos.com/paymentInit ";

	constructor(
		private http: HttpClient, private token: TokenService
	) { 
		this.baseUrl = environment.APP_URL;
	}

	Payment(data){
		return this.http.post(`${this.baseUrl}/auth/payment`, data);
	}
	
	RecurringPayment(data){
		return this.http.post(`${this.baseUrl}/auth/recurringPayment`, data);
	}

	paymentGateway(data){
		return this.http.post(`${this.baseUrl}/auth/paymentGateway`, data);
	}
	
	recurringPaymentGateway(data){
		return this.http.post(`${this.baseUrl}/auth/recurringpaymentGateway`, data);
	}

	// postPayment(data){
	// 	// const headers = new HttpHeaders({ 'Accept': 'application/json', 'Access-Control-Allow-Origin': '*'}); 
	// 	// const headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
	// 	// headers = headers.append('Access-Control-Allow-Origin', '*');
	// 	// headers = headers.append('Accept', 'application/json');
	// 	let header = new HttpHeaders();
	// 	header = header.append("Access-Control-Allow-Origin","*");
	// 	header = header.append("Content-Type","application/json");
	// 	console.log(header);
	// 	return this.http.post(this.paymentInit, data);
	// }
}
