import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './page/home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { RegisterComponent } from './auth/register/register.component';
import { AuthGuard } from './guard/auth.guard';
import { ThirdServiceComponent } from './page/third-service/third-service.component';
import { FourServiceComponent } from './page/four-service/four-service.component';
import { InvoicesAndCreditComponent } from './page/invoices-and-credit/invoices-and-credit.component';
import { MyAccountComponent } from './page/my-account/my-account.component';
import { MyAccountDocuments } from './page/my-document/my-document.component';
import { PersonalInfoComponent } from './page/personal-info/personal-info.component';
import { ConnectionAndSecurityComponent } from './page/connection-and-security/connection-and-security.component';
import { ThanksComponent } from './page/thanks/thanks.component';
import { CalendarComponent } from './page/calendar/calendar.component';
import { SpaceComponent } from './page/space/space.component';
import { CartComponent } from './page/cart/cart.component';
import { OrderSummaryComponent } from './page/order-summary/order-summary.component';
import { OrderComponent } from './page/order/order.component';
import { RecurringOrderComponent } from './page/recurring-order/recurring-order.component';
import { PaymentComponent } from './page/payment/payment.component';
import { MeetingRoomComponent } from './page/meeting-room/meeting-room.component';
import { CategoryComponent } from './page/category/category.component';
import { ServiceComponent } from './page/nomade-service/nomade-service.component';
import { WorkinProgress } from './page/workin-progress/workin-progress.component';
import { ContacterComponent } from './page/contact/contact.component';
import { DomiciliationComponent } from './page/domiciliation/domiciliation.component';
import { ThankYouComponent } from './page/thank-you/thank-you.component';

const routes: Routes = [
	{ 
		path: 'accueil', // home
		component: HomeComponent
	}, 
	{ 
		path: 'plateau', // tray
		component: ThirdServiceComponent
	},
	{ 
		path: 'un-événement', // event
		component: FourServiceComponent
	},
	{ 
		path: 'domiciliation', // event
		component: DomiciliationComponent
	},
	{ 
		path: 'espace', // space
		component: SpaceComponent
	},
	{ 
		path: 'espace/calendrier/:time', // space/calendar/:time
		component: CalendarComponent
	},
	{ 
		path: 'salle-de-réunion', // meeting-room 
		component: MeetingRoomComponent
	},
	{ 
		path: 'salle-de-réunion/calendrier', // meeting-room/calendar
		component: CalendarComponent
	},
	{ 
		path: 'catégorie', // category 
		component: CategoryComponent
	},
	{ 
		path: 'un-service', // nomade-service 
		component: ServiceComponent
	},

	{
		path: 'chariot',
		component: CartComponent,
	},

	{ 
		path: 'récapitulatif-de-la-commande', // order-summary 
		component: OrderSummaryComponent,
		canActivate: [AuthGuard]
	},
	{ 
		path: 'paiement-du-service', // Recurring Order Payment 
		component: RecurringOrderComponent,
		canActivate: [AuthGuard] 
	},
	{ 
		path: 'commande', // order 
		component: OrderComponent,
		canActivate: [AuthGuard] 
	},
	{ 
		path: 'paiement', // payment 
		component: PaymentComponent,
		canActivate: [AuthGuard] 
	},
	{ 
		path: 'factures-et-crédit', // invoices-and-credit 
		component: InvoicesAndCreditComponent,
		canActivate: [AuthGuard] 
	}, 
	{ 
		path: 'mon-compte', // my-account
		component: MyAccountComponent, 
		canActivate: [AuthGuard] 
	},
	{ 
		path: 'mes-documents', // my-account
		component: MyAccountDocuments, 
		canActivate: [AuthGuard] 
	}, 
	{ 
		path: 'informations-personnelles', // personal-info
		component: PersonalInfoComponent, 
		canActivate: [AuthGuard] 
	}, 
	{ 
		path: 'connexion-et-sécurité', // connection-and-security
		component: ConnectionAndSecurityComponent, 
		canActivate: [AuthGuard] 
	}, 
	{ 
		path: 'merci', // thanks
		component: ThanksComponent
	},
	{ 
		path: 'merci_pour_demande', // thank you
		component: ThankYouComponent
	}, 
	{ 
		path: 'calendrier', // calendar
		component: CalendarComponent
	},
	{ 
		path: 'working-progress', // working progress
		component: WorkinProgress
	},
	{ 
		path: 'contacter', // contact
		component: ContacterComponent
	}, 
    { path: 'connexion', component: LoginComponent }, // login
    { path: 's’inscrire-maintenant', component: RegisterComponent }, // register
    { path: 'mot-de-passe-oublié', component: ForgotPasswordComponent }, // forgot password
    { path: 'réinitialiser-le-mot-de-passe', component: ResetPasswordComponent }, // forgot password

    // otherwise redirect to home
    { path: '', redirectTo: '/accueil', pathMatch: 'full' },
    { path: '**', redirectTo: 'accueil' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
