import { Component, Input, OnInit } from '@angular/core';

import { AuthenticationService } from '../../service/authentication.service';
import { TokenService } from '../../service/token.service';
import { APP_BASE_HREF, Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../service/user.service';
import { StoreService } from '../../service/store.service';
import { CookieService } from 'ngx-cookie-service';
import { PaymentService } from '../../service/payment.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
declare var jQuery: any;
@Component({
  selector: 'app-back-next',
  templateUrl: './back-next.component.html',
  styleUrls: ['./back-next.component.css']
})
export class BackNextComponent implements OnInit {

	@Input() serviceNo: string;
	@Input() time = Array();
	paymentURL: string = "";
	//paymentURL: string = "https://payment-webinit.mercanet.bnpparibas.net/paymentInit";
	allData:any = {};
	paymentForm: FormGroup;
	prevDisable: any;
	walletData: any = {};
	orderDetail: any;
	checkPage: any;
	editOrder: any;
	internal_reference: any;
	step:any;


	constructor(
		private router: Router,
		private location: Location,
		public userserivce: UserService,
		private auth: AuthenticationService,
		private store: StoreService,
		private token: TokenService,
		private cookie: CookieService,
		private payment: PaymentService,
		private fb: FormBuilder,
		private toastr: ToastrService,
	) { 
        $(document).ready(function() {
		var url = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);
		console.log( url );
        	if(url == 'accueil'){
            	$('.home .arrow.back').addClass('hide');
            	$('.home .arrow.next').addClass('hide');
            	$('.step').addClass('hide');
            } else if( url == 'espace' ){
            	$('.step .arrow.next').addClass('hide');
            	$('.home').addClass('hide');
        	} else if( url == 'salle-de-r%C3%A9union' ){
        		$('.step .arrow.next').addClass('hide');
            	$('.home').addClass('hide');
            } else if( url == 'cat%C3%A9gorie' ){
            	//$('.step .arrow.next').addClass('hide');
            	$('.home').addClass('hide');
        	} else {
            	$('.arrow.back').removeClass('hide');
            	$('.step').removeClass('hide');
            	$('.home').addClass('hide');
        	}
			
        });
	}

	ngOnInit() {
		this.paymentForm = this.fb.group({
			email: ['', Validators.required],
			cardNumber: ['', Validators.required],
			expiredDate: ['', Validators.required],
			cvv: ['', Validators.required]
		})

		this.checkPage = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);
		console.log(this.checkPage);
		// if(this.token.get('totalPrice') != '0' && checkPage == "r%C3%A9capitulatif-de-la-commande"){
		// 	this.paymentGateWay();
		// }
		//console.log(document.referrer);
		this.token.set('previousUrl', this.checkPage);
		this.editOrder = this.token.get('editOrder');

	}

	back(){
		this.step = this.token.get('step');
		this.token.set('step', this.step - 1);
  		this.location.back();
  	}

	selectService(serviceno){
		// this.token.set('step', 1);
		if(typeof serviceno === "undefined"){
			if(this.token.get('service')){
				this.token.set('service', this.token.get('service'));
				serviceno = this.token.get('service')

			}else{
				console.log('enter true');
				jQuery('#serviceError').modal()

			}
		}else{
			console.log('enter false');
			this.store.setSelectedService(serviceno);
			this.token.set('service', serviceno);
		}

		localStorage.setItem('step', '1');

		if(serviceno == 1){
			this.router.navigate(['/espace']);
		}
		if(serviceno == 2){
			this.token.remove('event_type');
			this.router.navigate(['/salle-de-réunion']);
		}
		if(serviceno == 3){
			this.router.navigate(['/plateau']);
		}
		if(serviceno == 4){
			this.router.navigate(['/un-événement']);
		}
	}

	next(){
		var next = window.location.href.split('/');
		console.log(next);

		if(next.length == 4 && next[3] == 'espace'){

			this.token.set('step', 2);
			if(this.token.get('event_type')){
				this.store.setServiceType( this.token.get('event_type') )
				this.token.remove('eventData');
				this.cookie.set('space', JSON.stringify(this.time));
				this.router.navigate(['/espace/calendrier', this.token.get('event_type')]);
			}else{
				jQuery('#serviceError').modal();
			}
		}

		if(next[3] == 'espace' && next[4] == 'calendrier'){
			
			if(JSON.parse(this.token.get('eventData')) != null && JSON.parse(this.token.get('eventData')).length >= 1){
				if(this.token.get('event_type') == 'Dix jours'){
					if(JSON.parse(this.token.get('eventData')).length > 10){
						jQuery('#tenDaySelectError').modal();
					}else{
						this.router.navigate(['/connexion']);
					}	
				}else if (this.token.get('event_type') == '30 Jours') {
					if(JSON.parse(this.token.get('eventData')).length > 30){
						jQuery('#thirtyDaySelectError').modal();
					}else{
						this.router.navigate(['/connexion']);
					}
				}else{
					this.router.navigate(['/connexion']);
				}
			}else{
				jQuery('#selectAnyEventError').modal();
				this.router.navigate(['/espace/calendrier', this.token.get('event_type')]);
			}
		}

		if(next[3] == 'espace' && next[4] == 'calendrier' && this.auth.isLoggedIn()){
			
			this.token.set('step', 3);
			if(JSON.parse(this.token.get('eventData')) != null && JSON.parse(this.token.get('eventData')).length >= 1){
				if(this.token.get('event_type') == 'Dix jours' && JSON.parse(this.token.get('eventData')).length > 10){
						jQuery('#tenDaySelectError').modal();
						this.token.remove('editMainOrder');
						this.router.navigate(['/espace/calendrier', this.token.get('event_type')]);
				} else if (this.token.get('event_type') == '30 Jours' && JSON.parse(this.token.get('eventData')).length > 30) {
						this.token.remove('editMainOrder');
						jQuery('#thirtyDaySelectError').modal();
						this.router.navigate(['/espace/calendrier', this.token.get('event_type')]);
				} else if(JSON.parse(this.token.get('eventData')).length < 1){
						this.token.remove('editMainOrder');
						jQuery('#selectAnyEventError').modal();
						this.router.navigate(['/espace/calendrier', this.token.get('event_type')]);
				} else {
						this.token.remove('editMainOrder');
					this.router.navigate(['/récapitulatif-de-la-commande']);
				}
			} else {

				jQuery('#selectAnyEventError').modal();
				this.router.navigate(['/espace/calendrier', this.token.get('event_type')]);
			}
		}
		
		if(next[3] == 'r%C3%A9capitulatif-de-la-commande'){
			this.token.get('service') == '1' ? this.token.set('step', 4) : this.token.set('step', 5);
			this.internal_reference = jQuery('#internal_reference').val();
			//console.log( this.internal_reference ); return false;
			if(this.token.get('service_cost') != '0' && this.token.get('totalPrice') == '0'){
				this.walletBalance();
				console.log('walletBalance enter');
				// this.Payment();
			}else{
				console.log('click next');
				this.paymentGateWayOrder();
				// this.paymentGateWay();
			}

		}

		// if(next[3] == 'paiement'){
		// 	this.router.navigate(['/merci']);
		// }

		if(next.length == 4 && next[3] == 'salle-de-r%C3%A9union'){
			this.token.set('step', 2);
			this.token.remove('eventData');

			if( JSON.parse( this.token.get( 'selected_events' ) ) != null && JSON.parse( this.token.get( 'selected_events' ) ).length >= 1 ){
				this.router.navigate( ['/catégorie'] );
			} else {
				//jQuery('#serviceError').modal();
				jQuery('#selectAnyEventError').modal();
				this.router.navigate(['/salle-de-réunion']);
			}
		}

		if(next[3] == 'salle-de-r%C3%A9union' && next[4] == 'calendrier'){
			this.token.set('step', 3);
			this.token.remove('editMainOrder');
			this.token.remove('editMenuOrder');
			if(JSON.parse(this.token.get('eventData')) != null && JSON.parse(this.token.get('eventData')).length >= 1){
				this.router.navigate(['/catégorie']);
			} else {
				jQuery('#selectAnyEventError').modal();
				this.router.navigate(['/salle-de-réunion/calendrier']);
			}
		}


		if( !this.auth.isLoggedIn() && next[3] == 'cat%C3%A9gorie'){

			this.router.navigate(['/connexion']);
			// if(JSON.parse(this.token.get('menu')) != null && JSON.parse(this.token.get('menu')).length >= 1){
			// } else {
			// 	jQuery('#selectAnyCategoryError').modal();
			// 	this.router.navigate(['/catégorie']);
			// }
		}

		if(next[3] == 'chariot'){
			this.router.navigate(['/récapitulatif-de-la-commande']);
		}

		if (this.auth.isLoggedIn() && next[3] == 'cat%C3%A9gorie') {
			this.token.set('step', 4);
			this.token.remove('editMenuOrder');
				this.router.navigate(['/récapitulatif-de-la-commande']);
			// if(JSON.parse(this.token.get('menu')) != null && JSON.parse(this.token.get('menu')).length >= 1){
			// } else {
			// 	jQuery('#selectAnyCategoryError').modal();
			// 	this.router.navigate(['/catégorie']);
			// }
		}
	}

	postData: any = {};

	paymentGateWay(){
		this.allData['total'] = Number(this.token.get('totalSubmitPrice')) || Number(this.token.get('service_cost')) || 0;
		this.allData['orderDetail'] = this.orderDetail;
		this.payment.paymentGateway(this.allData).subscribe(
			data => {
				if(data['status'] == 'true'){

					//this.postData['Data'] = data['data'];
					//this.postData['InterfaceVersion'] = "HP_2.20";
					//this.postData['Seal'] = data['seal'];
					//console.log(this.postData['Data'])
					//console.log(this.postData['InterfaceVersion'])
					//console.log(this.postData['Seal'])
					//$("#submitPaymentForm input[name='Data']").val(this.postData['Data']);
					//$("#submitPaymentForm input[name='InterfaceVersion']").val(this.postData['InterfaceVersion']);
					//$("#submitPaymentForm input[name='Seal']").val(this.postData['Seal']);
					// $('#submitPaymentForm').submit();
					this.paymentURL = data['data'];
					
					setTimeout(()=>{ $('#paymentClick').click() }, 1000)
					//$('#paymentClick').click();

				}
				if(this.token.get('eventData') && this.token.get('service') == '1'){
					if(data['status'] == 'false'){
						this.toastr.error("Quelque chose ne va pas.");
						this.router.navigate(['/récapitulatif-de-la-commande']);
					}
				}

				if(this.token.get('menu') && this.token.get('service') == '2'){
					if(data['status'] == 'false'){
						this.toastr.error("Quelque chose ne va pas.");
						this.router.navigate(['/récapitulatif-de-la-commande']);
					}	
				}
			},
			error => {

			}
		);
	}

	paymentGateWayOrder(){
		console.log( 'paymentGateWayOrder' );
		var self = this;
		this.allData['allData'] = this.cookie.getAll();
		this.allData['menu'] = this.token.get('menu');
		this.allData['total'] = this.token.get('totalSubmitPrice') || this.token.get('service_cost');
		this.allData['taxAmount'] = this.token.get('taxAmount');
		this.allData['totalPrice'] = this.token.get('totalPrice');
		this.allData['eventData'] = this.token.get('eventData');
		this.allData['nomade_service'] = this.token.get('nomade_service');
		this.allData['userCanOrderWithoutPay'] = this.token.get('userCanOrderWithoutPay');
		var allEvents = [];

		var SelectedEvents = JSON.parse(this.token.get('selected_events') || '[]');
		var selected_event = null;

		var currentUser = JSON.parse(this.token.get('currentUser'));
		
		jQuery( SelectedEvents ).each( function( i, selectedEventData ){
			
			//console.log( selectedEventData );
			if( selectedEventData['status'] == 'pending' ){
				allEvents.push({
		            "event_key": selectedEventData['event_key'],
		            "item_key": selectedEventData['item_key'],
		            "order_type": selectedEventData['order_type'],
		            "room_id": selectedEventData['room_id'],
		            "event_day_type": selectedEventData['event_day_type'],
		            "event_start_date": selectedEventData['event_start_date'],
		            "event_end_date": selectedEventData['event_end_date'],
		            "price": selectedEventData['price'],
		            "status": selectedEventData['status'],
		            "id" : selectedEventData['id'],
		            'menu': selectedEventData['menu'] || [],
		        });
		        ///console.log( selected_event );
		    }

	        //this.allData['selectedEvents'] = selected_event;

	    });

	    this.allData['selectedEvents'] = JSON.stringify(allEvents);
	    //console.log( this.allData['selectedEvents'] );
	    //return;

		this.allData['service'] = this.token.get('service');
		this.allData['event_type'] = this.token.get('event_type');
		this.allData['room_size'] = this.token.get('room_size');
		this.allData['payment'] = this.paymentForm.value;
		this.allData['userDetail'] = this.token.userDetail('currentUser');
		this.allData['couponId'] = this.token.get('couponId');
		this.allData['couponDiscount'] = this.token.get('couponDiscount');
		this.allData['walletDiscount'] = this.token.get('walletDiscount');
		this.allData['walletUpdatePrice'] = this.token.get('walletUpdatePrice');
		this.allData['editOrder'] = this.token.get('editOrder');
		this.allData['editOrderId'] = this.token.get('editOrderId');
		this.allData['internal_reference'] = this.internal_reference;

		// var user_can_pay_offline  = this.token.get('user_can_pay_offline');

		// console.log(this.allData);
		// return;
		this.payment.Payment(this.allData).subscribe(
  			data => {
  				if(data['status'] == 'false'){
  					jQuery('#mailNotSend').modal();
  				}

	            if(data['status'] == 'true'){
	            	this.orderDetail = data['orderDetail']

	            	if( currentUser['user_can_pay_offline'] == '0'){
	            		this.paymentGateWay();
	            	}else{

	            		this.token.remove('menu');
						this.token.remove('eventData');
						//this.token.remove('service');
						this.token.remove('event_type');
						this.token.remove('room_size');
						this.token.remove('full_price');
						this.token.remove('hour_price');
						this.token.remove('half_price');
						this.token.remove('menuPrice');
						this.token.remove('totalPrice');
						this.token.remove('totalSubmitPrice');
						this.token.remove('walletTotalDesc');
						this.token.remove('service_cost');
						this.token.remove('service_type');
						this.token.remove('fetchEvent');
						this.token.remove('walletDiscount');
						this.token.remove('price');
						this.token.remove('walletUpdatePrice');
						this.token.remove('contract');
						this.token.remove('amountType');
						this.token.remove('percentage');
						this.token.remove('couponDiscount');
						this.token.remove('couponName');
						this.token.remove('taxAmount');
						this.token.remove('selected_events');
						this.token.remove('room_type');
						this.token.remove('item_key');
						this.token.remove('event_key');
						this.token.remove('CurrentItemKey');
						this.token.remove('selected_events_temp');
						this.token.remove('editOrder');
						this.token.remove('editOrderId');
						this.token.remove('edit_order_created_at');
						this.cookie.delete('service');
						this.cookie.delete('space');
						this.cookie.delete('eventData');
						this.cookie.delete('totalPrice');

	            		this.router.navigate(['/merci'],{queryParams:{staus:'true'}});
	            	}

                	this.toastr.success("Commande réussie..");
	            }
  			},
            error => {
                // this.toastr.error(error.error.error);
            }
  		);
	}

	Payment(){
		this.allData['allData'] = this.cookie.getAll();
		this.allData['menu'] = this.token.get('menu');
		this.allData['total'] = this.token.get('totalSubmitPrice') || this.token.get('service_cost');
		this.allData['taxAmount'] = this.token.get('taxAmount');
		this.allData['totalPrice'] = this.token.get('totalPrice');
		this.allData['eventData'] = this.token.get('eventData');

		var allEvents = [];

		var SelectedEvents = JSON.parse(this.token.get('selected_events') || '[]');
		var selected_event = null;

		jQuery( SelectedEvents ).each( function( i, selectedEventData ){
			
			//console.log( selectedEventData );
			if( selectedEventData['status'] == 'pending' ){
				allEvents.push({
		            "event_key": selectedEventData['event_key'],
		            "item_key": selectedEventData['item_key'],
		            "order_type": selectedEventData['order_type'],
		            "room_id": selectedEventData['room_id'],
		            "event_day_type": selectedEventData['event_day_type'],
		            "event_start_date": selectedEventData['event_start_date'],
		            "event_end_date": selectedEventData['event_end_date'],
		            "price": selectedEventData['price'],
		            "status": selectedEventData['status'],
		            'menu': selectedEventData['menu'] || [],
		        });
		        ///console.log( selected_event );
		    }

	        //this.allData['selectedEvents'] = selected_event;

	    });

	    this.allData['selectedEvents'] = JSON.stringify(allEvents);

		this.allData['service'] = this.token.get('service');
		this.allData['event_type'] = this.token.get('event_type');
		this.allData['room_size'] = this.token.get('room_size');
		this.allData['payment'] = this.paymentForm.value;
		this.allData['userDetail'] = this.token.userDetail('currentUser');
		this.allData['couponId'] = this.token.get('couponId');
		this.allData['couponDiscount'] = this.token.get('couponDiscount');
		this.allData['walletDiscount'] = this.token.get('walletDiscount');
		this.allData['walletUpdatePrice'] = this.token.get('walletUpdatePrice');
		this.allData['editOrder'] = this.token.get('editOrder');
		this.allData['editOrderId'] = this.token.get('editOrderId');
		this.allData['internal_reference'] = this.internal_reference;

		this.payment.Payment(this.allData).subscribe(
  			data => {
  				if(data['status'] == 'false'){
  					jQuery('#mailNotSend').modal();
  				}

	            if(data['status'] == 'true'){
	            	this.token.remove('menu');
	            	this.token.remove('eventData');
	            	//this.token.remove('service');
	            	this.token.remove('event_type');
	            	this.token.remove('room_size');
	            	this.token.remove('full_price');
	            	this.token.remove('hour_price');
	            	this.token.remove('half_price');
	            	this.token.remove('menuPrice');
	            	this.token.remove('totalPrice');
	            	this.token.remove('totalSubmitPrice');
	            	this.token.remove('walletTotalDesc');
	            	this.token.remove('service_cost');
	            	this.token.remove('service_type');
	            	this.token.remove('fetchEvent');
	            	this.token.remove('walletDiscount');
	            	this.token.remove('price');
	            	this.token.remove('walletUpdatePrice');
	            	this.token.remove('contract');
	            	this.token.remove('amountType');
	            	this.token.remove('percentage');
	            	this.token.remove('couponDiscount');
	            	this.token.remove('couponName');
	            	this.token.remove('taxAmount');
	            	this.token.remove('editOrder');
					this.token.remove('editOrderId');
					this.token.remove('edit_order_created_at');

	            	this.cookie.delete('service');
	            	this.cookie.delete('space');
	            	this.cookie.delete('eventData');
	            	this.cookie.delete('totalPrice');

                	this.toastr.success("Paiement réussi.");
	                this.router.navigate(['/merci']);
	            }
  			},
            error => {
                // this.toastr.error(error.error.error);
            }
  		);
	}

	walletBalance(){
		this.walletData['user_id'] = this.token.getUser();
		this.walletData['updateWalletTotal'] = this.token.get('walletUpdatePrice');
		this.userserivce.walletBalance(this.walletData).subscribe(
  			data => {
	            if(data['status'] == 'false'){
                	this.toastr.error(data['message']);
	            }
	            if(data['status'] == 'true'){
	            	console.log('true')
	            	this.Payment();
                	this.toastr.success(data['message']);
	            }
  			},
            error => {
                this.toastr.error(error.error.error);
            }
  		);
	}
}
