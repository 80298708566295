import { Injectable, Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../../service/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenService } from '../../service/token.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
//import { saveAs } from "file-saver";


import * as $ from 'jquery';
declare var jQuery: any;

@Component({
	selector: 'app-invoices-and-credit',
	templateUrl: './invoices-and-credit.component.html',
	styleUrls: ['./invoices-and-credit.component.css']
})
export class InvoicesAndCreditComponent implements OnInit {

	userDetail:any;
	userId:any = {};
	orderData:any;
	//orderIds:any = {};
	allOrders: any;
	allRefundOrders: any;
	walletTotal: any;
	countAllOrder: any;
	countAllRefundOrder: any;
	pdfURL: any;
	b64Doc: any;
	downloadUrl: any;
	fileName: any;
	type: any;
	orderDetail: any;
	orderChecked:any = [];

	constructor(
		private http:HttpClient,
		private router: Router,
		private token: TokenService,
		private auth: AuthenticationService,
		private toastr: ToastrService,
		public fb: FormBuilder,
		private userservice: UserService,
	) { 
		this.countAllOrder = 0;
		this.userservice.loadScript();
		this.userDetail = this.token.userDetail('currentUser');
		this.userId['user_id'] =  this.token.getUser();
		this.getAllorder();
		this.getAllRefundOrder();
	}

	ngOnInit() {
	}


	getAllorder(){
		this.userservice.getOrder(this.userId).subscribe(
  			data => {
	            if(data['status'] == 'false'){
                	this.toastr.error(data['message']);
	            }
	            if(data['status'] == 'true'){
	            	this.allOrders = data['order'];
	            	this.countAllOrder = this.allOrders.length;
	            }
  			},
            error => {
                this.toastr.error("Oups! Quelque chose s'est mal passé.");
            }
  		);
	}

	getAllRefundOrder(){
		this.userservice.getRefundOrder(this.userId).subscribe(
  			data => {
	            if(data['status'] == 'false'){
                	this.toastr.error(data['message']);
	            }
	            if(data['status'] == 'true'){
	            	this.allRefundOrders = data['order'];
	            	this.countAllRefundOrder = this.allRefundOrders.length
	            	this.walletTotal = data['walletTotal'];
	            }
  			},
            error => {
                this.toastr.error("Oups! Quelque chose s'est mal passé.");
            }
  		);
	}

	createPDF(order_id){
		//console.log('createPDF');
		this.orderData = {};
		this.orderData['orderId'] = order_id;
		this.orderData['action'] = 'view';
		this.userservice.genratePDF(this.orderData).subscribe(
  			data => {
	            if(data['status'] == 'false'){
                	this.toastr.error(data['message']);
	            }
	            if(data['status'] == 'true'){
					this.pdfURL = data['url'];
					this.orderDetail = data['orderDetail'];
					window.open(this.pdfURL, '_blank');
	            }
  			},
            error => {
                this.toastr.error("Oups! Quelque chose s'est mal passé.");
            }
  		);
	}

	updateCheckedOptions( order_id, event ){
		if(event.target.checked) {
	       this.orderChecked.push(order_id);
	    } else {
	     	for(var i=0 ; i < this.orderChecked.length; i++) {
	       		if(this.orderChecked[i] == order_id) {
	         		this.orderChecked.splice(i,1);
	      		}
	    	}
		}
		//console.log(this.orderChecked);
	}

	exportPdf(){
		
		if(this.orderChecked.length > 0){
			this.orderData = {};
			this.orderData['orderIds'] = this.orderChecked;;
			this.orderData['action'] = 'download';
			this.userservice.genratePDF(this.orderData).subscribe(
	  			data => {
	  				//console.log(data);
		            if(data['status'] == 'false'){
	                	this.toastr.error(data['message']);
		            }
		            if(data['status'] == 'true'){
		            	var self = this;
						this.downloadUrl = data['url'];
						this.b64Doc = data['b64Doc'];
						this.fileName = data['file_name'];
						this.type = data['type'];
					    this.downloadPDF(this.b64Doc,this.fileName);
		            }
	  			},
	            error => {
	                this.toastr.error("Oups! Quelque chose s'est mal passé.");
	            }
	  		);

	  	}else{
	  		jQuery('#noSelectedOrder').modal();
	  	}
	}

	downloadPDF(base64Data,filename) {

	    var arrBuffer = this.base64ToArrayBuffer(base64Data);

	    // It is necessary to create a new blob object with mime-type explicitly set
	    // otherwise only Chrome works like it should
	    var newBlob = new Blob([arrBuffer], { type: "application/pdf" });

	    // IE doesn't allow using a blob object directly as link href
	    // instead it is necessary to use msSaveOrOpenBlob
	    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
	        window.navigator.msSaveOrOpenBlob(newBlob);
	        return;
	    }

	    // For other browsers: 
	    // Create a link pointing to the ObjectURL containing the blob.
	    var data = window.URL.createObjectURL(newBlob);

	    var link = document.createElement('a');
	    document.body.appendChild(link); //required in FF, optional for Chrome
	    link.href = data;
	    link.download = filename;
	    link.click();

	    if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
			//console.log('safari');
			var windowReference = window.open();
			windowReference.location.href  = link.href;
			// window event not working here
		}else{
			//console.log('non safari');
	    	window.URL.revokeObjectURL(data);
		}

	    link.remove();
	}

	base64ToArrayBuffer(data) {
	    var binaryString = window.atob(data);
	    var binaryLen = binaryString.length;
	    var bytes = new Uint8Array(binaryLen);
	    for (var i = 0; i < binaryLen; i++) {
	        var ascii = binaryString.charCodeAt(i);
	        bytes[i] = ascii;
	    }
	    return bytes;
	};

	downloadPDF2(pdfbase64,filename) {
		const linkSource = `data:application/pdf;base64,${this.b64Doc}`;
		const downloadLink = document.createElement("a");
		const fileName = filename;
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		downloadLink.click();
	}

}
