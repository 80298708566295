import { Component, OnInit } from '@angular/core';

import { SpaceService } from '../../service/space.service';
import { AuthenticationService } from '../../service/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from '../../service/token.service';
// import { UserService } from '../../service/user.service';
import * as $ from 'jquery';
declare var jQuery: any;

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

	serviceNo:any;
	seletedserviceNo:any;

	constructor(
		private router: Router,
		private auth: AuthenticationService,
		private toastr: ToastrService,
		private token: TokenService,
		private spaceservice: SpaceService,
		// private userService: UserService,
	) {
		// localStorage.clear();
		this.getClosingDates();
		// this.userService.loadScript();
	}

	ngOnInit() {
		if( this.token.get('service') || this.token.get('service_2') ){
			var selected_service = this.token.get( 'service' ) || this.token.get( 'service_2' );
			jQuery("input[name=reserve][value=" + selected_service + "]").prop('checked', true);
		}

		this.recurringReminder();
	}

	checkBoxvalue(event){
		if( this.token.get('service') ){
			if( this.token.get('service') != event.target.value){
				this.seletedserviceNo = event.target.value;
				console.log(this.seletedserviceNo);
				this.changeService( this.seletedserviceNo );
				//jQuery('#serviceChangeConfirm').modal();
			} else {
				this.serviceNo = event.target.value;
				if( this.serviceNo == 1 ){
					this.token.set('service', this.serviceNo);
					this.router.navigate(['/espace']);
				} else if( this.serviceNo == 2 ){
					this.token.set('service', this.serviceNo);
					this.router.navigate(['/salle-de-réunion']);
				} else if( this.serviceNo == 3 ){
					this.token.set('service_2', this.serviceNo);
					this.router.navigate(['/plateau']);
					//this.router.navigate(['/working-progress']);
				} else if( this.serviceNo == 4 ){
					this.token.set('service_2', this.serviceNo);
					this.router.navigate(['/un-événement']);
					//this.router.navigate(['/working-progress']);
				} else if( this.serviceNo == 5 ){
					this.token.set('service_2', this.serviceNo);
					this.router.navigate(['/domiciliation']);
					//this.router.navigate(['/working-progress']);
				}	
			}
		}else{
			this.serviceNo = event.target.value;
			if( this.serviceNo == 1 ){
				this.token.set('service', this.serviceNo);
				this.router.navigate(['/espace']);
			} else if( this.serviceNo == 2 ){
				this.token.set('service', this.serviceNo);
				this.router.navigate(['/salle-de-réunion']);
			} else if( this.serviceNo == 3 ){
				this.token.set('service_2', this.serviceNo);
				this.router.navigate(['/plateau']);
				//this.router.navigate(['/working-progress']);
			} else if( this.serviceNo == 4 ){
				this.token.set('service_2', this.serviceNo);
				this.router.navigate(['/un-événement']);
				//this.router.navigate(['/working-progress']);
			}
			else if( this.serviceNo == 5 ){
				this.token.set('service_2', this.serviceNo);
				this.router.navigate(['/domiciliation']);
				//this.router.navigate(['/working-progress']);
			}
		}

	}

	recurringReminder(){
		this.spaceservice.sendRecurringReminder().subscribe(
  			data => {
	            
  			},
            error => {
                console.log('cannot send recurring request.');
            }
  		);
	}

	changeService(seletedserviceNo){
		if( this.token.get('service') ){
			this.serviceNo = seletedserviceNo;
			//this.removeLocalStorageToken();
			jQuery('#serviceChangeConfirm').modal('hide');
			jQuery("input[name=reserve][value=" + this.serviceNo + "]").prop('checked', true);
		}
		if( seletedserviceNo == 1 ){
			this.token.set('service', seletedserviceNo);
			this.router.navigate(['/espace']);
		} else if( seletedserviceNo == 2 ){
			this.token.set('service', seletedserviceNo);
			this.router.navigate(['/salle-de-réunion']);
		} else if( seletedserviceNo == 3 ){
			//this.removeLocalStorageToken();
			this.token.set('service_2', seletedserviceNo);
			this.router.navigate(['/plateau']);
			//this.router.navigate(['/working-progress']);
		} else if( seletedserviceNo == 4 ){
			//this.removeLocalStorageToken();
			this.token.set('service_2', seletedserviceNo);
			this.router.navigate(['/un-événement']);
			//this.router.navigate(['/working-progress']);
		}
		else if( seletedserviceNo == 5 ){
			//this.removeLocalStorageToken();
			this.token.set('service_2', seletedserviceNo);
			this.router.navigate(['/domiciliation']);
			//this.router.navigate(['/working-progress']);
		}
	}

	cancelChangeService(){
		jQuery("input[name=reserve][value=" + this.token.get('service') + "]").prop('checked', true);
	}

	getcheckBoxvalue(){
		return this.serviceNo;
	}

	// isSelected(serviceNo){
	// 	console.log(serviceNo);
	// 	let checked = false;
	// 	if(this.token.get('service') == serviceNo){
	// 		jQuery("input[name=reserve][value=" + serviceNo + "]").prop('checked', true);
	// 		checked = true;
	// 	}
	// 	//return checked
	// }

	removeLocalStorageToken(){
		this.token.remove('menu');
		this.token.remove('eventData');
		this.token.remove('service');
		this.token.remove('service_2');
		this.token.remove('event_type');
		this.token.remove('room_size');
		this.token.remove('full_price');
		this.token.remove('hour_price');
		this.token.remove('half_price');
		this.token.remove('menuPrice');
		this.token.remove('totalPrice');
		this.token.remove('totalSubmitPrice');
		this.token.remove('walletTotalDesc');
		this.token.remove('service_cost');
		this.token.remove('service_type');
		this.token.remove('fetchEvent');
		this.token.remove('walletDiscount');
		this.token.remove('price');
		this.token.remove('walletUpdatePrice');
		this.token.remove('contract');
		this.token.remove('amountType');
		this.token.remove('percentage');
		this.token.remove('couponDiscount');
		this.token.remove('couponName');
		this.token.remove('taxAmount');
		this.token.remove('selected_events');
		this.token.remove('room_type');
		this.token.remove('item_key');
		this.token.remove('event_key');
		this.token.remove('CurrentItemKey');
		this.token.remove('selected_events_temp');
	}

	getClosingDates(){
        var self = this;
        this.spaceservice.getClosingDates().subscribe(
            data => {
                if(data['status'] == 'true'){

                    var getClosingDates = {
                        "holidays": data['holidays'],
                        "week_days": data['week_days']
                    }

                    this.token.set('getClosingDates', JSON.stringify(getClosingDates));    
                    var closingData = this.token.get('getClosingDates');
                    console.log(JSON.parse(closingData));
                }
            },
            error => {
              console.log(error);
            }
        );
    }

}
