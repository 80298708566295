import { Component, OnInit } from '@angular/core';

import { TokenService } from '../../service/token.service';

@Component({
	selector: 'app-step',
	templateUrl: './step.component.html',
	styleUrls: ['./step.component.css']
})
export class StepComponent implements OnInit {

	step:any;
	service:any;

	constructor(
		private token : TokenService
	) { 
		this.service = this.token.get('service');
		this.step = this.token.get('step');
	}

	ngOnInit() {
	}

}
