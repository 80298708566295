import { Component, OnInit } from '@angular/core';

import { SpaceService } from '../../service/space.service';
import { AuthenticationService } from '../../service/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from '../../service/token.service';
// import { UserService } from '../../service/user.service';
import * as $ from 'jquery';
declare var jQuery: any;

@Component({
	selector: 'app-workin-progress',
	templateUrl: './workin-progress.html'
})
export class WorkinProgress implements OnInit {

	constructor(
		private router: Router,
		private auth: AuthenticationService,
		private toastr: ToastrService,
		private token: TokenService,
		private spaceservice: SpaceService,
		// private userService: UserService,
	) {
		
	}

	ngOnInit() {
		
	}
}
