import { Component, Input  } from '@angular/core';

import { AuthenticationService } from './service/authentication.service';
import { UserService } from './service/user.service';
import { StoreService } from './service/store.service';
import { TokenService } from './service/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
//import * as moment from 'moment';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
	// @Input() userUpdate: ;

	title = 'frontend'; 
	user:any;
	imageURL:any;
    storageURL: any;
	userImage:any;
	myOrders: any;
	userId: any = {};
	user_can_edit: any;
	orderId: any = {};
	appointments: any = [];
	eventArray: any = [];
	isSelectedEvent = [];

	constructor(
		public auth: AuthenticationService,
		private router: Router,
		private toastr: ToastrService,
		private token: TokenService,
		private userService: UserService,
		private store: StoreService,
	) { 
		this.userService.loadScript();
		auth.loginEvent.subscribe(item => this.userRefresh(item));

		this.imageURL = environment.IMG_URL;
        this.storageURL = environment.STORAGE_IMG_URL;
		this.user = JSON.parse(this.token.get('currentUser')) || [];
		this.userImage = this.user.image || null;

		this.user_can_edit = false;

		if( this.user.type == 2 ){
			this.user_can_edit = true;			
		}

		var url = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);

		if(url == 'r%C3%A9capitulatif-de-la-commande' && this.token.get('walletDiscount')){
			$('.logo a').removeAttr('href');
		}

		$(document).ready(function() {
			$('body').removeClass();
			$('body').addClass(url);

			$( document ).on('click', '.profile-wrap .avtar', function(event){
				event.stopPropagation();
				$('.profile-wrap .acc-option').slideToggle();
			})

			$( document ).on('click', '.profile-wrap .acc-option', function( event ){
    			event.stopPropagation();
            });

			$( document ).on( 'click', '.profile-wrap .option-box', function(event){
				$( '.profile-wrap .acc-option' ).slideToggle();
			});

		});

		$(document).on("click", function () {
		    $(".profile-wrap .acc-option").slideUp();
		});

		if (this.auth.isLoggedIn()) {
			this.token.remove('loginClick');
		}
		
		this.myOrder();	

	}

	myOrder(){
		this.userId['user_id'] = this.user.id;
		this.userService.getOrder(this.userId).subscribe(
			data => {
				if(data['status'] == 'true'){
					this.myOrders = data['order'];	
				}
			},
			error =>{

			}
		);
	}

	userRefresh(data){
		console.log( 'refresh' )
		this.user = data;
		this.userImage = data.image;
		$(document).ready(function() {
			/*$('.profile-wrap .avtar').click(function(){
				event.stopPropagation();
				$('.profile-wrap .acc-option').slideToggle();
			})*/
		});
	}

	Logout(){
		this.auth.logout();
		this.toastr.error("Déconnexion utilisateur réussie.");
        this.router.navigate(['/home']);
	}


	UserClicked(){
		this.token.set('loginClick',1);
	}

	// orderCanEdit( orderId, orderDate, order_payment_type ){

 //        let order_date = moment( orderDate );
 //        let current_date = moment();

 //        let duration = moment.duration( current_date.diff( order_date ) );
 //        let hours = Math.round( duration.asHours() * 100 ) / 100;
 //        if( hours < 48 && order_payment_type == "offline" ){
 //            return true;
 //        }

 //        return false;
 //    }

 	is_item_key_exist(needle, haystack) {
        //console.log(haystack.length);
        var length = haystack.length;
        if( length > 0 ){
            for(var key in haystack) {
                if(haystack[key]['item_key'] == needle){
                    return true;
                }
                if(typeof haystack[key]=='object'){
                    if(this.is_item_key_exist(needle, haystack[key])){
                        return true;
                    }
                } 
            }
        }
        return false;
    }

 	editOrder( orderId ){
        var self = this;

        this.token.remove('fetchEvent');
        this.token.remove('couponDiscount');
        this.token.remove('totalPrice');
        this.token.remove('eventData');
        this.token.remove('selected_events');
        this.token.remove('totalSubmitPrice');
        this.token.remove('nomade_service');
        this.token.remove('menu');
        this.token.remove('menuPrice');
        this.token.remove('percentage');
        this.token.remove('couponName');
        this.token.remove('couponId');
        this.token.remove('taxAmount');
        this.token.remove('price');
        this.token.remove('walletUpdatePrice');

        
        this.token.set('editOrder', 1);
        this.token.set('editOrderId', orderId);
        this.orderId['order_id'] = orderId;
        this.userService.getEditOrder(this.orderId).subscribe(
            data => {
                if(data['status'] == 'true'){
                    //console.log(data['events']);
                    let item_price = 0;
                    for (let i = 0; i < data['events'].length; i++) {
                        
                        var title = '';
                        
                        if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 1){
                            title = '30 Jours'
                        }else if(data['events'][i].order_type == 2 && data['events'][i].length_of_booking == 2){
                            title = 'Half Day';
                        }else if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 2 || data['events'][i].order_type == 2 && data['events'][i].length_of_booking == 3){
                            title = 'La journée';
                        }else if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 3){
                            title = 'Le mois';
                        }else if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 4){
                            title = '10 Jours';
                        }else if(data['events'][i].order_type == 2 && data['events'][i].length_of_booking == 1){
                            title = 'Hourly';
                        }

                        //if( data['events'][i].start_date != null && data['events'][i].end_date != null ){
                        if( typeof data['events'][i].appointment !== 'undefined' && data['events'][i].appointment.length > 0 ){

                            var title = '';
                            var service_cost = 0;
                            if( data['events'][i].length_of_booking == 2 ){
                                title = 'journée';
                                service_cost = 20;
                                service_cost = service_cost * data['events'][i].appointment.length;
                                item_price = item_price + service_cost;
                            }
                            
                            if( data['events'][i].length_of_booking == 3 ){
                                title = 'Mois';
                                service_cost = 140;
                                item_price = item_price + service_cost;
                            }

                            if( data['events'][i].length_of_booking == 4 ){
                                title = 'Dix jours';
                                service_cost = 90;
                                item_price = item_price + service_cost;
                            }

                            if( data['events'][i].length_of_booking == 1 ){
                                title = '30 Jours';
                                service_cost = 550;
                                item_price = item_price + service_cost;
                            }

                            self.token.set('event_type', title);

                            
                            this.token.set('service_cost', service_cost);
                            //console.log(data['events'][i].appointment.length);
                            if( data['events'][i].appointment.length > 0 ){
                                for (let j = 0; j < data['events'][i].appointment.length; j++) {
                                    
                                    self.appointments.push({
                                        start: data['events'][i].appointment[j].start_date,
                                        end : data['events'][i].appointment[j].end_date,
                                        event_type : title,
                                        id : data['events'][i].appointment[j].id
                                    })
                                    self.token.set('eventData', JSON.stringify(self.appointments));

                                }
                                //console.log(data['events'][i]);
                                if( data['events'][i].order_service.length > 0 ){
                                    //console.log(data['events'][i].order_service.length);
                                    self.token.set( 'nomade_service', JSON.stringify( data['events'][i].order_service ) );
                                }
                            }

                            /*if( data['events'][i].order_service.length > 0 ){

                                var getSerivceLocal = [];

                                for (let j = 0; j < data['events'][i].order_service.length; j++) {
                                    
                                    getSerivceLocal.push({
                                        id: data['events'][i].order_service[j].id,
                                        image : '',
                                        name : '',
                                        label : '',
                                        description : '',
                                        one_time_price : '',
                                        type_of_invoice : '',
                                        recurring_price : '',
                                        recurring_months : '',
                                        status : '',
                                        created_at : data['events'][i].order_service[j].created_at,
                                        updated_at : data['events'][i].order_service[j].updated_at,
                                    })
                                    self.token.set('nomade_service', JSON.stringify( getSerivceLocal ));

                                }

                            }*/
                        }

                        if(data['events'][i].event_start_date != null && data['events'][i].event_end_date != null){
                            var Fetchedevent = new Object();
                            Fetchedevent = {
                                title: title,
                                start: data['events'][i].event_start_date,
                                end: data['events'][i].event_end_date,
                                allDay: false,
                                editable:true,
                                color: 'yellow',
                                className: 'fetchEvent',
                                order_type: data['events'][i].order_type,
                                length_of_booking: data['events'][i].length_of_booking,
                                time_of_day: data['events'][i].time_of_day,
                                id : data['events'][i].id
                            };
                            item_price = item_price + data['events'][i].price;
                            this.eventArray.push( Fetchedevent );
                        }
                    }

                    if( data['order'].length > 0 ){
                        for (let i = 0; i < data['order'].length; i++) {
                            if( typeof data['order'][i].coupon !== 'undefined' ){
                                let editOrderCoupon = data['order'][i].coupon;
                                //console.log( editOrderCoupon );
                                //we found coupon applied in order so we set in edit order
                                this.token.set( 'amountType', editOrderCoupon['amount_type'] );
                                this.token.set( 'couponName', editOrderCoupon['name'] );
                                this.token.set( 'couponId', editOrderCoupon['id'] );
                                this.token.set( 'percentage', editOrderCoupon['percentage'] );
                                this.token.set( 'couponDiscount', data['order'][i].coupon_amount );
                                this.token.set( 'items_can_edit', data['order'][i].items_can_edit );
                                this.token.set( 'services_can_edit', data['order'][i].services_can_edit );
                            }

                            if( typeof data['order'][i].wallet_amount !== 'undefined' && data['order'][i].wallet_amount != '' ){
                                this.token.set( 'price', item_price );
                                this.token.set( 'walletDiscount', data['order'][i].wallet_amount );
                            }
                        }
                    }

                }
                
                self.store.RemoveEventSelection();
                var allEvents = JSON.parse(self.store.GetEventSelection()) || [];
                
                var allEvents = allEvents.filter(function (el) {
                  return el != null;
                });

                if (data['events'] !== undefined && Object.keys(data['events']).length > 0) {
                    
                    for (let i = 0; i < data['events'].length; i++) {
                        if( allEvents != null && !self.is_item_key_exist( data['events'][i].item_key ,allEvents )){
                            
                            // if( data['events'][i].order_fooding_menus == undefined ){
                            //     console.log('if');
                            // }else{
                            //     console.log('else');
                            //     console.log(data['events'][i].order_fooding_menus[0].id);

                            // }

                            if( data['events'][i].order_fooding_menus != undefined && Object.keys( data['events'][i].order_fooding_menus).length > 0 ){

                                var order_fooding_menus = [];
                                var MenuPrice = 0;
                                //var totalPrice = 0;
                                // var price = 0;

                                for (let j = 0; j < Object.keys( data['events'][i].order_fooding_menus).length; j++) {
                                    
                                    order_fooding_menus.push({
                                        id: data['events'][i].order_fooding_menus[j].id,
                                        contractor_id: data['events'][i].order_fooding_menus[j].contractor_id,
                                        menu_name: data['events'][i].order_fooding_menus[j].menu_name ,
                                        image: data['events'][i].order_fooding_menus[j].image ,
                                        description: data['events'][i].order_fooding_menus[j].description,
                                        price: data['events'][i].order_fooding_menus[j].price,
                                        created_at: data['events'][i].order_fooding_menus[j].created_at,
                                        updated_at: data['events'][i].order_fooding_menus[j].updated_at,
                                        qty: data['events'][i].order_fooding_menus[j].qty,
                                        category_id: data['events'][i].order_fooding_menus[j].category_id,
                                        category_name: data['events'][i].order_fooding_menus[j].category_name,
                                        menu_item_id: data['events'][i].order_fooding_menus[j].menu_item_id,
                                        order_fooding_category_id: data['events'][i].order_fooding_menus[j].order_fooding_category_id
                                    });
                                    
                                    MenuPrice = Number( MenuPrice ) + Number( data['events'][i].order_fooding_menus[j].price ) * Number( data['events'][i].order_fooding_menus[j].qty );
                                    //totalPrice = Number( totalPrice ) + Number( data['events'][i].order_fooding_menus[j].price ) * Number( data['events'][i].order_fooding_menus[j].qty );
                                }

                                self.store.setMenu( order_fooding_menus );
                                self.token.set('menuPrice', MenuPrice);
                                

                                data['events'][i].menu = order_fooding_menus;
                                //console.log(data['events'][i]);
                            }

                            allEvents.push(data['events'][i]);
                        }
                    }

                    var totalLength = data['events'].length + allEvents.length;
                    var k = 0;
                    
                    for (let i = data['events'].length; i < totalLength; i++) {
                        if( allEvents != null && !self.is_item_key_exist( allEvents[k].item_key ,allEvents )){
                            allEvents.push(allEvents[k]);
                            k++;
                        }
                    }
                    
                    this.store.SaveAllEventSelection( allEvents );

                }
                 
                //console.log(data['order'][0].total); 
                self.token.set('totalPrice', data['order'][0].total);
                self.token.set('totalSubmitPrice', data['order'][0].total);
                self.token.set('taxAmount', data['order'][0].taxAmount);
                self.token.set( 'service', 1 );
                self.token.set( 'edit_order_created_at', data['order'][0].created_at );
                //console.log(allEvents);

                this.token.set('fetchEvent', JSON.stringify(this.eventArray));

                var event_date = JSON.parse(this.token.get('eventData'));
                var title = this.token.get('event_type');
                if(event_date){
                    for( let z=0; z < event_date.length; z++ ){
                        this.isSelectedEvent.push({
                            title: title,
                            start: event_date[z].start,
                            end: event_date[z].end,
                            editable: false,
                            allDay: true,
                            color: '#90EE90'
                        });
                    }
                }

                var url = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);
				console.log( url );
				this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
				    this.router.navigate(['chariot']);
				}); 
                //this.router.navigate(['/chariot']);
            },
            error => {
            }
        );

    }
}

