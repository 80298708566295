import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
//import { Console } from 'console';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Domiciliation } from 'src/app/model/domiciliation';
import { TokenService } from 'src/app/service/token.service';
import { UserService } from 'src/app/service/user.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-domiciliation',
  templateUrl: './domiciliation.component.html',
  styleUrls: ['./domiciliation.component.css']
})
export class DomiciliationComponent implements OnInit {

  domiciliation: Domiciliation =new Domiciliation();

  baseUrl: any;
  
  allCountry: any;
  userDetail: any;

  registerForm: FormGroup;
  customersObservable : Observable<object>;

  submitten: boolean =  false;

  arrayOfOptions: Array<string> = ['Aucune' , 'Numérisation fréquence quotidienne', 'Numérisation fréquence hebdomadaire' , 'Numérisation fréquence mensuelle' , 'Réception et stockage colis 1 semaine' , 'Réception et stockage colis 2 semaines', 'Réception et stockage colis 1 mois'];

  pincodeArr = {
    FRA: "+33",
    AFG: "+93",
    ALB: "+355",
    ALG: "+213",
    AND: "+376",
    ANG: "+244",
    ANT: "+1-268",
    ARG: "+54",
    ARM: "+374",
    ARU: "+297",
    ASA: "+1-684",
    AUS: "+61",
    AUT: "+43",
    AZE: "+994",
    BAH: "+1-242",
    BAN: "+880",
    BAR: "+1-246",
    BDI: "+257",
    BEL: "+32",
    BEN: "+229",
    BER: "+1-441",
    BHU: "+975",
    BIH: "+387",
    BIZ: "+501",
    BLR: "+375",
    BOL: "+591",
    BOT: "+267",
    BRA: "+55",
    BRN: "+973",
    BRU: "+673",
    BUL: "+359",
    BUR: "+226",
    CAF: "236",
    CAM: "+855",
    CAN: "+1",
    CAY: "+1-345",
    CGO: "+242",
    CHA: "+235",
    CHI: "+56",
    CHN: "+86",
    CIV: "Cote d'Ivoire",
    CMR: "+237",
    COD: "+243",
    COK: "+682",
    COL: "+57",
    COM: "+269",
    CPV: "+238",
    CRC: "+506",
    CRO: "+385",
    CUB: "+53",
    CYP: "+357",
    CZE: "+420",
    DEN: "+45",
    DJI: "+253",
    DMA: "+1767",
    DOM: "+1 809",
    ECU: "+593",
    EGY: "+20",
    ERI: "+291",
    ESA: "+503",
    ESP: "+34",
    EST: "+372",
    ETH: "+251",
    FIJ: "+679",
    FIN: "+358",
    FSM: "+691",
    GAB: "+241",
    GAM: "+220",
    GBR: "+44",
    GBS: "+245",
    GEO: "+995",
    GEQ: "+240",
    GER: "+49",
    GHA: "+233",
    GRE: "+30",
    GRN: "+1473",
    GUA: "+502",
    GUI: "+224",
    GUM: "+1 671",
    GUY: "+592",
    HAI: "+509",
    HKG: "+852",
    HON: "+504",
    HUN: "+36",
    INA: "+62",
    IND: "+91",
    IRI: "+98",
    IRL: "+353",
    IRQ: "+964",
    ISL: "+354",
    ISR: "+972",
    ISV: "+00 1",
    ITA: "+39",
    IVB: "+1 284",
    JAM: "+1 876",
    JOR: "+962",
    JPN: "+81",
    KAZ: "+7 6",
    KEN: "+254",
    KGZ: "+996",
    KIR: "+686",
    KOR: "+82",
    KOS: "+383",
    KSA: "+966",
    KUW: "+965",
    LAO: "+856",
    LAT: "+371",
    LBA: "+218",
    LBR: "+231",
    LCA: "+1 758",
    LES: "+266",
    LIB: "+961",
    LIE: "+423",
    LTU: "+370",
    LUX: "+352",
    MAD: "+261",
    MAR: "+212",
    MAS: "+60",
    MAW: "+265",
    MDA: "+373",
    MDV: "+960",
    MEX: "+52",
    MGL: "+976",
    MHL: "+692",
    MKD: "+389",
    MLI: "+223",
    MLT: "+356",
    MNE: "+382",
    MON: "+377",
    MOZ: "+258",
    MRI: "+230",
    MTN: "+222",
    MYA: "+95",
    NAM: "+264",
    NCA: "+505",
    NED: "+31",
    NEP: "+977",
    NGR: "+234",
    NIG: "+227",
    NOR: "+47",
    NRU: "+674",
    NZL: "+64",
    OMA: "+968",
    PAK: "+92",
    PAN: "+507",
    PAR: "+595",
    PER: "+51",
    PHI: "+63",
    PLE: "+970",
    PLW: "+680",
    PNG: "+675",
    POL: "+48",
    POR: "+351",
    PRK: "+850",
    PUR: "+1 787",
    QAT: "+974",
    ROU: "+40",
    RSA: "+27",
    RUS: "+7",
    RWA: "+250",
    SAM: "+685",
    SEN: "+221",
    SEY: "+248",
    SIN: "+65",
    SKN: "+1 869",
    SLE: "+232",
    SLO: "+386",
    SMR: "+378",
    SOL: "+677",
    SOM: "+252",
    SRB: "+381",
    SRI: "+94",
    SSD: "+211",
    STP: "+239",
    SUD: "+249",
    SUI: "+41",
    SUR: "+597",
    SVK: "+421",
    SWE: "+46",
    SWZ: "+268",
    SYR: "+963",
    TAN: "+255",
    TGA: "+676",
    THA: "+66",
    TJK: "+992",
    TKM: "+993",
    TLS: "+670",
    TOG: "+228",
    TPE: "+886",
    TTO: "+1 868",
    TUN: "+216",
    TUR: "+90",
    TUV: "+688",
    UAE: "+971",
    UGA: "+256",
    UKR: "+380",
    URU: "+598",
    USA: "+1",
    UZB: "+998",
    VAN: "+678",
    VEN: "+58",
    VIE: "+84",
    VIN: "+1 784",
    YEM: "+967",
    ZAM: "+260",
    ZAN: "+255 24",
    ZIM: "+263"
  }

  constructor(private http: HttpClient, private userservice: UserService, private token: TokenService, private formBuilder: FormBuilder, private router: Router, private toastr: ToastrService) { 
    // console.log('constructor called...');
    this.baseUrl = environment.APP_URL;
    this.userDetail = this.token.userDetail('currentUser') || [];
		console.log( this.userDetail );
  }

  ngOnInit() {
    // console.log('ng on int call3ed.....' + JSON.stringify( this.userservice.allCountries()));
    // this.registerForm = this.formBuilder.group({
    //   email: ""
    // });
    this.getCountries();

    setTimeout(() => {
      this.domiciliation.options = 'select';
    }, 500);
  }

  getCountries(){
    // console.log('getCountries  called..');
    this.userservice.allCountries().subscribe(
      data => {
      
        this.allCountry = data;        

       // this.domiciliation.country_code = this.getSelectedCountry( (this.userDetail.country_code ? this.userDetail.country_code : 'FRA'));

       this.domiciliation.country_code = 'FRA';

       this.changePincode( this.domiciliation.country_code);
        //console.log( this.getSelectedCountry( this.userDetail.country_code ) );
      },
      err => console.log(err)
    )
  }

  getSelectedCountry( country_code ){
    // console.log('getSelectedCountry  called..' + country_code);
		//73 is used for france
		if( typeof country_code === 'undefined' || country_code == '' ){
			return '73';
		}

    // console.log(' this.allCountry : ' +  JSON.stringify(this.allCountry));

		if( this.allCountry.length > 0 ){
			for ( var i = 0; i < this.allCountry.length; i++ ) {
				if( this.allCountry[i].iso3 == country_code ){
					return this.allCountry[i].iso3;
				}
			}
		} else {
			return '73';
		}
	}

  submitForm(){

    // console.log('submitForm method called..' + JSON.stringify(this.domiciliation));

    this.submitten = true;

    if (!this.domiciliation.fname || !this.domiciliation.lname || !this.domiciliation.email || !this.domiciliation.number || !this.domiciliation.social_reason || !this.domiciliation.duration || (!this.domiciliation.options || this.domiciliation.options == 'select')){
      return;
    }

    this.router.navigate(['/merci_pour_demande']);

    this.http.post(`${this.baseUrl}/addDomiciliation`, this.domiciliation).subscribe(
      data  => {
      console.log("POST Request is successful ", data);
      this.toastr.success("Ajouter un événement avec succès.");
      this.router.navigate(['/merci_pour_demande']);
    },
    error  => {
      console.log("Error", error);
      this.toastr.error(error.error.error);
    }
    );
    
  }

  changeCountry(){
    // console.log('changeCountry called..');
    this.changePincode(this.domiciliation.country_code);
  }

  changePincode(containernew){
    // console.log('changePincode called..');
    
    var self = this;

   // self.domiciliation.pincode = this.pincodeArr[containernew];
    
    $.each(this.pincodeArr, function(key, value) {
        if(key == containernew){
          self.domiciliation.pincode = value;
        }
    });
  }

}
