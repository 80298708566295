import { Injectable } from '@angular/core';

import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(
        private router: Router,
        private auth: AuthenticationService
    ) {}

	canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        if (!this.auth.isLoggedIn()) {
            // authorised so return true
            this.router.navigate(['/connexion']);
            return false;
        }
        return true;

        // not logged in so redirect to login page with the return url
    }
  
}
