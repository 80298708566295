import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilder } from '@angular/forms';
import { TokenService } from '../../service/token.service';
import { UserService } from '../../service/user.service';
import { StoreService } from '../../service/store.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import * as $ from 'jquery';
import * as moment from 'moment';
declare var jQuery: any;
@Component({
	selector: 'app-order-summary',
	templateUrl: './order-summary.component.html',
	styleUrls: ['./order-summary.component.css']
})
export class OrderSummaryComponent implements OnInit {

	spaceData: any;
	totalPrice: number;
	price: any;
	balance: any;
	walletUpdatePrice: any;
	public eventData: any = Array();
	public space: any = Array();
	public SelectedEvents = Array();
	userId:any = {};
	walletTotal: any;
	newWalletPrice: any;
	menus:any = {};
	nomade_service:any = {};
	couponDiscount: any;
	walletTotalDesc: any;
	MenuPrice: any;
	ServicePrice: any = 0;
	deleteService: any;
	walletData:any = {};
	walletApply:any;
	deleteContractDetail: any;
	itemKey: any;
	percentage: any;
	couponName: any;
	updateCouponValue: any;
	VATPercent: any;
	currentUser: any;
	VATadd: any;
	IMG_URL: any;
	serviceNo:any;

	applyCouponForm = this.fb.group({
		couponValue: null,
		user_id: null,
		service: null,
	})

	constructor(
		private cookie: CookieService,
		public fb: FormBuilder,
		public token: TokenService,
		private userservice: UserService,
		private toastr: ToastrService,
		public datepipe: DatePipe,
		private router: Router,
		private store: StoreService
	) { 
		this.IMG_URL = environment.STORAGE_IMG_URL;
		this.nomade_service = JSON.parse( this.token.get( 'nomade_service' ) ) || [];
		this.getAllRefundOrder();
		this.updateOrderSummary();
		// this.IMG_URL = environment.STORAGE_IMG_URL;
		// if(!this.token.get('event_type')){
		// 	//this.router.navigate(['/merci']);
		// }

		// if( !this.token.get( 'service' ) ){
		// 	this.toastr.error('Veuillez sélectionner un service');
		// 	this.router.navigate(['/accueil']);
		// }


		// this.currentUser =  JSON.parse(this.token.get('currentUser'));
		// this.userId['user_id'] =  this.token.getUser();

		// /*if(this.token.get('walletUpdatePrice')){
		// 	this.walletTotal = this.token.get('walletUpdatePrice');
		// }else{
		// }*/
		// this.getAllRefundOrder();

		// this.menus = JSON.parse(this.token.get('menu') || '[]');
		// if(this.token.get('service') == '1' && this.token.get('event_type') != null){
		// 	if(this.token.get('service') == '1' && this.token.get('totalPrice') != '0'){
		// 		//console.log('service 1 true');
		// 		this.totalPrice = Number(this.token.get('service_cost'));
		// 		this.price = Number(this.token.get('service_cost'));
		// 	} else {
		// 		//console.log('service 1 false');
		// 		this.totalPrice = Number(this.token.get('totalPrice'));
		// 		this.price = Number(this.token.get('service_cost'));
		// 		//this.walletTotalDesc = Number(this.token.get('walletDiscount'));
		// 		//this.walletUpdatePrice = Number(this.token.get('walletUpdatePrice'));
		// 	}
		// }

		// if( this.token.get('service') == '2' ){
		// 	if( this.token.get( 'selected_events' ) ){
		// 		this.SelectedEvents = JSON.parse( this.token.get( 'selected_events' ) ) || [];
		// 	} else {
		// 		this.toastr.error('Veuillez sélectionner un service');
		// 		this.router.navigate( ['/accueil'] );
		// 	}
		// 	//this.eventData = JSON.parse( this.token.get('eventData') ) || [];
		// }

		// if( this.SelectedEvents.length > 0 ){
		// 	var self = this;
		// 	self.totalPrice = self.price = 0;
		// 	this.MenuPrice = 0;
		// 	jQuery( this.SelectedEvents ).each( function( i, selectedEventData ){
		// 		if( selectedEventData['status'] == 'pending'){
		// 			//console.log( 'Event Price => ' + Number( selectedEventData['price'] ) );
		// 			self.totalPrice = self.totalPrice + Number( selectedEventData['price'] );
		// 			self.price = self.price + Number( selectedEventData['price'] );
		// 			if( typeof selectedEventData.menu !== 'undefined' && selectedEventData.menu.length > 0 ){
		// 				for( let menu of selectedEventData.menu ){
		// 					self.MenuPrice = Number( self.MenuPrice ) + Number( menu.price ) * Number( menu.qty );
		// 					self.totalPrice = Number( self.totalPrice ) + Number( menu.price ) * Number( menu.qty );
		// 				}
		// 			}
		// 		}
		// 	});
		// 	// for( let menu of this.menus ){
		// 	// 	this.MenuPrice = Number( this.MenuPrice ) + Number( menu.price ) * Number( menu.qty );
		// 	// 	this.totalPrice = Number( this.totalPrice ) + Number( menu.price ) * Number( menu.qty );
		// 	// }
			
		// 	// this.couponDiscount = Number(this.token.get('couponDiscount'));
		// 	// this.totalPrice = this.totalPrice - this.couponDiscount;
		// 	this.token.set('menuPrice', this.MenuPrice);
		// 	this.token.set('totalPrice', this.totalPrice);
		// 	//this.walletTotalDesc = Number(this.token.get('walletDiscount'));
		// 	//this.walletUpdatePrice = Number(this.token.get('walletUpdatePrice'));
		// 	/*if(this.totalPrice == 0){
		// 		this.token.set('totalSubmitPrice', this.walletTotalDesc);
		// 	} else {
		// 		this.token.set('totalSubmitPrice', this.totalPrice);
		// 	}*/
		// }
		// //console.log('After all Render Total Price => '+this.totalPrice);

		// if(this.token.get('amountType') == '1'){
		// 	this.couponDiscount = Number(this.token.get('couponDiscount'));
		// 	this.totalPrice = this.totalPrice - this.couponDiscount;
		// 	//console.log(this.totalPrice);
		// 	if(this.totalPrice > 0){
		// 		this.totalPrice = this.totalPrice - this.couponDiscount;
		// 		this.token.set('totalPrice', this.totalPrice);
		// 		this.token.set('totalSubmitPrice', this.totalPrice);
		// 	}else{
		// 		this.token.set('totalPrice', this.totalPrice);
		// 		this.token.set('totalSubmitPrice', this.totalPrice);
		// 	}

		// 	this.percentage = Number(this.token.get('percentage'));
		// 	this.couponName = this.token.get('couponName');

		// 	setTimeout(()=>{
		// 		this.updateCouponValue = {
		// 			couponValue: this.couponName,
		// 			user_id: this.userId['user_id'],
		// 			service: this.token.get('service'),
		// 		}
		// 		this.applyCouponForm.setValue(this.updateCouponValue);
		// 	}, 1);

		// 	this.applyCouponForm.get('couponValue').disable();

		// 	//console.log('applycoupon ' + this.couponDiscount);
		// }

		// if(this.token.get('amountType') == '2'){
		// 	this.couponDiscount = Number(this.token.get('couponDiscount'));
		// 	this.totalPrice = this.totalPrice - this.couponDiscount;
		// 	this.percentage = Number(this.token.get('percentage'));
		// 	this.couponName = this.token.get('couponName');

		// 	setTimeout(()=>{
		// 		this.updateCouponValue = {
		// 			couponValue: this.couponName,
		// 			user_id: this.userId['user_id'],
		// 			service: this.token.get('service'),
		// 		}
		// 		this.applyCouponForm.setValue(this.updateCouponValue);
		// 	}, 1);

		// 	this.applyCouponForm.get('couponValue').disable();

		// 	//console.log('applycoupon ' + this.couponDiscount);
		// }

		// //if(this.token.get('service') == '1'){
		// 	//console.log( this.currentUser );
		// if( this.currentUser.type == '1' ){
		// 	//We apply flat 20% vat
		// 	this.VATPercent = 20;
		// 	this.VATadd = this.totalPrice*this.VATPercent/100;
		// 	this.totalPrice = this.totalPrice + this.totalPrice*this.VATPercent/100;
		// 	this.token.set('totalPrice', this.totalPrice);
		// 	this.token.set('totalSubmitPrice', this.totalPrice);
		// 	this.token.set('taxAmount', this.VATadd);
		// } else {
		// 	//Check vat number
		// 	if( this.currentUser.tva != null && this.currentUser.tva.toLowerCase().substr(0, 2) == 'fr' ){
		// 		//console.log('in');
		// 		this.VATPercent = 20;
		// 		this.VATadd = this.totalPrice*this.VATPercent/100;
		// 		this.totalPrice = this.totalPrice + this.totalPrice*this.VATPercent/100;
		// 		this.token.set('totalPrice', this.totalPrice);
		// 		this.token.set('totalSubmitPrice', this.totalPrice);
		// 		this.token.set('taxAmount', this.VATadd);
		// 	}
		// }
		// this.token.set('totalPrice', this.totalPrice);
		// this.token.set('totalSubmitPrice', this.totalPrice);
	}

	updateOrderSummary(){
		
		if(!this.token.get('event_type')){
			//this.router.navigate(['/merci']);
		}

		if( !this.token.get( 'service' ) ){
			this.toastr.error('Veuillez sélectionner un service');
			this.router.navigate(['/accueil']);
		}


		this.currentUser =  JSON.parse(this.token.get('currentUser'));
		this.userId['user_id'] =  this.token.getUser();
		this.totalPrice = this.price = 0;

		/*if(this.token.get('walletUpdatePrice')){
			this.walletTotal = this.token.get('walletUpdatePrice');
		}else{
		}*/

		console.log( '0 =>' + this.totalPrice );
		
		this.menus = JSON.parse(this.token.get('menu') || '[]');
		if( this.token.get('event_type') != null ){
			//console.log('service 1 true');
			this.totalPrice = Number( this.token.get('service_cost') );
			this.price = Number( this.token.get('service_cost') );
		}

		console.log( '1 =>' + this.totalPrice ); // 0

		//if( this.token.get('service') == '2' ){
			if( this.token.get( 'selected_events' ) ){
				this.SelectedEvents = JSON.parse( this.token.get( 'selected_events' ) ) || [];
			}
			//this.eventData = JSON.parse( this.token.get('eventData') ) || [];
		//}

		if( this.SelectedEvents.length > 0 ){
			var self = this;
			//self.totalPrice = self.price = 0;
			this.MenuPrice = 0;
			jQuery( this.SelectedEvents ).each( function( i, selectedEventData ){
				if( selectedEventData['status'] == 'pending'){
					//console.log( 'Event Price => ' + Number( selectedEventData['price'] ) );
					self.totalPrice = self.totalPrice + Number( selectedEventData['price'] );
					self.price = self.price + Number( selectedEventData['price'] );
					if( typeof selectedEventData.menu !== 'undefined' && selectedEventData.menu.length > 0 ){
						for( let menu of selectedEventData.menu ){
							self.MenuPrice = Number( self.MenuPrice ) + Number( menu.price ) * Number( menu.qty );
							self.totalPrice = Number( self.totalPrice ) + Number( menu.price ) * Number( menu.qty );
						}
					}
				}
			});
			// for( let menu of this.menus ){
			// 	this.MenuPrice = Number( this.MenuPrice ) + Number( menu.price ) * Number( menu.qty );
			// 	this.totalPrice = Number( this.totalPrice ) + Number( menu.price ) * Number( menu.qty );
			// }
			
			// this.couponDiscount = Number(this.token.get('couponDiscount'));
			// this.totalPrice = this.totalPrice - this.couponDiscount;
			this.token.set('menuPrice', this.MenuPrice);
			this.token.set('totalPrice', this.totalPrice);
			//this.walletTotalDesc = Number(this.token.get('walletDiscount'));
			//this.walletUpdatePrice = Number(this.token.get('walletUpdatePrice'));
			/*if(this.totalPrice == 0){
				this.token.set('totalSubmitPrice', this.walletTotalDesc);
			} else {
				this.token.set('totalSubmitPrice', this.totalPrice);
			}*/
		}

		if( this.nomade_service.length > 0 ){
			var self = this;
			self.ServicePrice = 0;
			jQuery( this.nomade_service ).each( function( i, service ){
				let nomadeService = self.nomade_service[i];
				let servicePrice = 0;
				if( nomadeService['type_of_invoice'] == '2' ){
					servicePrice = nomadeService['recurring_price'];
					let todayDate = new Date();
					todayDate.setMonth( todayDate.getMonth() + Number( nomadeService['recurring_months'] ) );
					self.nomade_service[i]['service_until'] = self.datepipe.transform( todayDate, 'yyyy-MM-dd' );
				} else {
					servicePrice = nomadeService['one_time_price'];
				}
				self.ServicePrice = Number( self.ServicePrice ) + Number( servicePrice );
			});

			this.totalPrice = Number( this.totalPrice ) + Number( this.ServicePrice );
		}

		console.log( '2 =>' + this.totalPrice );
		//console.log('After all Render Total Price => '+this.totalPrice);

		//if(this.token.get('service') == '1'){
			//console.log( this.currentUser );
		//if( this.currentUser.type == '1' ){
			//We apply flat 20% vat
			this.VATPercent = 20;
			this.VATadd = this.totalPrice*this.VATPercent/100;
			this.totalPrice = this.totalPrice + this.totalPrice*this.VATPercent/100;
			this.token.set('totalPrice', this.totalPrice);
			this.token.set('totalSubmitPrice', this.totalPrice);
			this.token.set('taxAmount', this.VATadd);
		// } else {
		// 	//Check vat number
		// 	if( this.currentUser.tva != null && this.currentUser.tva.toLowerCase().substr(0, 2) == 'fr' ){
		// 		//console.log('in');
		// 		this.VATPercent = 20;
		// 		this.VATadd = this.totalPrice*this.VATPercent/100;
		// 		this.totalPrice = this.totalPrice + this.totalPrice*this.VATPercent/100;
		// 		this.token.set('totalPrice', this.totalPrice);
		// 		this.token.set('totalSubmitPrice', this.totalPrice);
		// 		this.token.set('taxAmount', this.VATadd);
		// 	}
		// }

		console.log( '3 =>' + this.totalPrice );

		if(this.token.get('amountType') == '1'){
			this.couponDiscount = Number(this.token.get('couponDiscount'));
			this.totalPrice = this.totalPrice - this.couponDiscount;
			//console.log(this.totalPrice);
			if(this.totalPrice > 0){
				this.totalPrice = this.totalPrice - this.couponDiscount;
				this.token.set('totalPrice', this.totalPrice);
				this.token.set('totalSubmitPrice', this.totalPrice);
			}else{
				this.token.set('totalPrice', this.totalPrice);
				this.token.set('totalSubmitPrice', this.totalPrice);
			}

			this.percentage = Number(this.token.get('percentage'));
			this.couponName = this.token.get('couponName');

			setTimeout(()=>{
				this.updateCouponValue = {
					couponValue: this.couponName,
					user_id: this.userId['user_id'],
					service: this.token.get('service'),
				}
				this.applyCouponForm.setValue(this.updateCouponValue);
			}, 1);

			this.applyCouponForm.get('couponValue').disable();

			//console.log('applycoupon ' + this.couponDiscount);
		}

		console.log( '4 =>' + this.totalPrice );

		if(this.token.get('amountType') == '2'){

			this.percentage = Number(this.token.get('percentage'));
			this.couponDiscount = Number(this.token.get('percentage'));
			console.log(this.totalPrice);
			this.couponDiscount = this.totalPrice * this.couponDiscount / 100;
			this.totalPrice = this.totalPrice - this.couponDiscount;
			console.log(this.totalPrice);
			
			
			this.token.set('couponDiscount', this.couponDiscount);
			this.couponName = this.token.get('couponName');

			/*this.couponDiscount = Number(this.token.get('couponDiscount'));
			this.totalPrice = this.totalPrice - this.couponDiscount;
			this.percentage = Number(this.token.get('percentage'));
			this.couponName = this.token.get('couponName');*/

			setTimeout(()=>{
				this.updateCouponValue = {
					couponValue: this.couponName,
					user_id: this.userId['user_id'],
					service: this.token.get('service'),
				}
				this.applyCouponForm.setValue(this.updateCouponValue);
			}, 1);

			this.applyCouponForm.get('couponValue').disable();

			//console.log('applycoupon ' + this.couponDiscount);
		}

		console.log( '5 =>' + this.totalPrice );

		
		this.token.set('totalPrice', this.totalPrice);
		this.token.set('totalSubmitPrice', this.totalPrice);

		//this.appllyWalletBalance();

	}

	ngOnInit() {
		this.setEventData();
	}

	setEventData(){
		var self = this;
		self.eventData = [];
		//this.eventData = [];
		console.log( this.eventData.length );
		this.spaceData = this.cookie.getAll();

		if( this.token.get('event_type') == '30 Jours'){
			let thirtyDaysdata = JSON.parse( this.token.get( 'eventData' ) ) || [];
			if( thirtyDaysdata.length > 0 ){
				thirtyDaysdata = thirtyDaysdata.sort( this.sortEventData );
				let totalLength = thirtyDaysdata.length - 1;
				let eventStartDate = new Date( thirtyDaysdata[0]['start'] );
				let eventOfferEndDate = eventStartDate.setMonth( eventStartDate.getMonth() + 6 );
				this.eventData.push({
					start: thirtyDaysdata[0]['start'],
					end: eventOfferEndDate,
					event_type : '30 Jours',
					service : '1',
					price: this.token.get( 'service_cost' ),
				});
			}
			//this.eventData = JSON.parse(this.token.get('eventData'));
		}

		if( this.token.get('event_type') == 'journée'){
			let onehDaysdata = JSON.parse(this.token.get('eventData')) || [];

			if( onehDaysdata.length > 0 ){
				onehDaysdata = onehDaysdata.sort( this.sortEventData );
				let totalLength = onehDaysdata.length - 1;
				var Alldates = [];
				if(onehDaysdata.length > 0){
					for (var i = 0; i < onehDaysdata.length; i++) {
						Alldates.push(onehDaysdata[i]['start']);
					}	
				}
				//Alldates.join(" et ");
				this.eventData.push({
					start: onehDaysdata[0]['start'],
					end: onehDaysdata[totalLength]['end'],
					event_type : 'journée',
					all_dates : Alldates,
					service : '1',
					price: this.token.get( 'service_cost' ),
				});
			}
		}

		if( this.token.get('event_type') == 'Mois'){
			let monthDaysdata = JSON.parse( this.token.get( 'eventData' ) ) || [] ;
			if( monthDaysdata.length > 0 ){
				monthDaysdata = monthDaysdata.sort( this.sortEventData );
				let totalLength = monthDaysdata.length - 1;
				this.eventData.push({
					start: monthDaysdata[0]['start'],
					end: monthDaysdata[totalLength]['end'],
					event_type : 'Mois',
					service : '1',
					price: this.token.get( 'service_cost' ),
				});
			}
			//this.eventData = JSON.parse(this.token.get('eventData'));
		}
		if( this.token.get('event_type') == 'Dix jours'){
			//console.log(  this.token.get('event_type') );
			let tenDaysdata = JSON.parse( this.token.get( 'eventData' ) ) || [];
			//console.log(tenDaysdata.length);
			if( tenDaysdata.length > 0 ){
				tenDaysdata = tenDaysdata.sort( this.sortEventData );
				let totalLength = tenDaysdata.length - 1;
				let eventStartDate = new Date( tenDaysdata[0]['start'] );
				let eventOfferEndDate = eventStartDate.setMonth( eventStartDate.getMonth() + 2 );
				this.eventData.push({
					start: tenDaysdata[0]['start'],
					end: eventOfferEndDate,
					event_type : 'Dix jours',
					service : '1',
					price: this.token.get( 'service_cost' ),
				});
			}
			//this.eventData = JSON.parse(this.token.get('eventData'));
		}

		//if(this.token.get('service') == '2'){
			if( this.SelectedEvents.length > 0 ){
				var self = this;
				jQuery( this.SelectedEvents ).each( function( i, selectedEventData ){
					if( selectedEventData['status'] == 'pending'){
						self.eventData.push({
	                        start: selectedEventData['event_start_date'],
	                        end: selectedEventData['event_end_date'],
	                        price: selectedEventData['price'],
	                        event_day_type: selectedEventData['event_day_type'],
	                        item_key: selectedEventData['item_key'],
	                        menu: selectedEventData['menu'],
	                        room_name: selectedEventData['room_name'],
	                        room_size: selectedEventData['room_size'],
	                        room_image: selectedEventData['room_image'],
	                        room_desc: selectedEventData['room_desc'],
	                        room_id: selectedEventData['room_id'],
	                        service : '2'
	                    });
	                }
				});
			}
			//this.eventData = JSON.parse(this.token.get('eventData')) || [];
		//}

		console.log( this.eventData.length );
		this.space = JSON.parse(this.spaceData.space || '[]');
	}

	getAllRefundOrder(){
		this.walletData['user_id'] = this.token.getUser();
		this.userservice.getRefundOrder(this.walletData).subscribe(
			data => {
				if(data['status'] == 'false'){
					this.toastr.error(data['message']);
				}
				if(data['status'] == 'true'){
					this.walletTotal = data['walletTotal'];

					this.walletTotalDesc = Number( this.token.get( 'walletDiscount' ) );
					this.walletUpdatePrice = Number( this.token.get( 'walletUpdatePrice' ) );

					if( this.walletTotalDesc && this.walletTotalDesc != 0 ){
						this.walletTotal = this.walletTotal - Number( this.walletTotalDesc );
						this.totalPrice = Number( this.totalPrice ) - Number( this.walletTotalDesc );
						this.newWalletPrice = this.walletTotal;

						this.token.set( 'totalPrice', this.totalPrice );
						this.token.set( 'totalSubmitPrice', this.totalPrice );

						//console.log( 'Total Price after apply wallet => '+ this.totalPrice );
						//console.log( 'Total wallet price after walletTotalDesc => '+ this.walletTotal );
						this.walletApply = true;
					} else {
						this.newWalletPrice = this.walletTotal;
						//console.log('No wallet amount found previous.');
					}

					console.log( '6 =>'+ this.totalPrice );

					if( this.totalPrice == 0 ){
						//console.log('enter disabled')
						this.applyCouponForm.get('couponValue').disable();
					}
				}
			},
			error => {
				this.toastr.error(error.error.error);
			}
		);
	}

	updateWalletBalance(){
		
		//this.walletTotal = 53.76;
		console.log( 'walletTotal' + this.walletTotal );

		this.walletTotalDesc = Number( this.token.get( 'walletDiscount' ) ); // 53.76
		this.walletUpdatePrice = Number( this.token.get( 'walletUpdatePrice' ) ); // 0

		if( this.walletTotalDesc && this.walletTotalDesc != 0 ){
			this.walletTotal = Number( this.walletTotal ) - Number( this.walletTotalDesc ); // 53.76 - 53.76 = 0
			this.totalPrice = Number( this.totalPrice ) - Number( this.walletTotalDesc ); // 
			this.newWalletPrice = this.walletTotal;

			this.token.set( 'totalPrice', this.totalPrice );
			this.token.set( 'totalSubmitPrice', this.totalPrice );

			console.log( 'Total Price after apply wallet => '+ this.totalPrice );
			console.log( 'Total wallet price after walletTotalDesc => '+ this.walletTotal );
			this.walletApply = true;
		} else {
			this.newWalletPrice = this.walletTotal;
			//console.log('No wallet amount found previous.');
		}

		/*if( this.couponDiscount && this.couponDiscount > 0 ){
			this.newWalletPrice = this.newWalletPrice - this.couponDiscount;
			this.walletTotal = this.walletTotal - this.couponDiscount;
		}*/

		if( this.totalPrice == 0 ){
			//console.log('enter disabled')
			this.applyCouponForm.get('couponValue').disable();
		} else {
			this.applyCouponForm.get('couponValue').enable();
		}
			
	}

	checkWalletBalance( action ){
		var appliedWalletBalance = Number( this.token.get( 'walletDiscount' ) ); // 40.8
		var remainWalletBalance = Number( this.token.get( 'walletUpdatePrice' ) ); // 12.96
		if( action == 'plus' ){
			if( appliedWalletBalance && appliedWalletBalance != 0 ){
				console.log( 'appliedWalletBalance not null' );
				if( remainWalletBalance > 0 ){
					var totalWalletBalance = Number( appliedWalletBalance + remainWalletBalance );
					var totalNewPrice = this.totalPrice;
					var newRemainWalletBalance = Number( totalWalletBalance - totalNewPrice );
					if( newRemainWalletBalance >= 0 ){
						var newAppliedWalletBalance = Number( totalWalletBalance - newRemainWalletBalance );
						this.walletTotal = totalWalletBalance;
						this.token.set( 'walletDiscount', newAppliedWalletBalance );
						this.token.set( 'walletUpdatePrice', newRemainWalletBalance );
					} else {
						newRemainWalletBalance = Number( totalWalletBalance - appliedWalletBalance );
						var newAppliedWalletBalance = Number( appliedWalletBalance + newRemainWalletBalance );
						this.walletTotal = totalWalletBalance;
						this.token.set( 'walletDiscount', newAppliedWalletBalance );
						this.token.set( 'walletUpdatePrice', 0 );
					}

					console.log( 'Total New Price '+totalNewPrice );
					console.log( 'totalWalletBalance '+totalWalletBalance );
					console.log( 'totalNewPrice '+totalNewPrice );
					console.log( 'newRemainWalletBalance '+newRemainWalletBalance );
					console.log( 'newAppliedWalletBalance '+newAppliedWalletBalance );
					console.log( 'walletTotal '+this.walletTotal );
				} else {
					this.walletTotal = Number( appliedWalletBalance + remainWalletBalance );
				}
				this.updateWalletBalance();
			}
		} else {
			console.log( 'minus wallet' );
			var cartTotalPrice = this.totalPrice;
			var totalWalletBalance = Number( appliedWalletBalance + remainWalletBalance );
			if( appliedWalletBalance && appliedWalletBalance != 0 && cartTotalPrice < totalWalletBalance ){
				var newRemainWalletBalance = totalWalletBalance - cartTotalPrice;
				var newAppliedWalletBalance = totalWalletBalance - newRemainWalletBalance;
				this.token.set( 'walletDiscount', newAppliedWalletBalance );
				this.token.set( 'walletUpdatePrice', newRemainWalletBalance );
				this.walletTotal = Number( newRemainWalletBalance + newAppliedWalletBalance );
				//this.walletTotal = newWalletBalance;

				if( cartTotalPrice <= appliedWalletBalance ){
					console.log( 'cart <= appliedWalletBalance' );
					
				} else {
					console.log( 'cart > appliedWalletBalance' );
				}
			} else {
				this.walletTotal = Number( appliedWalletBalance + remainWalletBalance );
			}
			this.updateWalletBalance();
		}
		
	}
	
	sortEventData( a, b ){
		//return eventDateData;
		//eventDateData.sort(function(a,b){
		  	var dateA = new Date(a.start).getTime();
		    var dateB = new Date(b.start).getTime();
		    return dateA > dateB ? 1 : -1;
		//});
	}

	appllyWalletBalance(){
		//console.log( this.totalPrice );
		//console.log( this.walletTotal );
		if( this.totalPrice >= this.walletTotal ){
			this.walletUpdatePrice = 0;
			this.walletTotalDesc = this.walletTotal;
			this.newWalletPrice = this.walletUpdatePrice;
			this.totalPrice = Number( this.totalPrice ) - Number( this.walletTotal );

			//console.log( 'Total Price =>'+this.totalPrice );
			//console.log( 'Total walletUpdatePrice =>'+this.walletUpdatePrice );
			//console.log( 'Total walletTotalDesc =>'+this.walletTotalDesc );
			//console.log( 'New wallet price => '+ this.newWalletPrice );

			this.token.set( 'totalPrice', this.totalPrice );
			this.token.set('totalSubmitPrice', this.totalPrice);
			this.token.set( 'walletDiscount', this.walletTotalDesc );
			this.token.set( 'price', this.price );
			this.token.set( 'walletUpdatePrice', this.walletUpdatePrice );

			$('.walletDiscount').removeClass('hideDesc');
			$('.applyBtn').addClass('hideDesc');
			$('.cancelApplyBtn').removeClass('hideDesc');
			if( this.totalPrice == 0 ){
				$('.coupon-box input[name="couponValue"]').attr('disabled', true);
				$('.coupon-box button').attr('disabled', true);

			}
			this.walletApply = true;
		} else {
			//console.log('here');
			this.walletUpdatePrice = Number( this.walletTotal ) - Number( this.totalPrice ); // 66 - 65 = 1
			this.walletTotalDesc = this.walletTotal - this.walletUpdatePrice; // 66 - 1 = 65
			this.newWalletPrice = this.walletTotal - this.walletTotalDesc;
			this.totalPrice = 0;

			//console.log( 'Total Price =>'+this.totalPrice );
			//console.log( 'Total walletUpdatePrice =>'+this.walletUpdatePrice );
			//console.log( 'Total walletTotalDesc =>'+this.walletTotalDesc );
			//console.log( 'New wallet price => '+ this.newWalletPrice );

			this.token.set( 'totalPrice', this.totalPrice );
			this.token.set( 'totalSubmitPrice', this.totalPrice );
			this.token.set( 'walletDiscount', this.walletTotalDesc );
			this.token.set( 'price', this.price );
			this.token.set( 'walletUpdatePrice', this.walletUpdatePrice );

			$('.walletDiscount').removeClass('hideDesc');
			$('.applyBtn').addClass('hideDesc');
			$('.cancelApplyBtn').removeClass('hideDesc');
			if( this.totalPrice == 0 ){
				$('.coupon-box input[name="couponValue"]').attr('disabled', true);
				$('.coupon-box button').attr('disabled', true);
			}
			this.walletApply = true;
		}
	}

	cancelWalletBalance(){
		console.log( 'before '+this.totalPrice );
		this.totalPrice = this.totalPrice + Number( this.walletTotalDesc ); // 0 + 32
		console.log( 'after ' + this.totalPrice );
		this.walletUpdatePrice =  Number( this.walletTotalDesc ) + Number( this.walletUpdatePrice );
		this.walletTotal = this.walletUpdatePrice;
		this.newWalletPrice = this.walletTotal;
		this.walletTotalDesc = this.totalPrice - this.walletTotalDesc;


		this.token.set('totalPrice', this.totalPrice);
		this.token.set('walletUpdatePrice', this.walletUpdatePrice);
		this.token.set('price', this.price);
		this.token.set('totalSubmitPrice', this.totalPrice);
		// this.token.set('totalSubmitPrice', this.totalPrice);
		this.token.remove('walletDiscount');


		//console.log('cancel ' + this.totalPrice)
		//console.log('totalPrice ' + this.totalPrice)
		//console.log('walletUpdatePrice ' + this.walletUpdatePrice)

		$('.walletDiscount').addClass('hideDesc');
		$('.applyBtn').removeClass('hideDesc');
		$('.cancelApplyBtn').addClass('hideDesc');
		$('.coupon-box input[name="couponValue"]').attr('disabled', false);
		$('.coupon-box button').attr('disabled', false);
		this.walletApply = false;
	}

	applyCoupon(){
		this.applyCouponForm.value.user_id = this.token.getUser();
		this.applyCouponForm.value.service = this.token.get('service');
		this.userservice.applyCoupon(this.applyCouponForm.value).subscribe(
			data => {
				if(data['status'] == 'false'){
					this.toastr.error("Promocode non valide.");
				}
				if(data['status'] == 'true'){
					if(data['amount'].amount_type == 1){

						this.couponDiscount = data['amount'].amount;
						console.log(data['amount']);
						console.log(this.totalPrice);
						this.totalPrice = this.totalPrice - this.couponDiscount;
						console.log(this.totalPrice);
						this.token.set('totalPrice', this.totalPrice);
						this.token.set('totalSubmitPrice', this.totalPrice);
						this.token.set('amountType', data['amount'].amount_type);
						this.token.set('couponName', data['amount'].name);
						this.token.set('couponId', data['amount'].id);
						this.token.set('couponDiscount', this.couponDiscount);

						$('.coupon-box input[name="couponValue"]').attr('disabled', true);
						$('.coupon-box button').attr('disabled', true);

						this.toastr.success("Appliquer le coupon avec succès.");
					}else if(data['amount'].amount_type == 2){

						this.percentage = data['amount'].percentage;
						this.couponDiscount = data['amount'].percentage;
						console.log(this.totalPrice);
						this.couponDiscount = this.totalPrice * this.couponDiscount / 100;
						this.totalPrice = this.totalPrice - this.couponDiscount;
						console.log(this.totalPrice);
						this.token.set('totalPrice', this.totalPrice);
						this.token.set('totalSubmitPrice', this.totalPrice);
						this.token.set('amountType', data['amount'].amount_type);
						this.token.set('couponName', data['amount'].name);
						this.token.set('couponId', data['amount'].id);
						this.token.set('couponDiscount', this.couponDiscount);
						this.token.set('percentage', data['amount'].percentage);

						$('.coupon-box input[name="couponValue"]').attr('disabled', true);
						$('.coupon-box button').attr('disabled', true);

						this.toastr.success("Appliquer le coupon avec succès.");
					}else{
						this.toastr.error("Promocode non valide.");
					}
				}
			},
			error => {
				this.toastr.error(error.error.error);
			}
			);
	}

	trash(){
		jQuery('#trashConfirm').modal();
	}

	trashRtype( event ){
		jQuery('#trashRtype').val( event );
		jQuery('#trashRConfirm').modal();
	}

	trashYes( service ){
		if( service == '1' ){
			this.token.remove('event_type')
			this.token.remove('contract')
			//this.token.remove('room_size')
			this.token.remove('hour_price')
			//this.token.remove('half_price')
			//this.token.remove('full_price')
			this.token.remove('eventData')
			this.token.remove('nomade_service')
			//this.token.remove('menuPrice')
			//this.token.remove('menu')
			//this.router.navigate(['/accueil']);
			this.ServicePrice = 0;
			jQuery('#trashConfirm').modal('hide');
		}

		if( service == '2' ){
			var trashRtype = jQuery( '#trashRtype' ).val();
			var events = JSON.parse( this.token.get( 'selected_events' ) || '[]' );

			if( events.length > 0 ){
				for( let i=0; i < events.length; i++ ){
					var getId = events[i].item_key;
					//console.log(events[i]);
					if( getId == trashRtype ){
						var index = events.indexOf( getId );
						events.splice( i, 1 );
						this.token.set( 'selected_events', JSON.stringify( events ) );
					}
				}
			} else {
				//this.token.remove('event_type')
				//this.token.remove('contract')
				this.token.remove('room_size')
				this.token.remove('room_type')
				this.token.remove('hour_price')
				this.token.remove('half_price')
				this.token.remove('full_price')
				//this.token.remove('eventData')
				this.token.remove('menuPrice')
				this.token.remove('menu')
				this.token.remove('selected_events')
				//this.router.navigate(['/accueil']);
			}
			//this.getMenu();
			jQuery('#trashRConfirm').modal('hide');
		}
		this.getEvents();
		this.token.set( 'totalPrice', this.totalPrice );
	}

	edit(){
		this.token.set('editMainOrder', true);

		if(this.token.get('service') == '1'){
			this.router.navigate(['/espace/calendrier', this.token.get('event_type')]);
		}
		if(this.token.get('service') == '2'){
			this.router.navigate(['/salle-de-réunion/calendrier']);
		}
	}

	editContract(menu){
		this.token.set('editMenuOrder', true);
		this.token.set('editMenu', JSON.stringify(menu));
		this.router.navigate(['/catégorie']);
	}

	/*deleteContractAsk(menu){
		this.deleteContractDetail = menu;
		jQuery('#trashConfirmContract').modal();
	}

	deleteContract(){
		var getMenu = JSON.parse(this.token.get('menu'));
		for( let i=0; i < getMenu.length; i++ ){

			var getId = getMenu[i].id;
			var Id = this.deleteContractDetail.id;

			if(getId == Id){
				var index = getMenu.indexOf(getId);
				getMenu.splice(i, 1);
				this.token.set('menu', JSON.stringify(getMenu));
			}
		}
		this.getMenu();
		jQuery('#trashConfirmContract').modal('hide');
		this.token.set('totalPrice', this.totalPrice);
	}*/

	deleteServiceAsk( service ){
		console.log( 'deleted service id => '+service.id );
		this.deleteService = service.id;
		jQuery('#trashConfirmService').modal();
	}

	deleteEventMenuAsk(itemKey, menu){
		this.deleteContractDetail = menu;
		this.itemKey = itemKey;
		jQuery('#trashConfirmContract').modal();
	}

	deleteEventService(){
		var getSerivceLocal = JSON.parse( this.token.get( 'nomade_service' ) ) || [];
		var newServicePrice = 0;
		var self = this;
		console.log(self.deleteService);
		if( getSerivceLocal.length > 0 ){
			getSerivceLocal.forEach((item, itemIndex) => {				
				if( item.id == self.deleteService ){
					getSerivceLocal.splice( itemIndex, 1 )
				}
		  	})
		}
		//this.token.remove( 'nomade_service' );
		this.nomade_service = getSerivceLocal;
		this.token.set( 'nomade_service', JSON.stringify( this.nomade_service ) );
		this.ServicePrice = 0;
		this.getEvents();
        jQuery('#trashConfirmService').modal('hide');
	}

	deleteEventMenu(){
		
		var all_events = JSON.parse(this.store.getAllEventSelection());

		for (var i = all_events.length - 1; i >= 0; --i) {
            if (all_events[i].item_key == this.itemKey) {
            	for( let j=0; j < all_events[i].menu.length; j++ ){
					var menuId = all_events[i].menu[j].id;
					var deleteId = this.deleteContractDetail.id;
					if(menuId == deleteId){
						var index = all_events[i].menu.indexOf(menuId);
						all_events[i].menu.splice(j, 1);
					}
				}
            }
        }


        this.store.SaveAllEventSelection( all_events );
        this.getEvents();
        jQuery('#trashConfirmContract').modal('hide');

	}

	getEvents(){
		//this.eventData = [];
		this.SelectedEvents = JSON.parse( this.token.get( 'selected_events' ) ) || [];
		this.nomade_service = JSON.parse( this.token.get( 'nomade_service' ) ) || [];
		this.setEventData();
		this.updateOrderSummary();
		this.checkWalletBalance( 'minus' );
		this.removeLocalStorageItem();
	}

	removeLocalStorageItem(){
		if( this.eventData.length < 1 ){
			setTimeout(()=>{

				this.token.remove('menu');
				this.token.remove('eventData');
				this.token.remove('service');
				this.token.remove('event_type');
				this.token.remove('room_size');
				this.token.remove('full_price');
				this.token.remove('hour_price');
				this.token.remove('half_price');
				this.token.remove('nomade_service');
				this.token.remove('menuPrice');
				this.token.remove('totalPrice');
				this.token.remove('totalSubmitPrice');
				this.token.remove('walletTotalDesc');
				this.token.remove('service_cost');
				this.token.remove('service_type');
				this.token.remove('fetchEvent');
				this.token.remove('walletDiscount');
				this.token.remove('price');
				this.token.remove('walletUpdatePrice');
				this.token.remove('contract');
				this.token.remove('amountType');
				this.token.remove('percentage');
				this.token.remove('couponId');
				this.token.remove('couponDiscount');
				this.token.remove('couponName');
				this.token.remove('taxAmount');
				this.token.remove('selected_events');
				this.token.remove('room_type');
				this.token.remove('item_key');
				this.token.remove('event_key');
				this.token.remove('CurrentItemKey');
				this.token.remove('selected_events_temp');
				this.toastr.error('Veuillez sélectionner un service');
				this.router.navigate(['/accueil']);

			}, 1);
		}	
	}

	getMenu(){
		this.menus = JSON.parse(this.token.get('menu') || '[]');

		if( this.token.get('event_type') == 'toutes les heures'){
			this.totalPrice = Number(this.token.get('hour_price'));
			this.price = this.token.get('hour_price');
			this.MenuPrice = 0;
			for(let menu of this.menus){
				this.MenuPrice = Number(this.MenuPrice) + Number(menu.price) * Number(menu.qty);
				this.totalPrice = Number(this.totalPrice) + Number(menu.price) * Number(menu.qty);	
				this.token.set('menuPrice', this.MenuPrice);
			}
		}

		if( this.token.get('event_type') == 'demi-journée'){
			this.totalPrice = Number(this.token.get('half_price'));
			this.price = this.token.get('half_price');
			this.MenuPrice = 0;
			for(let menu of this.menus){
				this.MenuPrice = Number(this.MenuPrice) + Number(menu.price) * Number(menu.qty);
				this.totalPrice = Number(this.totalPrice) + Number(menu.price) * Number(menu.qty);	
				this.token.set('menuPrice', this.MenuPrice);
			}
		}

		if( this.token.get('event_type') == 'journée complète'){
			this.totalPrice = Number(this.token.get('full_price'));
			this.price = this.token.get('full_price');
			this.MenuPrice = 0;
			for(let menu of this.menus){
				this.MenuPrice = Number(this.MenuPrice) + Number(menu.price) * Number(menu.qty);
				this.totalPrice = Number(this.totalPrice) + Number(menu.price) * Number(menu.qty);
				this.token.set('menuPrice', this.MenuPrice);	
			}
		}

		this.walletTotal = this.token.get('walletDiscount') || 0.00;
	}

	isSameMonthBooking( event ){
		let start_month = new Date( event['start'] ).getMonth();
		let end_month = new Date( event['end'] ).getMonth();
		return start_month == end_month;
	}

	minusQty( item_key, menu, event ){
		var minus_qty 	  = $(event.target),
			food_menu_box = minus_qty.parents('.food-menu-box'),
			qty_input 	  = food_menu_box.find('input[type="number"]'),
			qty 		  = parseInt( qty_input.val() ),
			qty 		  = qty - 1,
			price         = qty * parseFloat( qty_input.data('price') );

			if(qty >= 1){
				qty_input.val(qty);
				food_menu_box.find('.price').text(price.toFixed(2).replace(".", ",")+ ' € HT');
				this.updateQty( item_key, menu, qty, 'minus');
			}

	}

	plusQty( item_key, menu, event ){
		var plus_qty 	  = $(event.target),
			food_menu_box = plus_qty.parents('.food-menu-box'),
			qty_input 	  = food_menu_box.find('input[type="number"]'),
			qty 		  = parseInt( qty_input.val() ),
			qty 		  = qty + 1,
			price         = qty * parseFloat( qty_input.data('price') );

			qty_input.val(qty);
			food_menu_box.find('.price').text(price.toFixed(2).replace(".", ",")+ ' € HT' );
			this.updateQty( item_key, menu, qty, 'plus');
	}	

	updateQty( item_key, menuItem, qty, action ){
		
		var selected_menu = JSON.parse(this.token.get('menu') ) || [] ;
		var selected_events = JSON.parse(this.token.get('selected_events')) || [];

		if(selected_events.length > 0){
			for (var i = 0; i < selected_events.length; i++) {
				if( selected_events[i].item_key == item_key){
					if(selected_events[i].menu.length > 0){
						for (var j = 0; j < selected_events[i].menu.length; j++) {
							if(menuItem.menu_item_id == selected_events[i].menu[j].menu_item_id){
								selected_events[i].menu[j].qty = parseInt(qty);
							}
						}
					}
				}
			}
		}

		this.store.SaveAllEventSelection( selected_events );

		if(selected_menu.length > 0){
			selected_menu.forEach((item, index) => {
		  		if( menuItem.menu_item_id == item.menu_item_id ){
		  			item.qty = parseInt( qty )
		  		}
		  	})
		}

		this.store.setMenu( selected_menu );

		this.updateOrderSummary();
		this.checkWalletBalance( action );
		//this.updateWalletBalance();
	}

	services_can_edit(){
		
    	if( this.token.get('editOrder') == '1' ){
    		if( this.token.get('services_can_edit') == 'true' ){
	    		return true;
	    	}
	    	return false;
    	}

    	return true;

		//return this.token.get('services_can_edit');
     //    var editOrder = this.token.get('editOrder');
     //    if( editOrder ){
     //    	var edit_order_created_at = this.token.get('edit_order_created_at');
     //    	if( edit_order_created_at != '' ){
		   //      let order_date   = moment( edit_order_created_at );
		   //      let current_date = moment();
		   //      let duration     = moment.duration( current_date.diff( order_date ) );
		   //      let hours        = Math.round( duration.asHours() * 100 ) / 100;
		   //      if( hours > 18 ){
		   //          return false;
		   //      }
		   //  }
	    // }
     //    return true;
    }

    items_can_edit(){
    	//console.log(this.token.get('items_can_edit'));
    	
    	if( this.token.get('editOrder') == '1' ){
    		if( this.token.get('items_can_edit') == 'true' ){
	    		return true;
	    	}
	    	return false;
    	}

    	return true;
		//return this.token.get('items_can_edit');
     //    var editOrder = this.token.get('editOrder');
     //    if( editOrder ){
     //    	var edit_order_created_at = this.token.get('edit_order_created_at');
     //    	if( edit_order_created_at != '' ){
		   //      let order_date   = moment( edit_order_created_at );
		   //      let current_date = moment();
		   //      let duration     = moment.duration( current_date.diff( order_date ) );
		   //      let hours        = Math.round( duration.asHours() * 100 ) / 100;
		   //      if( hours > 18 ){
		   //          return false;
		   //      }
		   //  }
	    // }
     //    return true;



    }

    SelectFoodMenu( service, roomsize, roomtype, type, date ){
    	var event_key      = service+roomsize+roomtype+date.replace(/-/g,'');
        var item_key       = service+roomsize+roomtype+type+date.replace(/-/g,'');

        this.store.setCurrentEventKey( event_key );
        this.store.setCurrentItemKey( item_key );
        this.store.set('CurrentItemKey',item_key);


        var allEvents = JSON.parse(this.store.GetEventSelection()) || [];
                
        var allEvents = allEvents.filter(function (el) {
          return el != null;
        });

        if ( allEvents.length > 0) {
            for (let i = 0; i < allEvents.length; i++) {
            	if( allEvents[i].item_key == item_key && allEvents[i].status == 'pending' ){                    
                    if( allEvents[i].order_fooding_menus !== undefined && allEvents[i].order_fooding_menus.length > 0 ){

                        var order_fooding_menus = [];
                        var MenuPrice = 0;

                        for (let j = 0; j < allEvents[i].order_fooding_menus.length; j++) {
                            
                            order_fooding_menus.push({
                                id: allEvents[i].order_fooding_menus[j].id,
                                contractor_id: allEvents[i].order_fooding_menus[j].contractor_id,
                                menu_name: allEvents[i].order_fooding_menus[j].menu_name ,
                                image: allEvents[i].order_fooding_menus[j].image ,
                                description: allEvents[i].order_fooding_menus[j].description,
                                price: allEvents[i].order_fooding_menus[j].price,
                                created_at: allEvents[i].order_fooding_menus[j].created_at,
                                updated_at: allEvents[i].order_fooding_menus[j].updated_at,
                                qty: allEvents[i].order_fooding_menus[j].qty,
                                category_id: allEvents[i].order_fooding_menus[j].category_id,
                                category_name: allEvents[i].order_fooding_menus[j].category_name,
                                menu_item_id: allEvents[i].order_fooding_menus[j].menu_item_id,
                                order_fooding_category_id: allEvents[i].order_fooding_menus[j].order_fooding_category_id
                            });
                            
                            MenuPrice = Number( MenuPrice ) + Number( allEvents[i].order_fooding_menus[j].price ) * Number( allEvents[i].order_fooding_menus[j].qty );
                        }

                        this.store.setMenu( order_fooding_menus );
                        this.token.set('menuPrice', MenuPrice);   
                    }
                }
            }
        }

        this.router.navigate(['/catégorie']);

    }
}