export class Domiciliation{
    
    public fname: string;
    public lname: string;
    public country_code: string;
    public pincode: string;
    public number: string;
    public social_reason: string;
    public email: string;
   
    public duration: string;
    public options: string; 
}