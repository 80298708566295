import { Component, ViewChild, OnInit, NgZone } from '@angular/core';

import { SpaceService } from '../../service/space.service';
import { AuthenticationService } from '../../service/authentication.service';
import { TokenService } from '../../service/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../service/user.service';
import { StoreService } from '../../service/store.service';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import * as moment from 'moment';
declare var jQuery: any;

@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.css']
})
export class SpaceComponent implements OnInit {

    time = Array();
    // public addEventForm: FormGroup;
    addEventForm = this.fb.group({
        equipements: [],
        event_date: []
    })

    eventData: any = {};
    startTime: NgbTimeStruct = {hour: 8, minute: 0, second:0}
    endTime: NgbTimeStruct = {hour: 12, minute: 0, second:0}
    orderType: any = {};
    eventArray: any = [];
    nextPrevArray: any = [];
    eventDataThirtyDay = [];
    eventDataTenDay = [];
    eventDataOneDay = [];
    eventDataOneMonth = [];
    eventDataHour = [];
    eventDataHalfDay = [];
    isSelectedEvent = [];
    fetchAllEvent: any;
    eventTypeName: any;
    full_day_label: any = 'J'; //Journée entière';
    morning_label: any = 'M'; //Matin';
    afternoon_label: any = 'A'; //Après-midi';
    unavailable_label: any  = 'F'; //Fermé
    unavailable_class: any = 'unavailable';
    service_cost: any = 0;
    id: any;


    constructor(
        private router: Router,
        private userservice: UserService,
        private store: StoreService,
        public fb: FormBuilder,
        private auth: AuthenticationService,
        private toastr: ToastrService,
        private spaceservice: SpaceService,
        public datepipe: DatePipe,
        private cookie: CookieService,
        private token: TokenService,
        private zone: NgZone
    ) { 
        this.userservice.loadScript();    
        //console.log( this.store.getSelectedService() );
        this.fetchEvent();
    }

     ngOnInit() {

        this.id = this.getUrlParameter('id');
        //console.log(this.id);
        if( this.id != '' ){
            var elmId = this.id;
            this.token.set('service', 1);
            setTimeout(function(){
                let elem = document.getElementById( elmId );
                //console.log(elem);
                if( elem != null ){
                    let evt = new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    });
                    elem.dispatchEvent(evt);
                }
            }, 1000 );
        }

         //console.log('ngOnInit');
        // var getEvent = JSON.parse(this.cookie.get('eventData') || '[]');
        // var selectSpace = JSON.parse(this.cookie.get('space') || '[]');
        var self = this;
        var icon = "X";
        jQuery('#calendar').fullCalendar({
            themeSystem: 'bootstrap4',
            businessHours: false,
            defaultView: 'month',
            editable: true,
            header: {
                left: 'title',
                center: '',
                right: 'prev,next'
            },
            week: {
                dow: 1,
                doy: 4
            },
            buttonText: {
                today: "Aujourd'hui",
                year: "Année",
                month: "Mois",
                week: "Semaine",
                day: "Jour",
                list: "Mon planning"
            },
            firstDay: '1',
            weekLabel: "Sem.",
            monthNames: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
            monthNamesShort: ['Jan','Fév','Mar','Avr','Mai','Jui','Jui','Aoû','Sep','Oct','Nov','Déc'],
            dayNames: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
            dayNamesShort:["Dim.","Lun.","Mar.","Mer.","Jeu.","Ven.","Sam."],
            allDayHtml: "Toute la<br/>journée",
            eventLimitText: "en plus",
            noEventsMessage: "Aucun événement à afficher",
            eventAfterAllRender: function(view){
                console.log('view');
                jQuery(this).html(' ');
                jQuery('.event_selection').remove();

                var today = new Date();
                var dd    = String(today.getDate()).padStart(2, '0');
                var mm    = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy  = today.getFullYear();
                    today = new Date(yyyy+'-'+mm+'-'+dd); // today date

                jQuery('#calendar .fc-day.fc-widget-content').each(function(i) {

                    var html = '';

                    // Fullcalendar includes a date data element on each day.
                    var date          = jQuery(this).data('date');
                    var selected_date = new Date(date);

                    // prevent to past dates.
                    if(selected_date.getTime() < today.getTime()){
                        return;
                    }
                    
                    if( !self.checkSelectDayIsAvailable(date) ){
                        html = '<div class="event_selection foo">';
                            html += self.unavailableDayHtml(date);
                        html += '</div>';
                        //console.log(self.unavailable_class);
                        jQuery(this).append(html).addClass(self.unavailable_class);
                        if( self.getUnavailableMsg(date) ){
                            var unavailableFullMsg = self.getUnavailableFullMsg( date );
                            //console.log( jQuery(this) );
                            jQuery(this).attr( "data-tooltip", unavailableFullMsg );
                        }
                    }else{
                        jQuery(this).append(html);
                    }


                });
            },
            eventRender: function(event, element) {
                console.log('eventRender');
                // element.append( "<span class='closeon'>"+icon+"</span>" );
                /*if(event.className[0] != 'fetchEvent'){
                    element.find('.fc-content').append("<span class='closeon' style='font-weight:900; color:white; font-size:10px; float:right;'>X</span>")
                }

                // var calendarEvents = [];    
                // calendarEvents.push(event);

                // console.log(calendarEvents);

                element.find('.closeon').click(function() {

                    //console.log(calendarEvents); return;

                    var event_date = JSON.parse(self.token.get('eventData'));
                    //var event_date2 = JSON.parse(self.token.get('eventData'));
                    //console.log(event_date);
                    for( let i=0; i < event_date.length; i++ ){
                        //console.log(event_date[i].id);
                        /*var startDate = self.datepipe.transform(event_date[i].start, 'yyyy-MM-dd');
                        var startDateEvent = self.datepipe.transform(calendarEvents[0].source.events[i].start._i, 'yyyy-MM-dd');
                        var endDate = self.datepipe.transform(event_date[i].end, 'yyyy-MM-dd');
                        var endDateEvent = self.datepipe.transform(calendarEvents[0].source.events[i].end._i, 'yyyy-MM-dd');*/
                        //if(startDate === startDateEvent && endDate === endDateEvent){
                            // console.log(startDate +'==='+ startDateEvent +'==='+ endDate +'==='+ endDateEvent); 
                            // console.log(calendarEvents[0].source.events[i]._id);*/
                            /*jQuery('#calendar').fullCalendar('removeEvents', event_date[i].id);*/
                            //return;
                            // var index = event_date2.indexOf(event_date[i].id);
                            // event_date2.splice(i, 1);
                            // self.eventDataThirtyDay = event_date;
                            // self.eventDataTenDay = event_date;
                            // self.eventDataOneDay = event_date;
                            //self.eventDataOneMonth = event_date;
                            // self.eventDataHour = event_date;
                            // self.eventDataHalfDay = event_date;
                            //self.token.set('eventData', JSON.stringify(event_date));
                            
                        //}
                    /*}
                    //console.log(self.eventDataOneMonth);
                    self.eventDataOneMonth = [];
                    self.token.set('eventData', JSON.stringify( new Array() ));
                    jQuery('#calendar').fullCalendar('removeEvents');
                    // jQuery('#calendar').fullCalendar('rerenderEvents');
                    // jQuery('#calendar').fullCalendar('refetchEvents');
                    // jQuery('#calendar').fullCalendar('refetchEventSources');

                    self.fetchAllEvent = JSON.parse(self.token.get('fetchEvent'));
                    jQuery('#calendar').fullCalendar('addEventSource', self.fetchAllEvent);


                });*/

                // element.append( "<span class='closeon'>"+icon+"</span>" );
                /*console.log( element );
                console.log( event );*/
                let current_date = self.datepipe.transform(event.start._i, 'yyyy-MM-dd');
                
                //$( '[data-date="'+current_date+'"].fc-day' ).addClass('full_day');
                var d = moment( current_date );
                if( d.isoWeekday() != 6 && d.isoWeekday() != 7 ){
                    //event is already booked.
                    $( '[data-date="'+current_date+'"].fc-day' ).addClass('full_day');
                }
                $( '[data-date="'+current_date+'"].fc-day-number' ).addClass('date-highlight');
                if( event.className[0] != 'fetchEvent' ){
                    if( self.checkSelectDayIsAvailable( current_date ) ){
                        element.find('.fc-content').append("<span class='closeon' style='font-weight:900; color:white; font-size:10px; float:right;'>X</span>");
                    }
                    //element.parent('td').addClass('full_day');
                } else {

                    // var currentEvent = self.token.get('service_type');
                    // if( currentEvent == 'Mois' ){
                    //     console.log( current_date );
                        var d = moment( current_date );
                        if( d.isoWeekday() != 6 && d.isoWeekday() != 7 ){
                            //event is already booked.
                            $( '[data-date="'+current_date+'"].fc-day' ).addClass('full_day_reserved');
                        }
                    // }else{
                    //     //event is already booked.
                    //     $( '[data-date="'+current_date+'"].fc-day' ).addClass('full_day_reserved');
                    // }
                }

                element.find('.closeon').click(function() {
                    var event_date = JSON.parse(self.token.get('eventData'));
                    //console.log(event_date);
                    var currentEvent = self.token.get('service_type');
                    if( currentEvent == 'Mois' ){
                        //console.log(currentEvent);
                        let tempMonthData = [];
                        for( let i=0; i < event_date.length; i++ ){
                            //console.log(jQuery( '[data-date="'+event_date[i]['start']+'"].fc-day-number' ).hasClass('date-highlight'));
                            //$( '[data-date="'+event_date[i]['start']+'"].fc-day-number' ).removeClass('date-highlight');
                            var d = moment(event_date[i]['start']);
                            if( d.isoWeekday() != 6 && d.isoWeekday() != 7 ){
                                let event_id = '_fc' + ( i + 1 );
                                tempMonthData.push({
                                    start: event_date[i]['start'],
                                    end : event_date[i]['end'],
                                    id: event_id,
                                    event_type : self.token.get('event_type')
                                });
                            }
                        }
                        jQuery('#calendar').fullCalendar( 'removeEventSource', tempMonthData );
                        self.token.remove('eventData');
                        self.eventDataOneMonth = [];
                        self.eventNextPrev();
                        //$( '.fc-day-number' ).removeClass('date-highlight');
                    } else if( currentEvent == 'journée'){
                        jQuery('#calendar').fullCalendar('removeEvents', event._id);

                        
                        var total_event = event_date.length;
                        for( let i=0; i < event_date.length; i++ ){

                            var startDate = self.datepipe.transform(event_date[i].start, 'yyyy-MM-dd');
                            var startDateEvent = self.datepipe.transform(event.start._i, 'yyyy-MM-dd');
                            var endDate = self.datepipe.transform(event_date[i].end, 'yyyy-MM-dd');
                            var endDateEvent = self.datepipe.transform(event.end._i, 'yyyy-MM-dd');

                            if( startDate === startDateEvent && endDate === endDateEvent ){
                                total_event--;
                                var index = event_date.indexOf(startDate);
                                event_date.splice(i, 1);
                                self.eventDataThirtyDay = event_date;
                                self.eventDataTenDay = event_date;
                                self.eventDataOneDay = event_date;
                                self.eventDataOneMonth = event_date;
                                self.eventDataHour = event_date;
                                self.eventDataHalfDay = event_date;
                                self.token.set('eventData', JSON.stringify(event_date));
                                $( '[data-date="'+event.start._i+'"].fc-day' ).removeClass('full_day');
                                $( '[data-date="'+event.start._i+'"].fc-day-number' ).removeClass('date-highlight');

                                var service_cost = self.service_cost * total_event;
                                self.token.set('service_cost', service_cost);
                                
                            }
                        }
                    } else {

                        jQuery('#calendar').fullCalendar('removeEvents', event._id);

                        for( let i=0; i < event_date.length; i++ ){

                            var startDate = self.datepipe.transform(event_date[i].start, 'yyyy-MM-dd');
                            var startDateEvent = self.datepipe.transform(event.start._i, 'yyyy-MM-dd');
                            var endDate = self.datepipe.transform(event_date[i].end, 'yyyy-MM-dd');
                            var endDateEvent = self.datepipe.transform(event.end._i, 'yyyy-MM-dd');

                            if( startDate === startDateEvent && endDate === endDateEvent ){
                                var index = event_date.indexOf(startDate);
                                event_date.splice(i, 1);
                                self.eventDataThirtyDay = event_date;
                                self.eventDataTenDay = event_date;
                                self.eventDataOneDay = event_date;
                                self.eventDataOneMonth = event_date;
                                self.eventDataHour = event_date;
                                self.eventDataHalfDay = event_date;
                                self.token.set('eventData', JSON.stringify(event_date));
                                $( '[data-date="'+event.start._i+'"].fc-day' ).removeClass('full_day');
                                $( '[data-date="'+event.start._i+'"].fc-day-number' ).removeClass('date-highlight');                                
                            }
                        }
                    }
                });
            },
            dayClick: function(date, jsEvent, view, element) {
                console.log('day click call');

                //jQuery('#calendar').fullCalendar('gotoDate', moment(date.format('YYYY-MM-DD')).add(2, 'M').format('YYYY-MM-DD'));

                let eventExist = 'false';
                if (moment().format('YYYY-MM-DD') === date.format('YYYY-MM-DD') || date.isAfter(moment())) {
                    // This allows today and future date
                    //console.log(eventExist);
                    var selectedDate = date.format('YYYY-MM-DD');
                    if( !self.checkSelectDayIsAvailable(selectedDate) ){
                        //console.log('not available');
                        return;
                    }
                    //console.log(eventExist);

                    jQuery('#calendar').fullCalendar('clientEvents', function(event) {
                        if(event.start <= date && event.end >= date) {
                            eventExist = 'true';
                        }
                    });
                    //console.log(eventExist);
                    if (eventExist == 'true') {
                        jQuery("#eventExist").modal();
                    }
                    //console.log(eventExist);
                    if(eventExist == 'false'){
                        jQuery('#event_date').val(date);

                        //console.log(eventExist);

                        if(self.store.getSelectedService() == "1" ){
                            if( self.token.get( 'event_type' ) == "30 Jours" ){
                                var event_date = self.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd');
                                var event_date = self.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd');
                                var thirtyDaysData = JSON.parse( self.token.get( 'eventData' ) ) || [];
                                console.log( 'thirtyDaysData => '+thirtyDaysData.length );
                                if( thirtyDaysData.length >= 1 ){
                                    console.log( 'thirty days data if' );
                                    console.log( event_date );
                                    if( self.checkUserAbletoSelectDay( event_date, '30 Jours' ) ){
                                        if( self.eventDataThirtyDay.length < 30 ){
                                            self.eventDataThirtyDay.push({
                                                start: event_date,
                                                end : event_date,
                                                event_type : self.token.get('event_type')
                                            })
                                            self.token.set('eventData', JSON.stringify(self.eventDataThirtyDay));
                                        } else {
                                            jQuery('#thirtyDayEventError').modal();
                                        }
                                        self.addThirtyDayEvent();
                                    } else {
                                        jQuery('#OutofSelectionError').modal();
                                    }
                                } else {
                                    console.log( 'thirty days data else' );
                                    if( self.eventDataThirtyDay.length < 30 ){
                                        self.eventDataThirtyDay.push({
                                            start: event_date,
                                            end : event_date,
                                            event_type : self.token.get('event_type')
                                        })
                                        self.token.set('eventData', JSON.stringify(self.eventDataThirtyDay));
                                    } else {
                                        jQuery('#thirtyDayEventError').modal();
                                    }
                                    self.addThirtyDayEvent();
                                }
                            }

                            if(self.token.get('event_type') == "journée"){
                                if( self.eventDataOneDay.length >= 1 ){
                                    for ( var i = 0; i <= self.eventDataOneDay.length - 1; i++ ) {
                                        var old_date = self.eventDataOneDay[i].start;
                                        //console.log( 'old-date => '+ old_date );
                                        $( '[data-date="'+old_date+'"].fc-day' ).removeClass('full_day');
                                        $( '[data-date="'+old_date+'"].fc-day-number' ).removeClass('date-highlight');
                                    }
                                }
                                var event_date = self.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd');
                                //self.eventDataOneDay = [];
                                if(self.eventDataOneDay.length < 3){
                                    self.eventDataOneDay.push({
                                        start: event_date,
                                        end : event_date,
                                        event_type : self.token.get('event_type')
                                    })
                                    self.token.set('eventData', JSON.stringify(self.eventDataOneDay));
                                }else{
                                    jQuery('#oneDayEventError').modal();
                                }
                                self.addDayEvent();
                            }

                            if(self.token.get('event_type') == "Mois"){
                                var selectDate = moment(new Date(date)).format('YYYY-MM-DD');
                                // console.log(startDate);
                                // return;

                                const event_date = new Date(jQuery('#event_date').val());
                                var end_date = moment(new Date(event_date).setMonth(event_date.getMonth() + 1)).format('YYYY-MM-DD');
                                var monthEventExists = true;
                                var dateArray = [];
                                var eventArray = [];
                                var allEvents = jQuery("#calendar").fullCalendar("clientEvents");
                                var dt = new Date(event_date);
                                var et = new Date(end_date);
                                var diff = [];
                                let selectedEvent = self.token.get('eventData') || [];

                                //console.log(allEvents);

                                for(var b=0; b < allEvents.length; b++){
                                    eventArray.push(moment(allEvents[b].start._i).format('YYYY-MM-DD'))
                                }

                                //console.log(eventArray);

                                while(dt <= et){
                                    dateArray.push(moment(dt).format('YYYY-MM-DD'));
                                    dt.setDate(dt.getDate() + 1);
                                }
                                diff = jQuery(dateArray).not(eventArray).get();
                                
                                //if(diff.length == dateArray.length){
                                    //console.log( selectedEvent);
                                    //console.log( selectedEvent.length );
                                    //console.log( self.eventDataOneMonth.length );
                                    //if( selectedEvent.length < 1 ){
                                        if( self.eventDataOneMonth.length < 1 ){
                                            
                                            var startDate = moment(selectDate);
                                            var endDate = moment(startDate).add(30, 'days');
                                            
                                            // If you want an inclusive end date (fully-closed interval)
                                            var i = 0;
                                            for (var m = moment(startDate); m.diff(endDate.add(1, 'days'), 'days') <= 0; m.add(1, 'days')) {
                                                if(i < 30 ){
                                                    var currentDate = m.format('YYYY-MM-DD');
                                                    //if( self.checkSelectDayIsAvailable(currentDate) ){
                                                        //if(self.checkDayIsAvailableInBooked(currentDate)){

                                                            var id = '_fc' + ( i + 1 );
                                                            self.eventDataOneMonth.push({
                                                                start: m.format('YYYY-MM-DD'),
                                                                end : m.format('YYYY-MM-DD'),
                                                                id: id,
                                                                event_type : self.token.get('event_type')
                                                            });
                                                            
                                                            i++;
                                                        //}
                                                    //}
                                                }else{
                                                    i++;
                                                    break;
                                                }
                                            }
                                            self.token.set('eventData', JSON.stringify(self.eventDataOneMonth));
                                        } else {
                                            // user already selected month data
                                            var startDate = moment(selectDate);
                                            var endDate = moment(startDate).add(30, 'days');
                                            self.eventDataOneMonth = [];
                                            // If you want an inclusive end date (fully-closed interval)
                                            var i = 0;
                                            for (var m = moment(startDate); m.diff(endDate.add(1, 'days'), 'days') <= 0; m.add(1, 'days')) {
                                                if(i < 30 ){
                                                    var currentDate = m.format('YYYY-MM-DD');
                                                    //if( self.checkSelectDayIsAvailable(currentDate) ){
                                                        //if(self.checkDayIsAvailableInBooked(currentDate)){

                                                            var id = '_fc' + ( i + 1 );
                                                            self.eventDataOneMonth.push({
                                                                start: m.format('YYYY-MM-DD'),
                                                                end : m.format('YYYY-MM-DD'),
                                                                id: id,
                                                                event_type : self.token.get('event_type')
                                                            });
                                                            
                                                            i++;
                                                        //}
                                                    //}
                                                }else{
                                                    i++;
                                                    break;
                                                }
                                            }
                                            self.token.set('eventData', JSON.stringify(self.eventDataOneMonth));
                                        }
                                        self.addMonthEvent();
                                    // } else {
                                    //     jQuery("#eventExist").modal();
                                    // }
                                /*}else{
                                    jQuery("#eventExist").modal();
                                }*/
                            }

                            if(self.token.get('event_type') == "Dix jours"){
                                var event_date = self.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd');
                                var tenDaysData = JSON.parse( self.token.get( 'eventData' ) ) || [];
                                console.log( 'tenDaysData => '+tenDaysData.length );
                                if( tenDaysData.length >= 1 ){
                                    console.log( 'ten days data if' );
                                    console.log( event_date );
                                    if( self.checkUserAbletoSelectDay( event_date, 'Dix jours' ) ){
                                        if( self.eventDataTenDay.length < 10 ){
                                            self.eventDataTenDay.push({
                                                start: event_date,
                                                end : event_date,
                                                event_type : self.token.get('event_type')
                                            })
                                            self.token.set('eventData', JSON.stringify(self.eventDataTenDay));
                                        } else {
                                            jQuery('#tenDayEventError').modal();
                                        }

                                        self.addTenDayEvent();
                                    } else {
                                        jQuery('#OutofSelectionError').modal();
                                    }
                                } else {
                                    console.log( 'ten days data else' );
                                    if( self.eventDataTenDay.length < 10 ){
                                        self.eventDataTenDay.push({
                                            start: event_date,
                                            end : event_date,
                                            event_type : self.token.get('event_type')
                                        })
                                        self.token.set('eventData', JSON.stringify(self.eventDataTenDay));
                                    }else{
                                        jQuery('#tenDayEventError').modal();
                                    }

                                    self.addTenDayEvent();
                                }
                            }
                        }
                    }

                } else {
                    // Else part is for past dates
                    jQuery('#pastDayClick').modal();
                }
            },
            eventDestroy: function( event, element ){
                //console.log('eventDestroy');
                let current_date = self.datepipe.transform(event.start._i, 'yyyy-MM-dd');
                if( event.className[0] != 'fetchEvent' ){
                    $( '[data-date="'+current_date+'"].fc-day' ).removeClass('full_day');
                    $( '[data-date="'+current_date+'"].fc-day-number' ).removeClass('date-highlight');
                }
            },
            eventClick: function(event, jsEvent, view) {
                /*console.log('eventClick');
                var similarEvents = $("#calendar").fullCalendar('clientEvents', function(e) { return e.test === event.test });

                for (var i = 0; similarEvents.length > i ; i++){
                    similarEvents[i].className = 'full_day';
                    $('#calendar').fullCalendar('updateEvent', similarEvents[i]);
                }*/
            },
            /*loading: function( isLoading, view ) {
                if(isLoading) {// isLoading gives boolean value  
                    console.log('start loading');
                } else {
                    console.log('stop loading');
                }
            }*/
        })

        jQuery('.fc-prev-button span').click(function(){
            self.eventNextPrev();
        });

        jQuery('.fc-next-button span').click(function(){
            self.eventNextPrev();
        });

        var goToDate = JSON.parse(this.token.get('eventData'))

        if(this.token.get('eventData')){
            if( typeof goToDate !== 'undefined' && goToDate.length > 0 ){
                jQuery('#calendar').fullCalendar('gotoDate', goToDate[0].start);
            }
        }

        jQuery('#calendar-modal-view-event').on('shown.bs.modal', function () {
            //console.log('calendar modal show');
            jQuery("#calendar").fullCalendar('render');
            // self.fetchAllEvent = JSON.parse(self.token.get('fetchEvent'));
            // console.log(self.fetchAllEvent);
            //jQuery('#calendar').fullCalendar('addEventSource', self.fetchAllEvent);
            jQuery('#calendar').fullCalendar('refetchEvents');
            if(self.token.get('event_type') == "30 Jours"){
                self.addThirtyDayEvent();
            }

            if(self.token.get('event_type') == "journée"){
                self.addDayEvent();
            }
            
            if(self.token.get('event_type') == "Mois"){
                self.addMonthEvent();
            }

            if(self.token.get('event_type') == "Dix jours"){
                self.addTenDayEvent();
            }
        });
    }

    changePackage(){
        
        jQuery('#calendar').fullCalendar('removeEvents');

        this.token.remove('eventData');
        this.eventDataThirtyDay = [];
        this.eventDataTenDay = [];
        this.eventDataOneDay = [];
        this.eventDataOneMonth = [];
        this.eventDataHour = [];
        this.eventDataHalfDay = [];
        //jQuery("#calendar").fullCalendar('render');
        //jQuery('#calendar').fullCalendar('refetchEvents');
        jQuery('#previousPackageError').modal('hide');
        setTimeout(()=>{    //<<<---    using ()=> syntax
                this.eventNextPrev();
                //this.eventTypeName = eventTypeName;
              jQuery('#calendar-modal-view-event').modal();
        }, 500);
        
    }

    cancelChangePackage(){
        jQuery('#previousPackageError').modal('hide');
    }

    nextToCheckout(){
        this.zone.run(() => {
            if( JSON.parse( this.token.get( 'eventData' ) ) != null && JSON.parse( this.token.get( 'eventData' ) ).length >= 1 && !this.auth.isLoggedIn() ){
                if( this.token.get('event_type') == 'Dix jours' ){
                    if( JSON.parse( this.token.get( 'eventData' ) ).length > 10 ){
                        jQuery('#tenDaySelectError').modal();
                    } else {
                        jQuery('#calendar-modal-view-event').modal('hide');
                        this.router.navigate( ['/connexion'] );
                        //this.router.navigate(['/working-progress']);
                    }
                } else if ( this.token.get('event_type') == '30 Jours' ) {
                    if( JSON.parse(this.token.get('eventData')).length > 30 ){
                        jQuery('#thirtyDaySelectError').modal();
                    } else {
                        jQuery('#calendar-modal-view-event').modal('hide');
                        this.router.navigate( ['/connexion'] );
                        //this.router.navigate(['/working-progress']);
                    }
                } else {
                    jQuery('#calendar-modal-view-event').modal('hide');
                    this.router.navigate( ['/connexion'] );
                    //this.router.navigate(['/working-progress']);
                }
            } else if( JSON.parse(this.token.get('eventData')) != null && JSON.parse(this.token.get('eventData')).length >= 1 && this.auth.isLoggedIn() ){
                if( this.token.get('event_type') == 'Dix jours' ){
                    if( JSON.parse( this.token.get( 'eventData' ) ).length > 10 ){
                        jQuery('#tenDaySelectError').modal();
                    } else {
                        jQuery('#calendar-modal-view-event').modal('hide');
                        this.router.navigate( ['/un-service'] ); // send to service component
                        //this.router.navigate( ['/récapitulatif-de-la-commande'] ); // cart
                        //this.router.navigate(['/working-progress']);
                    }
                } else if ( this.token.get('event_type') == '30 Jours' ) {
                    if( JSON.parse(this.token.get('eventData')).length > 30 ){
                        jQuery('#thirtyDaySelectError').modal();
                    } else {
                        jQuery('#calendar-modal-view-event').modal('hide');
                        this.router.navigate( ['/un-service'] ); // send to service component
                        //this.router.navigate( ['/récapitulatif-de-la-commande'] ); // cart
                        //this.router.navigate(['/working-progress']);
                    }
                } else {
                    jQuery('#calendar-modal-view-event').modal('hide');
                    this.router.navigate( ['/un-service'] ); // send to service component
                    //this.router.navigate( ['/récapitulatif-de-la-commande'] ); // cart
                    //this.router.navigate(['/working-progress']);
                }
            } else {
                jQuery('#selectAnyEventError').modal();
            }
            //localStorage.setItem('step', '3');
            //jQuery('#previousPackageError').modal('hide');
            //jQuery('#calendar-modal-view-event').modal('hide');
            //this.router.navigate(['/working-progress']);
        });
    }

    checkBoxvalue(eventType, event, price){
        this.eventTypeName = eventType;
        this.time[0] = event.target.value;
        this.token.set('event_type', event.target.value);
        this.store.setServiceType( this.token.get('event_type') )
        this.token.set('service_cost', price);
        this.time[1] = price;
        this.service_cost = this.token.get('service_cost');
        var eventData = JSON.parse(this.token.get('eventData'));
        if( eventData !== null && typeof eventData !== 'undefined' && eventData.length > 0 && !this.in_array_r( this.token.get('event_type'), eventData) ){
            jQuery("#previousPackageError").modal();
            return;
        }
        
        jQuery('#calendar-modal-view-event').modal();
    }

    isEventType(eventType){
        let checked = false
        if(this.token.get('event_type') == eventType){
            checked = true
        }
        return checked;
    }

    checkUserAbletoSelectDay( date, service ){
        console.log( ' Selected Date => '+date );
        let selectedDates = JSON.parse( this.token.get( 'eventData' ) );
        selectedDates = selectedDates.sort( this.sortEventData );
        let eventStartDate = new Date( selectedDates[0]['start'] );
        let eventOfferEndDate = new Date( selectedDates[0]['start'] );
        let offerExpirePeriod = ( service == 'Dix jours' ) ? 2 : 6 ;
        eventOfferEndDate.setMonth( eventOfferEndDate.getMonth() + offerExpirePeriod );
        let startDate = this.datepipe.transform( eventStartDate, 'yyyy-MM-dd' );
        let endDate = this.datepipe.transform( eventOfferEndDate, 'yyyy-MM-dd' );
        if( date > startDate  && endDate < date ){
            return false;
        } else {
            return true;
        }
    }

    removeExtraDays( service ){
        let selectedDates = JSON.parse( this.token.get( 'eventData' ) ) || [];
        selectedDates = selectedDates.sort( this.sortEventData );
        console.log(selectedDates);
        if( selectedDates.length < 1 ){
            return;
        }

        let eventStartDate = new Date( selectedDates[0]['start'] );
        let eventOfferEndDate = new Date( selectedDates[0]['start'] );
        let offerExpirePeriod = ( service == 'Dix jours' ) ? 2 : 6 ;
        eventOfferEndDate.setMonth( eventOfferEndDate.getMonth() + offerExpirePeriod );
        console.log( 'eventStartDate => '+ eventStartDate );
        console.log( 'eventOfferEndDate => '+ eventOfferEndDate );
        let tempEventData = [];

        if( selectedDates.length > 0 ){
            for ( var i = 0; i < selectedDates.length; i++ ) {
                let currentDate = new Date( selectedDates[i]['start'] );
                console.log( currentDate.getTime() );
                console.log( eventOfferEndDate.getTime() );
                console.log( eventStartDate.getTime() );
                if( currentDate.getTime() <= eventOfferEndDate.getTime() && currentDate.getTime() >= eventStartDate.getTime() ) { 
                    console.log( "Date is in between the" );
                    tempEventData.push({
                        start: selectedDates[i]['start'],
                        end : selectedDates[i]['end'],
                        event_type : selectedDates[i]['event_type']
                    })
                } else { 
                    console.log( "Date is not in between" );
                }
            }
            this.token.set( 'eventData', JSON.stringify( tempEventData ) );
        }
    }

    sortEventData( a, b ){
        var dateA = new Date(a.start).getTime();
        var dateB = new Date(b.start).getTime();
        return dateA > dateB ? 1 : -1;
    }

    checkSelectDayIsAvailable( date ){
        var self = this;
        if(self.token.get('getClosingDates')){
            var getClosingDates = JSON.parse(self.token.get('getClosingDates'));
            if( typeof getClosingDates !== 'undefined' ){
                if( typeof getClosingDates.holidays !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 && self.checkDateInHolidays(date) ){
                    if( !self.checkMorningAvailable(date) || !self.checkAfternoonAvailable(date) ){
                        this.unavailable_class = 'holiday';
                        return false;
                    }
                }else{
                    if( !self.checkDateInWeekdays(date) ){
                        this.unavailable_class = 'unavailable';
                        return false;
                    }
                }
            }
        }
        this.unavailable_class = 'unavailable';
        return true;
    }

    checkDayIsAvailableInBooked( date ){
        var fetchEvent = JSON.parse(this.token.get('fetchEvent'));            
        if( typeof fetchEvent !== 'undefined' && fetchEvent.length > 0 ){
            for (var i = fetchEvent.length - 1; i >= 0; --i) {
                var startDate = fetchEvent[i].start.split(" ");
                if ( startDate[0] == date ) {
                    //console.log(startDate[0] +'=='+ date);
                    return false;
                }
            }
        }
        return true;
    }

    unavailableDayHtml( date ){
        var unavailableMsg = this.unavailable_label;
        if(this.getUnavailableMsg(date)){
            unavailableMsg = this.getUnavailableMsg(date);
            //return '<div class="unavailable" data-tooltip="'+unavailableFullMsg+'"><span class="complete">'+unavailableMsg+'</span></div>';
        }
        return '<div class="unavailable"><span class="complete">'+unavailableMsg+'</span></div>';
    }

    getUnavailableFullMsg( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));   
        if( typeof getClosingDates !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 ){
            for (var i = getClosingDates.holidays.length - 1; i >= 0; --i) {
                if ( getClosingDates.holidays[i].date == date) {
                    if( typeof getClosingDates.holidays[i].purpose !== 'undefined' && getClosingDates.holidays[i].purpose != '' ){
                        let holiday_purpose = getClosingDates.holidays[i].purpose.split(" ");
                        let short_purpose_text = '';
                        if( holiday_purpose.length > 1 ){
                            for ( var k = 0; k <= 1; k++ ) {
                                short_purpose_text += holiday_purpose[k].charAt(0);
                            }
                        } else{
                            short_purpose_text = holiday_purpose.charAt(0);
                        }
                        return getClosingDates.holidays[i].purpose;
                    }
                }
            }
        }
        return false;
    }

    getUnavailableMsg( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));   
        if( typeof getClosingDates !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 ){
            for (var i = getClosingDates.holidays.length - 1; i >= 0; --i) {
                if ( getClosingDates.holidays[i].date == date) {
                    if( typeof getClosingDates.holidays[i].purpose !== 'undefined' && getClosingDates.holidays[i].purpose != '' ){
                        let holiday_purpose = getClosingDates.holidays[i].purpose.split(" ");
                        let short_purpose_text = '';
                        if( holiday_purpose.length > 1 ){
                            for ( var k = 0; k <= 1; k++ ) {
                                short_purpose_text += holiday_purpose[k].charAt(0);
                            }
                        } else{
                            short_purpose_text = holiday_purpose.charAt(0);
                        }
                        //console.log( short_purpose_text );
                        //return getClosingDates.holidays[i].purpose;
                        return short_purpose_text;
                    }
                }
            }
        }
        return false;
    }

    checkDateInHolidays( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));
        if( typeof getClosingDates !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 && this.in_array_r( date, getClosingDates.holidays )){
            return true;
        }
        return false;
    }

    checkMorningAvailable( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));            
        if( typeof getClosingDates !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 ){
            for (var i = getClosingDates.holidays.length - 1; i >= 0; --i) {
                if ( getClosingDates.holidays[i].date == date && getClosingDates.holidays[i].morning == '1' ) {
                    return true;
                }
            }
        }
        return false;
    }

    checkAfternoonAvailable( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));
        if( typeof getClosingDates !== 'undefined' && typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 ){
            for (var i = getClosingDates.holidays.length - 1; i >= 0; --i) {
                if ( getClosingDates.holidays[i].date == date && getClosingDates.holidays[i].afternoon == '1' ) {
                    return true;
                }
            }
        }
        return false;
    }

    checkDateInWeekdays( date ){
        
        var self = this;

        if(self.token.get('getClosingDates')){

            var getClosingDates = JSON.parse(self.token.get('getClosingDates'));
            
            if( typeof getClosingDates !== 'undefined' ){

                if( typeof getClosingDates.week_days !== 'undefined' && getClosingDates.week_days.length > 0 ){

                    var currentDate = moment(date);

                    if( currentDate.isoWeekday() == 1 ){
                        
                        if( getClosingDates.week_days[0].monday_morning == '0' || getClosingDates.week_days[0].monday_afternoon == '0' ){
                            return false;
                        }
                        
                    }else if( currentDate.isoWeekday() == 2 ){

                        if( getClosingDates.week_days[0].tuesday_morning == '0' || getClosingDates.week_days[0].tuesday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 3 ){
                        
                        if( getClosingDates.week_days[0].wednesday_morning == '0' || getClosingDates.week_days[0].wednesday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 4 ){
                        
                        if( getClosingDates.week_days[0].thursday_morning == '0' || getClosingDates.week_days[0].thursday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 5 ){
                        
                        if( getClosingDates.week_days[0].friday_morning == '0' || getClosingDates.week_days[0].friday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 6 ){

                        if( getClosingDates.week_days[0].saturday_morning == '0' || getClosingDates.week_days[0].saturday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 7 ){
                        
                        if( getClosingDates.week_days[0].sunday_morning == '0' || getClosingDates.week_days[0].sunday_afternoon == '0' ){
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    in_array_r(needle, haystack) {
        var length = haystack.length;
        for(var key in haystack) {
            if(haystack[key] == needle){
                return true;
            }
            if(typeof haystack[key]=='object'){
                if(this.in_array_r(needle, haystack[key])){
                    return true;
                }
            } 
        }
        return false;
    }

    saveHourly(){
        var event_date = JSON.parse(this.token.get('eventData'));
        var title = this.token.get('event_type');

        let date = this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd');
        jQuery('#modal-view-hourevent-add').modal('hide');
        jQuery('#calendar').fullCalendar('removeEvents')
        this.eventData['start'] = date + ' ' + parseInt(this.startTime.hour.toString(), 10) +':'+ parseInt(this.startTime.minute.toString(), 10);
        this.eventData['end'] = date + ' ' + parseInt(this.endTime.hour.toString(), 10) +':'+ parseInt(this.endTime.minute.toString(), 10)
        
        this.eventDataHour = [];
        this.eventDataHour.push({
            start: this.eventData['start'],
            end : this.eventData['end']
        })

        this.token.set('eventData', JSON.stringify(this.eventDataHour));
        // this.token.set('eventData', JSON.stringify(this.eventData));
        jQuery('#calendar').fullCalendar('renderEvent', {
            title: '8 AM to 12 PM',
            start: new Date(date + ' ' + parseInt(this.startTime.hour.toString(), 10) +':'+ parseInt(this.startTime.minute.toString(), 10)),
            end: new Date(date + ' ' + parseInt(this.endTime.hour.toString(), 10) +':'+ parseInt(this.endTime.minute.toString(), 10)),
            editable: false,
            allDay: false,
            color: '#90EE90'
        });
        // this.fetchEvent();
        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    addEvent(select){
        var eventData = [];
        this.eventDataHalfDay = [];
        let latest_date = this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd h:mm:ss a');
        var event_day = this.addEventForm.value.event_date = latest_date;
        
        if(select == null){
            jQuery('#modal-view-event-add').modal();
        }

        if (select) {
            eventData[1] = event_day;

            this.token.set('eventData', JSON.stringify(eventData));

            jQuery('#modal-view-event-add').modal('hide');

            jQuery('#calendar').fullCalendar('removeEvents')
            this.eventDataHalfDay.push({
                start: new Date(this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd') + ' 08:00:00 AM'),
                end : new Date(this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd') + ' 12:00:00 PM'),
            })
            this.token.set('eventData', JSON.stringify(this.eventDataHalfDay));
            if(select == '8am'){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: '8 AM',
                    start: new Date(this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd') + ' 08:00:00 AM'),
                    end: new Date(this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd') + ' 12:00:00 PM'),
                    allDay: false,
                    editable: false,
                    color: '#90EE90'
                });
            }

            if(select == '2pm'){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: '2 PM',
                    start: new Date(this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd') + ' 02:00:00 PM'),
                    end: new Date(this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd') + ' 06:00:00 PM'),
                    allDay: false,
                    editable: false,
                    color: '#90EE90'
                });
            }
        }

        // this.fetchEvent();
        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    addThirtyDayEvent(){

        var event_date = JSON.parse(this.token.get('eventData')) || [];
        var title = this.token.get('event_type');

        if(event_date != 'null' && event_date.length <= 30){
            jQuery('#calendar').fullCalendar('removeEvents')
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: this.full_day_label,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90',
                    className: 'full_day_section'
                });
            }
        }else{
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: this.full_day_label,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90',
                    className: 'full_day_section'
                });
            }
        }

        this.removeExtraDays( '30 Jours' );

        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    addDayEvent(){

        jQuery('#calendar').fullCalendar('removeEvents')
        var event_date = JSON.parse(this.token.get('eventData')) || [];
        var title = this.token.get('event_type');
        // console.log(event_date);

        var total_event = 1;
        if(event_date != 'null' && event_date.length <= 3){
            

            for( let i=0; i < event_date.length; i++ ){

                var service_cost = this.service_cost * total_event;
                this.token.set('service_cost', service_cost);

                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: this.full_day_label,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90',
                    className: 'full_day_section'
                });
                total_event++;
            }
        }else{
            
            for( let i=0; i < event_date.length; i++ ){

                var service_cost = this.service_cost * total_event;
                this.token.set('service_cost', service_cost);

                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: this.full_day_label,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90',
                    className: 'full_day_section'
                });

                total_event++;
            }
        }

        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    addMonthEvent(){

        jQuery('#calendar').fullCalendar('removeEvents')
        var event_date = JSON.parse(this.token.get('eventData')) || [];
        var title = this.token.get('event_type');

        if(event_date != 'null' && event_date.length <= 1){
            for( let i=0; i < event_date.length; i++ ){
                var d = moment(event_date[i].start);
                if( d.isoWeekday() != 6 && d.isoWeekday() != 7 ){

                    jQuery('#calendar').fullCalendar('renderEvent', {
                        title: this.full_day_label,
                        start: event_date[i].start,
                        end: event_date[i].end,
                        editable: false,
                        allDay: true,
                        color: '#90EE90',
                        className: 'full_day_section'
                    });

                }
            }
        }else{
            for( let i=0; i < event_date.length; i++ ){
                var d = moment(event_date[i].start);
                if( d.isoWeekday() != 6 && d.isoWeekday() != 7 ){
                    jQuery('#calendar').fullCalendar('renderEvent', {
                        title: this.full_day_label,
                        start: event_date[i].start,
                        end: event_date[i].end,
                        editable: false,
                        allDay: true,
                        color: '#90EE90',
                        className: 'full_day_section'
                    });
                }
            }
        }

        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    addTenDayEvent(){

        jQuery('#calendar').fullCalendar('removeEvents')
        var event_date = JSON.parse(this.token.get('eventData')) || [];
        var title = this.token.get('event_type');

        if(event_date != 'null' && event_date.length <= 10){
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: this.full_day_label,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90',
                    className: 'full_day_section'
                });
            }
        }else{
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: this.full_day_label,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90',
                    className: 'full_day_section'
                });
            }
        }

        this.removeExtraDays( 'Dix jours' );

        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    fetchEvent(){

        this.eventArray = [];
        this.orderType['order_type'] = this.token.get('service');
        this.spaceservice.fetchEvent(this.orderType).subscribe(
            data => {
                if(data['status'] == 'true'){
                    for (let i = 0; i < data['events'].length; i++) {
                        
                        var title = '';
                        if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 1){
                            title = '30 Jours'
                        }else if(data['events'][i].order_type == 2 && data['events'][i].length_of_booking == 2){
                            title = 'Half Day';
                        }else if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 2 || data['events'][i].order_type == 2 && data['events'][i].length_of_booking == 3){
                            title = 'La journée';
                        }else if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 3){
                            title = 'Le mois';
                        }else if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 4){
                            title = '10 Jours';
                        }else if(data['events'][i].order_type == 2 && data['events'][i].length_of_booking == 1){
                            title = 'Hourly';
                        }
                        if( data['events'][i].start_date != null && data['events'][i].end_date != null && this.token.get('userId') != null && this.token.get('userId') == data['events'][i].user_id ){
                            this.eventArray.push({
                                title: this.full_day_label,
                                start: data['events'][i].start_date,
                                end: data['events'][i].end_date,
                                allDay: true,
                                editable:false,
                                color: 'yellow',
                                className: 'fetchEvent'
                            });
                        }

                        if(data['events'][i].start_date == null && data['events'][i].end_date == null && this.token.get('userId') != null && this.token.get('userId') == data['events'][i].user_id ){
                            for (let j = 0; j < data['events'][i].appointment.length; j++) {
                                var d = moment(data['events'][i].appointment[j].start_date);
                                if( d.isoWeekday() != 6 && d.isoWeekday() != 7 ){
                                    this.eventArray.push({
                                        title: this.full_day_label,
                                        start: data['events'][i].appointment[j].start_date,
                                        end: data['events'][i].appointment[j].end_date,
                                        allDay: true,
                                        editable:false,
                                        color: 'yellow',
                                        className: 'fetchEvent'
                                    });
                                }
                            }
                        }
                    }
                }
                //console.log( this.eventArray );
                jQuery('#calendar').fullCalendar('addEventSource', this.eventArray);
                this.token.set('fetchEvent', JSON.stringify(this.eventArray));

                var event_date = JSON.parse(this.token.get('eventData'));
                var title = this.token.get('event_type');


                var currentEvent = this.token.get('service_type');
                if( currentEvent == 'Mois' ){
                    if(event_date){
                        for( let z=0; z < event_date.length; z++ ){
                            var d = moment(event_date[z].start);
                            if( d.isoWeekday() != 6 && d.isoWeekday() != 7 ){
                                this.isSelectedEvent.push({
                                    title: this.full_day_label,
                                    start: event_date[z].start,
                                    end: event_date[z].end,
                                    editable: false,
                                    allDay: true,
                                    color: '#90EE90',
                                    className: 'full_day_section'
                                });
                            }
                        }
                        jQuery('#calendar').fullCalendar('addEventSource', this.isSelectedEvent);
                    }
                }else{
                    if(event_date){
                        for( let z=0; z < event_date.length; z++ ){
                            this.isSelectedEvent.push({
                                title: this.full_day_label,
                                start: event_date[z].start,
                                end: event_date[z].end,
                                editable: false,
                                allDay: true,
                                color: '#90EE90',
                                className: 'full_day_section'
                            });
                        }
                        jQuery('#calendar').fullCalendar('addEventSource', this.isSelectedEvent);
                    }
                }

            },
            error => {
            }
        );
    }

    eventNextPrev(){
        this.nextPrevArray = [];
        jQuery('#calendar').fullCalendar('removeEvents')
        var event_date = JSON.parse(this.token.get('eventData')) || [];
        var title = this.token.get('event_type');

        var currentEvent = this.token.get('service_type');
        if( currentEvent == 'Mois' ){
            if(event_date){
                for( let k=0; k < event_date.length; k++ ){
                    var d = moment(event_date[k].start);
                    if( d.isoWeekday() != 6 && d.isoWeekday() != 7 ){
                        this.nextPrevArray.push({
                            title: this.full_day_label,
                            start: event_date[k].start,
                            end: event_date[k].end,
                            editable:false,
                            allDay: true,
                            color: '#90EE90',
                            className: 'full_day_section'
                        });
                    }
                }
                jQuery('#calendar').fullCalendar('addEventSource', this.nextPrevArray);
            }
        }else{
            if(event_date){
                for( let k=0; k < event_date.length; k++ ){
                    this.nextPrevArray.push({
                        title: this.full_day_label,
                        start: event_date[k].start,
                        end: event_date[k].end,
                        editable:false,
                        allDay: true,
                        color: '#90EE90',
                        className: 'full_day_section'
                    });
                }
                jQuery('#calendar').fullCalendar('addEventSource', this.nextPrevArray);
            }
        }

        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    }
}
