import { Component, ViewChild, OnInit } from '@angular/core';

import { SpaceService } from '../../service/space.service';
import { TokenService } from '../../service/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../service/user.service';
import { StoreService } from '../../service/store.service';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
// import * as jQuery from 'jquery';
import * as moment from 'moment';
declare var jQuery: any;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

    // public addEventForm: FormGroup;
    addEventForm = this.fb.group({
        equipements: [],
        event_date: []
    })

    eventData: any = {};
    startTime: NgbTimeStruct = {hour: 8, minute: 0, second:0}
    endTime: NgbTimeStruct = {hour: 12, minute: 0, second:0}
    orderType: any = {};
    eventArray: any = [];
    nextPrevArray: any = [];
    eventDataThirtyDay = [];
    eventDataTenDay = [];
    eventDataOneDay = [];
    eventDataOneMonth = [];
    eventDataHour = [];
    eventDataHalfDay = [];
    isSelectedEvent = [];
    fetchAllEvent: any;

    constructor(
        private router: Router,
        private userservice: UserService,
        private store: StoreService,
        public fb: FormBuilder,
        private toastr: ToastrService,
        private spaceservice: SpaceService,
        public datepipe: DatePipe,
        private cookie: CookieService,
        private token: TokenService
    ) { 
        this.userservice.loadScript();
        this.fetchEvent();
        //this.getClosingDates();

        // if(this.token.get('walletDiscount') && this.token.get('editMainOrder') != 'true'){
        //     this.router.navigate(['/récapitulatif-de-la-commande']);
        // }
    }

    ngOnInit() {

        // var getEvent = JSON.parse(this.cookie.get('eventData') || '[]');
        // var selectSpace = JSON.parse(this.cookie.get('space') || '[]');
        var self = this;
        var icon = "X";
        jQuery('#calendar').fullCalendar({
            themeSystem: 'bootstrap4',
            businessHours: false,
            defaultView: 'month',
            editable: true,
            header: {
                left: 'title',
                center: 'month,agendaWeek,agendaDay',
                right: 'today prev,next'
            },
            eventAfterAllRender: function(view){

                jQuery(this).html(' ');
                jQuery('.event_selection').remove();

                var today = new Date();
                var dd    = String(today.getDate()).padStart(2, '0');
                var mm    = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy  = today.getFullYear();
                    today = new Date(yyyy+'-'+mm+'-'+dd); // today date

                jQuery('#calendar .fc-day.fc-widget-content').each(function(i) {

                    var html = '';

                    // Fullcalendar includes a date data element on each day.
                    var date          = jQuery(this).data('date');
                    var selected_date = new Date(date);

                    // prevent to past dates.
                    if(selected_date.getTime() < today.getTime()){
                        return;
                    }
                    
                    if( !self.checkSelectDayIsAvailable(date) ){
                        html = '<div class="event_selection foo">';
                            html += self.unavailableDayHtml(date);
                        html += '</div>';
                        jQuery(this).append(html).addClass('unavailable');
                    }else{
                        jQuery(this).append(html);
                    }


                });
            },
            eventRender: function(event, element) {
                // element.append( "<span class='closeon'>"+icon+"</span>" );
                /*if(event.className[0] != 'fetchEvent'){
                    element.find('.fc-content').append("<span class='closeon' style='font-weight:900; color:white; font-size:10px; float:right;'>X</span>")
                }

                // var calendarEvents = [];    
                // calendarEvents.push(event);

                // console.log(calendarEvents);

                element.find('.closeon').click(function() {

                    //console.log(calendarEvents); return;

                    var event_date = JSON.parse(self.token.get('eventData'));
                    //var event_date2 = JSON.parse(self.token.get('eventData'));
                    //console.log(event_date);
                    for( let i=0; i < event_date.length; i++ ){
                        //console.log(event_date[i].id);
                        /*var startDate = self.datepipe.transform(event_date[i].start, 'yyyy-MM-dd');
                        var startDateEvent = self.datepipe.transform(calendarEvents[0].source.events[i].start._i, 'yyyy-MM-dd');
                        var endDate = self.datepipe.transform(event_date[i].end, 'yyyy-MM-dd');
                        var endDateEvent = self.datepipe.transform(calendarEvents[0].source.events[i].end._i, 'yyyy-MM-dd');*/
                        //if(startDate === startDateEvent && endDate === endDateEvent){
                            // console.log(startDate +'==='+ startDateEvent +'==='+ endDate +'==='+ endDateEvent); 
                            // console.log(calendarEvents[0].source.events[i]._id);*/
                            /*jQuery('#calendar').fullCalendar('removeEvents', event_date[i].id);*/
                            //return;
                            // var index = event_date2.indexOf(event_date[i].id);
                            // event_date2.splice(i, 1);
                            // self.eventDataThirtyDay = event_date;
                            // self.eventDataTenDay = event_date;
                            // self.eventDataOneDay = event_date;
                            //self.eventDataOneMonth = event_date;
                            // self.eventDataHour = event_date;
                            // self.eventDataHalfDay = event_date;
                            //self.token.set('eventData', JSON.stringify(event_date));
                            
                        //}
                    /*}
                    //console.log(self.eventDataOneMonth);
                    self.eventDataOneMonth = [];
                    self.token.set('eventData', JSON.stringify( new Array() ));
                    jQuery('#calendar').fullCalendar('removeEvents');
                    // jQuery('#calendar').fullCalendar('rerenderEvents');
                    // jQuery('#calendar').fullCalendar('refetchEvents');
                    // jQuery('#calendar').fullCalendar('refetchEventSources');

                    self.fetchAllEvent = JSON.parse(self.token.get('fetchEvent'));
                    jQuery('#calendar').fullCalendar('addEventSource', self.fetchAllEvent);


                });*/

                // element.append( "<span class='closeon'>"+icon+"</span>" );
                if(event.className[0] != 'fetchEvent'){
                    element.find('.fc-content').append("<span class='closeon' style='font-weight:900; color:white; font-size:10px; float:right;'>X</span>")
                }

                element.find('.closeon').click(function() {
                    jQuery('#calendar').fullCalendar('removeEvents', event._id);
                    var event_date = JSON.parse(self.token.get('eventData'));
                    for( let i=0; i < event_date.length; i++ ){

                        var startDate = self.datepipe.transform(event_date[i].start, 'yyyy-MM-dd');
                        var startDateEvent = self.datepipe.transform(event.start._i, 'yyyy-MM-dd');
                        var endDate = self.datepipe.transform(event_date[i].end, 'yyyy-MM-dd');
                        var endDateEvent = self.datepipe.transform(event.end._i, 'yyyy-MM-dd');

                        if(startDate === startDateEvent && endDate === endDateEvent){
                            var index = event_date.indexOf(startDate);
                            event_date.splice(i, 1);
                            self.eventDataThirtyDay = event_date;
                            self.eventDataTenDay = event_date;
                            self.eventDataOneDay = event_date;
                            self.eventDataOneMonth = event_date;
                            self.eventDataHour = event_date;
                            self.eventDataHalfDay = event_date;
                            self.token.set('eventData', JSON.stringify(event_date));
                        }
                    }
                });
            },
            dayClick: function(date, jsEvent, view, element) {

                let eventExist = 'false';
                if (moment().format('YYYY-MM-DD') === date.format('YYYY-MM-DD') || date.isAfter(moment())) {
                    // This allows today and future date

                    var selectedDate = date.format('YYYY-MM-DD');
                    if( !self.checkSelectDayIsAvailable(selectedDate) ){
                        console.log('not available');
                        return;
                    }

                    jQuery('#calendar').fullCalendar('clientEvents', function(event) {
                        if(event.start <= date && event.end >= date) {
                            eventExist = 'true';
                        }
                    });
                    if (eventExist == 'true') {
                        jQuery("#eventExist").modal();
                    }
                    
                    if(eventExist == 'false'){
                        jQuery('#event_date').val(date);

                        if(self.store.getSelectedService() == "1" ){
                            if(self.token.get('event_type') == "30 Jours"){
                                var event_date = self.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd')
                                if(self.eventDataThirtyDay.length < 30){
                                    self.eventDataThirtyDay.push({
                                        start: event_date,
                                        end : event_date
                                    })
                                    self.token.set('eventData', JSON.stringify(self.eventDataThirtyDay));
                                }else{
                                    jQuery('#thirtyDayEventError').modal();
                                }
                                self.addThirtyDayEvent();
                            }

                            if(self.token.get('event_type') == "journée"){
                                var event_date = self.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd')
                                if(self.eventDataOneDay.length < 1){
                                    self.eventDataOneDay.push({
                                        start: event_date,
                                        end : event_date
                                    })
                                    self.token.set('eventData', JSON.stringify(self.eventDataOneDay));
                                }else{
                                    jQuery('#oneDayEventError').modal();
                                }
                                self.addDayEvent();
                            }

                            if(self.token.get('event_type') == "Mois"){
                                var selectDate = moment(new Date(date)).format('YYYY-MM-DD');
                                // console.log(startDate);
                                // return;

                                const event_date = new Date(jQuery('#event_date').val());
                                var end_date = moment(new Date(event_date).setMonth(event_date.getMonth() + 1)).format('YYYY-MM-DD');
                                var monthEventExists = true;
                                var dateArray = [];
                                var eventArray = [];
                                var allEvents = jQuery("#calendar").fullCalendar("clientEvents");
                                var dt = new Date(event_date);
                                var et = new Date(end_date);
                                var diff = [];

                                //console.log(allEvents);

                                for(var b=0; b < allEvents.length; b++){
                                    eventArray.push(moment(allEvents[b].start._i).format('YYYY-MM-DD'))
                                }

                                //console.log(eventArray);

                                while(dt <= et){
                                    dateArray.push(moment(dt).format('YYYY-MM-DD'));
                                    dt.setDate(dt.getDate() + 1);
                                }
                                diff = jQuery(dateArray).not(eventArray).get();
                                
                                if(diff.length == dateArray.length){
                                    
                                    if(self.eventDataOneMonth.length < 1){
                                        
                                        var startDate = moment(selectDate);
                                        var endDate = moment(startDate).add(30, 'days');
                                        
                                        // If you want an inclusive end date (fully-closed interval)
                                        var i = 0;
                                        for (var m = moment(startDate); m.diff(endDate.add(1, 'days'), 'days') <= 0; m.add(1, 'days')) {
                                            if(i < 30 ){
                                                var currentDate = m.format('YYYY-MM-DD');
                                                if( self.checkSelectDayIsAvailable(currentDate) ){

                                                    if(self.checkDayIsAvailableInBooked(currentDate)){

                                                        var id = '_fc'+ ( i + 1 );

                                                        self.eventDataOneMonth.push({
                                                            start: m.format('YYYY-MM-DD'),
                                                            end : m.format('YYYY-MM-DD'),
                                                            id: id
                                                        });
                                                        
                                                        i++;
                                                    }
                                                }
                                            }else{
                                                break;
                                            }
                                        }
                                        self.token.set('eventData', JSON.stringify(self.eventDataOneMonth));
                                    }
                                    self.addMonthEvent();
                                }else{
                                    jQuery("#eventExist").modal();
                                }
                            }

                            if(self.token.get('event_type') == "Dix jours"){
                                var event_date = self.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd')
                                if(self.eventDataTenDay.length < 10){
                                    self.eventDataTenDay.push({
                                        start: event_date,
                                        end : event_date
                                    })
                                    self.token.set('eventData', JSON.stringify(self.eventDataTenDay));
                                }else{
                                    jQuery('#tenDayEventError').modal();
                                }

                                self.addTenDayEvent();
                            }
                        }

                        if(self.store.getSelectedService() == "2" ){
                            if(self.store.getEventType() == 'toutes les heures'){
                                jQuery('#modal-view-hourevent-add').modal();
                            }

                            if(self.store.getEventType() == 'demi-journée'){
                                jQuery('#modal-view-event-add').modal();
                            }

                            if(self.store.getEventType() == 'journée complète'){
                                // jQuery('#modal-view-dayEvent-add').modal();
                                var event_date = self.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd')
                                if(self.eventDataOneDay.length < 1){
                                    self.eventDataOneDay.push({
                                        start: event_date,
                                        end : event_date
                                    })
                                    self.token.set('eventData', JSON.stringify(self.eventDataOneDay));
                                }else{
                                    jQuery('#oneDayEventError').modal();
                                }
                                self.addDayEvent();
                            }
                        }
                    }

                } else {
                    // Else part is for past dates
                    jQuery('#pastDayClick').modal();
                }
            },
            eventClick: function(event, jsEvent, view) {
            },
            loading: function( isLoading, view ) {
                if(isLoading) {// isLoading gives boolean value  
                    console.log('start loading');
                } else {
                    console.log('stop loading');
                }
            }
        })

        jQuery('.fc-prev-button span').click(function(){
            self.eventNextPrev();
        });

        jQuery('.fc-next-button span').click(function(){
            self.eventNextPrev();
        });

        var goToDate = JSON.parse(this.token.get('eventData'))

        if(this.token.get('eventData')){
            if( typeof goToDate !== 'undefined' && goToDate.length > 0 ){
                jQuery('#calendar').fullCalendar('gotoDate', goToDate[0].start);
            }
        }
    }

    checkSelectDayIsAvailable( date ){
        var self = this;
        if(self.token.get('getClosingDates')){
            var getClosingDates = JSON.parse(self.token.get('getClosingDates'));
            if( typeof getClosingDates !== 'undefined' ){
                if( typeof getClosingDates.holidays !== 'undefined' && getClosingDates.holidays.length > 0 && self.checkDateInHolidays(date) ){
                    if( !self.checkMorningAvailable(date) || !self.checkAfternoonAvailable(date) ){   
                        return false;
                    }
                }else{
                    if( !self.checkDateInWeekdays(date) ){
                        return false;
                    }
                }
            }
        }
        return true;
    }

    checkDayIsAvailableInBooked( date ){
        console.log(date);
        var fetchEvent = JSON.parse(this.token.get('fetchEvent'));            
        if( typeof fetchEvent !== 'undefined' && fetchEvent.length > 0 ){
            for (var i = fetchEvent.length - 1; i >= 0; --i) {
                var startDate = fetchEvent[i].start.split(" ");
                if ( startDate[0] == date ) {
                    console.log(startDate[0] +'=='+ date);
                    return false;
                }
            }
        }
        return true;
    }

    unavailableDayHtml( date ){
        var unavailableMsg = 'Unavailable';
        if(this.getUnavailableMsg(date)){
            unavailableMsg = this.getUnavailableMsg(date);
        }
        return '<div class="unavailable"><span class="complete">'+unavailableMsg+'</span></div>';
    }

    getUnavailableMsg( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));   
        if( typeof getClosingDates !== 'undefined' && getClosingDates.holidays.length > 0 ){
            for (var i = getClosingDates.holidays.length - 1; i >= 0; --i) {
                if ( getClosingDates.holidays[i].date == date) {
                    if( typeof getClosingDates.holidays[i].purpose !== 'undefined' && getClosingDates.holidays[i].purpose != '' ){
                        return getClosingDates.holidays[i].purpose;
                    }
                }
            }
        }
        return false;
    }

    checkDateInHolidays( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));
        if( typeof getClosingDates !== 'undefined' && getClosingDates.holidays.length > 0 && this.in_array_r( date, getClosingDates.holidays )){
            return true;
        }
        return false;
    }

    checkMorningAvailable( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));            
        if( typeof getClosingDates !== 'undefined' && getClosingDates.holidays.length > 0 ){
            for (var i = getClosingDates.holidays.length - 1; i >= 0; --i) {
                if ( getClosingDates.holidays[i].date == date && getClosingDates.holidays[i].morning == '1' ) {
                    return true;
                }
            }
        }
        return false;
    }

    checkAfternoonAvailable( date ){
        var getClosingDates = JSON.parse(this.token.get('getClosingDates'));
        if( typeof getClosingDates !== 'undefined' && getClosingDates.holidays.length > 0 ){
            for (var i = getClosingDates.holidays.length - 1; i >= 0; --i) {
                if ( getClosingDates.holidays[i].date == date && getClosingDates.holidays[i].afternoon == '1' ) {
                    return true;
                }
            }
        }
        return false;
    }

    checkDateInWeekdays( date ){
        
        var self = this;

        if(self.token.get('getClosingDates')){

            var getClosingDates = JSON.parse(self.token.get('getClosingDates'));
            
            if( typeof getClosingDates !== 'undefined' ){

                if( typeof getClosingDates.week_days !== 'undefined' && getClosingDates.week_days.length > 0 ){

                    var currentDate = moment(date);

                    if( currentDate.isoWeekday() == 1 ){
                        
                        if( getClosingDates.week_days[0].monday_morning == '0' || getClosingDates.week_days[0].monday_afternoon == '0' ){
                            return false;
                        }
                        
                    }else if( currentDate.isoWeekday() == 2 ){

                        if( getClosingDates.week_days[0].tuesday_morning == '0' || getClosingDates.week_days[0].tuesday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 3 ){
                        
                        if( getClosingDates.week_days[0].wednesday_morning == '0' || getClosingDates.week_days[0].wednesday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 4 ){
                        
                        if( getClosingDates.week_days[0].thursday_morning == '0' || getClosingDates.week_days[0].thursday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 5 ){
                        
                        if( getClosingDates.week_days[0].friday_morning == '0' || getClosingDates.week_days[0].friday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 6 ){

                        if( getClosingDates.week_days[0].saturday_morning == '0' || getClosingDates.week_days[0].saturday_afternoon == '0' ){
                            return false;
                        }

                    }else if( currentDate.isoWeekday() == 7 ){
                        
                        if( getClosingDates.week_days[0].sunday_morning == '0' || getClosingDates.week_days[0].sunday_afternoon == '0' ){
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    in_array_r(needle, haystack) {
        var length = haystack.length;
        for(var key in haystack) {
            if(haystack[key] == needle){
                return true;
            }
            if(typeof haystack[key]=='object'){
                if(this.in_array_r(needle, haystack[key])){
                    return true;
                }
            } 
        }
        return false;
    }

    saveHourly(){
        var event_date = JSON.parse(this.token.get('eventData'));
        var title = this.token.get('event_type');

        let date = this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd');
        jQuery('#modal-view-hourevent-add').modal('hide');
        jQuery('#calendar').fullCalendar('removeEvents')
        this.eventData['start'] = date + ' ' + parseInt(this.startTime.hour.toString(), 10) +':'+ parseInt(this.startTime.minute.toString(), 10);
        this.eventData['end'] = date + ' ' + parseInt(this.endTime.hour.toString(), 10) +':'+ parseInt(this.endTime.minute.toString(), 10)
        
        this.eventDataHour = [];
        this.eventDataHour.push({
            start: this.eventData['start'],
            end : this.eventData['end']
        })

        this.token.set('eventData', JSON.stringify(this.eventDataHour));
        // this.token.set('eventData', JSON.stringify(this.eventData));
        jQuery('#calendar').fullCalendar('renderEvent', {
            title: '8 AM to 12 PM',
            start: new Date(date + ' ' + parseInt(this.startTime.hour.toString(), 10) +':'+ parseInt(this.startTime.minute.toString(), 10)),
            end: new Date(date + ' ' + parseInt(this.endTime.hour.toString(), 10) +':'+ parseInt(this.endTime.minute.toString(), 10)),
            editable: false,
            allDay: false,
            color: '#90EE90'
        });
        // this.fetchEvent();
        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    addEvent(select){
        var eventData = [];
        this.eventDataHalfDay = [];
        let latest_date = this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd h:mm:ss a');
        var event_day = this.addEventForm.value.event_date = latest_date;
        
        if(select == null){
            jQuery('#modal-view-event-add').modal();
        }

        if (select) {
            eventData[1] = event_day;

            this.token.set('eventData', JSON.stringify(eventData));

            jQuery('#modal-view-event-add').modal('hide');

            jQuery('#calendar').fullCalendar('removeEvents')
            this.eventDataHalfDay.push({
                start: new Date(this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd') + ' 08:00:00 AM'),
                end : new Date(this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd') + ' 12:00:00 PM'),
            })
            this.token.set('eventData', JSON.stringify(this.eventDataHalfDay));
            if(select == '8am'){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: '8 AM',
                    start: new Date(this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd') + ' 08:00:00 AM'),
                    end: new Date(this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd') + ' 12:00:00 PM'),
                    allDay: false,
                    editable: false,
                    color: '#90EE90'
                });
            }

            if(select == '2pm'){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: '2 PM',
                    start: new Date(this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd') + ' 02:00:00 PM'),
                    end: new Date(this.datepipe.transform(jQuery('#event_date').val(), 'yyyy-MM-dd') + ' 06:00:00 PM'),
                    allDay: false,
                    editable: false,
                    color: '#90EE90'
                });
            }
        }

        // this.fetchEvent();
        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    addThirtyDayEvent(){

        var event_date = JSON.parse(this.token.get('eventData'));
        var title = this.token.get('event_type');

        if(event_date.length <= 30){
            jQuery('#calendar').fullCalendar('removeEvents')
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: title,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90'
                });
            }
        }else{
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: title,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90'
                });
            }
        }

        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    addDayEvent(){

        jQuery('#calendar').fullCalendar('removeEvents')
        var event_date = JSON.parse(this.token.get('eventData'));
        var title = this.token.get('event_type');

        if(event_date.length <= 1){
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: title,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90'
                });
            }
        }else{
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: title,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90'
                });
            }
        }

        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    addMonthEvent(){

        jQuery('#calendar').fullCalendar('removeEvents')
        var event_date = JSON.parse(this.token.get('eventData'));
        var title = this.token.get('event_type');

        if(event_date.length <= 1){
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: title,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90'
                });
            }
        }else{
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: title,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90'
                });
            }
        }

        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    addTenDayEvent(){

        jQuery('#calendar').fullCalendar('removeEvents')
        var event_date = JSON.parse(this.token.get('eventData'));
        var title = this.token.get('event_type');

        if(event_date.length <= 10){
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: title,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90'
                });
            }
        }else{
            for( let i=0; i < event_date.length; i++ ){
                jQuery('#calendar').fullCalendar('renderEvent', {
                    title: title,
                    start: event_date[i].start,
                    end: event_date[i].end,
                    editable: false,
                    allDay: true,
                    color: '#90EE90'
                });
            }
        }

        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    fetchEvent(){
        this.eventArray = [];
        this.orderType['order_type'] = this.token.get('service');
        this.spaceservice.fetchEvent(this.orderType).subscribe(
            data => {
                if(data['status'] == 'true'){
                    for (let i = 0; i < data['events'].length; i++) {
                        
                        var title = '';
                        if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 1){
                            title = '30 Jours'
                        }else if(data['events'][i].order_type == 2 && data['events'][i].length_of_booking == 2){
                            title = 'Half Day';
                        }else if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 2 || data['events'][i].order_type == 2 && data['events'][i].length_of_booking == 3){
                            title = 'La journée';
                        }else if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 3){
                            title = 'Le mois';
                        }else if(data['events'][i].order_type == 1 && data['events'][i].length_of_booking == 4){
                            title = '10 Jours';
                        }else if(data['events'][i].order_type == 2 && data['events'][i].length_of_booking == 1){
                            title = 'Hourly';
                        }
                        if(data['events'][i].start_date != null && data['events'][i].end_date != null){
                            this.eventArray.push({
                                title: title,
                                start: data['events'][i].start_date,
                                end: data['events'][i].end_date,
                                allDay: true,
                                editable:false,
                                color: 'yellow',
                                className: 'fetchEvent'
                            });
                        }

                        if(data['events'][i].start_date == null && data['events'][i].end_date == null){
                            for (let j = 0; j < data['events'][i].appointment.length; j++) {
                                this.eventArray.push({
                                    title: title,
                                    start: data['events'][i].appointment[j].start_date,
                                    end: data['events'][i].appointment[j].end_date,
                                    allDay: true,
                                    editable:false,
                                    color: 'yellow',
                                    className: 'fetchEvent'
                                });
                            }
                        }
                    }
                }
                jQuery('#calendar').fullCalendar('addEventSource', this.eventArray);
                this.token.set('fetchEvent', JSON.stringify(this.eventArray));

                var event_date = JSON.parse(this.token.get('eventData'));
                var title = this.token.get('event_type');
                if(event_date){
                    for( let z=0; z < event_date.length; z++ ){
                        this.isSelectedEvent.push({
                            title: title,
                            start: event_date[z].start,
                            end: event_date[z].end,
                            editable: false,
                            allDay: true,
                            color: '#90EE90'
                        });
                    }
                    jQuery('#calendar').fullCalendar('addEventSource', this.isSelectedEvent);
                }
            },
            error => {
            }
        );
    }

    eventNextPrev(){
        this.nextPrevArray = [];
        jQuery('#calendar').fullCalendar('removeEvents')
        var event_date = JSON.parse(this.token.get('eventData')) || [];
        var title = this.token.get('event_type');
        if(event_date){
            for( let k=0; k < event_date.length; k++ ){
                this.nextPrevArray.push({
                    title: title,
                    start: event_date[k].start,
                    end: event_date[k].end,
                    editable:false,
                    allDay: true,
                    color: '#90EE90'
                });
            }
            jQuery('#calendar').fullCalendar('addEventSource', this.nextPrevArray);
        }

        this.fetchAllEvent = JSON.parse(this.token.get('fetchEvent'));
        jQuery('#calendar').fullCalendar('addEventSource', this.fetchAllEvent);
    }

    /*getClosingDates(){
        var self = this;
        this.spaceservice.getClosingDates().subscribe(
            data => {
                if(data['status'] == 'true'){

                    var getClosingDates = {
                        "holidays": data['holidays'],
                        "week_days": data['week_days']
                    }

                    this.token.set('getClosingDates', JSON.stringify(getClosingDates));    
                    var closingData = this.token.get('getClosingDates');
                    console.log(JSON.parse(closingData));
                }
            },
            error => {
              console.log(error);
            }
        );
    }*/

}
