import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceThreeService {

	baseUrl: any;

  	constructor(private http: HttpClient) { 
  		this.baseUrl = environment.APP_URL;
  	}

  	addServiceThree(data){
  		return this.http.post(`${this.baseUrl}/addThreeService`, data);
  	}

  	getServiceOfficeData(){
  		return this.http.get(`${this.baseUrl}/getThreeServiceOffice`);
  	}
}
