import { Component, OnInit, NgZone } from '@angular/core';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../service/authentication.service';
import { UserService } from '../../service/user.service';
import { StoreService } from '../../service/store.service';
import { SpaceService } from '../../service/space.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TokenService } from '../../service/token.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-nomade-service',
  templateUrl: './nomade-service.component.html',
  styleUrls: ['./nomade-service.component.css']
})
export class ServiceComponent implements OnInit {

	services: any = [];
	contractors: any = [];
	contractor_id: any = null;
	selectedService: any = [];
	IMG_URL: any;
	currentCategory: any;
	catName:any;
	catImg:any;

	constructor(
		private router: Router,
		private userservice: UserService,
		private token: TokenService,
		private store: StoreService,
		private auth: AuthenticationService,
		private space: SpaceService,
		private cookie: CookieService,
		private toastr: ToastrService,
		private zone: NgZone
	) { 
		this.IMG_URL = environment.STORAGE_IMG_URL;
		this.userservice.loadScript();
		this.getCategory();
	}

	ngOnInit() { 
		this.selectedService = this.store.getService();
	}

	getCategory(){
		this.space.allService().subscribe(
            data => {
  				this.services = data
            },
            error => {
                this.toastr.error(error.error.error);
            }
        );
	}

	saveService( item_id ){
		var getSerivceLocal = JSON.parse( this.token.get( 'nomade_service' ) ) || [];
		var alreadyInclude = false;
		if( getSerivceLocal.length > 0 ){
			getSerivceLocal.forEach((item, itemIndex) => {
				if( item.id == item_id ){
					alreadyInclude = true;
					getSerivceLocal.splice( itemIndex, 1 )
				}
		  	})
		}

		if( !alreadyInclude ){
		  	this.services.forEach( ( menu, menuIndex ) => {
		  		if( menu.id == item_id ){
					getSerivceLocal.push( menu );
				}	
		  	});
		}
		this.token.set( 'nomade_service', JSON.stringify( getSerivceLocal ) );
	}

	removeAllData(){
		console.log('remove cart data');
		this.token.remove('menu');
		this.token.remove('nomade_service');
		this.token.remove('eventData');
		this.token.remove('service');
		this.token.remove('event_type');
		this.token.remove('room_size');
		this.token.remove('full_price');
		this.token.remove('hour_price');
		this.token.remove('half_price');
		this.token.remove('menuPrice');
		this.token.remove('totalPrice');
		this.token.remove('totalSubmitPrice');
		this.token.remove('walletTotalDesc');
		this.token.remove('service_cost');
		this.token.remove('service_type');
		this.token.remove('fetchEvent');
		this.token.remove('walletDiscount');
		this.token.remove('price');
		this.token.remove('walletUpdatePrice');
		this.token.remove('contract');
		this.token.remove('amountType');
		this.token.remove('percentage');
		this.token.remove('couponDiscount');
		this.token.remove('couponName');
		this.token.remove('taxAmount');
		this.token.remove('selected_events');
		this.token.remove('room_type');
		this.token.remove('item_key');
		this.token.remove('event_key');
		this.token.remove('CurrentItemKey');
		this.token.remove('selected_events_temp');
		this.cookie.delete('service');
		this.cookie.delete('space');
		this.cookie.delete('eventData');
		this.cookie.delete('totalPrice');

		this.router.navigate(['/accueil']);
	}

	isSelectedCategory(itemId){
		//console.log('isSelectedCategory');
		let isSelected = false;
		var getSerivceLocal = JSON.parse(this.token.get('nomade_service'));
		if(getSerivceLocal){
			getSerivceLocal.forEach((item, index) => {
		  		if(item.id == itemId){
		  			isSelected = true;
		  		}
		  	})
		}
		return isSelected;
	}
}	
